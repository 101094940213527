import { Routes, Route, Link } from "react-router-dom";
import ReferenceArchitecture from "./ReferenceArchitecture";
import { Box, Typography } from "@mui/material";
import refArchImage from './Reference Architecture.png' 
import { IReferenceArchitectureConfig } from "./reference-architecture-module";


const ReferenceArchitectureToc = () => {
    
    
    
    const configGeneral: IReferenceArchitectureConfig= 
    {
        id: "general",
        version: "0.1",
        versionDate: "2023-04-16",
        title: "Reference Architecture: General",
        supportedBy:[],
        supports:[ 
            {
                id: "commcare-azure-powerbi",
                title: "Reference Architecture: CommCare-Azure-PowerBI"
            }
        ],
        sections: [
        {
            id:'ref-arch-overview',
            name: 'ref-arch-overview-title',
            desc: 'ref-arch-overview-desc',
            content: <> This architecture seeks to capture the needs of a cash program.  Most cash programs share core needs and require similar features and this reference architecture is intended to provide a starting point for customization.</>
        },
        {
            id:'ref-arch-system-context',
            name: 'ref-arch-system-context-title',
            desc: 'ref-arch-system-context-desc',
            content: <>Key stakeholders:
            <ul>
                <li>Program manager</li>
                <li>Cash Officer</li>
                <li>M&E Officer</li>
                <li>Data protection</li>
                <li>Grants and reporting manager</li>
            </ul></>
        },
        {
            id:'ref-arch-functional-arch',
            name:'ref-arch-functional-arch-title',
            desc:'ref-arch-functional-arch-desc',
            content: <><Typography variant="body1" component="div">
                Key considerations for functional architecture:
                <ul>
                    <li>Address needs of each stakeholder</li>
                    <li>Ensure ease of use, self-service and minimal need for support.</li>
                </ul>
            </Typography>
            <Typography variant="body1">This diagram seeks to capture key functional areas the information management system must address. 
            <img src={refArchImage} alt="reference architecture"/>
            </Typography></>
        },
        {
            id:'ref-arch-data-arch',
            name:'ref-arch-data-arch-title',
            desc: 'ref-arch-data-arch-desc',
            content: <>
        Data architecture should include:
        <ul>
            <li>Form and field data collection repository: Collection, storage, and querying of raw responses from participants. Contains PII.</li>
            <li>Case data repository: Integrated and cleaned data store with current near real time data representing current state of the program and each participant. This is the operational data store that is used to create distribution list and other functions requiring access to PII.</li>
            <li>Reporting data store: De-identified data that is easily accessible for monitoring, indicator analysis, and donor reporting. Does not contain PII.</li>
        </ul>
        </>
        },
        // {
        //      id: 'ref-arch-tech-arch',
        //     name: 'ref-arch-tech-arch-title',
        //     desc: 'ref-arch-tech-arch-desc',
        //     content: <></>
        // },
        {
            id:'ref-arch-security-arch',
            name: 'ref-arch-security-arch-title',
            desc: 'ref-arch-security-arch-desc',
            content: <>
    Security architecture considerations:
    <ul>
        <li>Access to protected data is restricted to those who require it. Most functions can be completed without access it Personal Identifying Information(PII).</li>
        <li>Systems are hosted in a secure environment with limited access</li>
        <li>Tax numbers, IBANs, names and other identifying attributes are not used or cryptographically protected where possible</li>
    </ul>
            </>
        },
        // {
        //     id:'ref-arch-deployment-arch',
        //     name: 'ref-arch-deployment-arch-title',
        //     desc: 'ref-arch-deployment-arch-desc',
        //     content: <></>
        // }
    ]
    };
    const configCommcareAzurePowerbi: IReferenceArchitectureConfig= 
    {
        id: "commcare-azure-powerbi",
        version: "0.1",
        versionDate: "2023-04-17",
        title: "Reference Architecture: CommCare-Azure-PowerBI",
        supportedBy:[
            {
                id: "general",
                title: "Reference Architecture: General"
            }
        ],
        supports:[],
        sections: [
        {
            id:'ref-arch-overview',
            name: 'ref-arch-overview-title',
            desc: 'ref-arch-overview-desc',
            content: <>This is a specialization of the <Link to="../general">general architecture</Link> making some recommended technology choices for each domain </>,
        },
        // {
        //     id:'ref-arch-system-context',
        //     name: 'ref-arch-system-context-title',
        //     desc: 'ref-arch-system-context-desc',
        //     content: <>Key stakeholders:
        //     <ul>
        //         <li>Program manager</li>
        //         <li>Cash Officer</li>
        //         <li>M&E Officer</li>
        //         <li>Data protection</li>
        //         <li>Grants and reporting manager</li>
        //     </ul></>
        // },
        // {
        //     id:'ref-arch-functional-arch',
        //     name:'ref-arch-functional-arch-title',
        //     desc:'ref-arch-functional-arch-desc',
        //     content: <><Typography variant="body1">
        //         Key considerations for functional architecture:
        //         <ul>
        //             <li>Address needs of each stakeholder</li>
        //             <li>Ensure ease of use, self-service and minimal need for support.</li>
        //         </ul>
        //     </Typography>
        //     <Typography variant="body1">This diagram seeks to capture key functional areas the information management system must address. 
        //     <img src={refArchImage} alt="reference architecture"/>
        //     </Typography></>
        // },
        {
            id:'ref-arch-data-arch',
            name:'ref-arch-data-arch-title',
            desc: 'ref-arch-data-arch-desc',
            content: <>
        Data architecture should include:
        <ul>
            <li>Form and field data collection: CommCare</li>
            <li>Form and filed data collection repository: Azure PostreSQL.<br/> Rationale for this choice is that CommCare Data Export Tool(DET) natively supports export of entire data document to the Postgres as a JSON column.  This export tool is supported and can be implemented securely in Azure. CommCare Sync should be avoided as it lacks adequate protection for API keys.  <br/> Using DET to populate a database is an effective way to backup data and also make it available for querying.  Although JSON can be challenging to query, the data can be exposed as a view with appropriate protections for sensitive information.<br/>
            DET also supports exports to Microsoft SQL Server.  However these exports need to maintained when forms are updated, while PostgreSQL exports will automatically export the entire document.</li>
            <li>Case data repository: Case data can be stored in CommCare or in PostgreSQL database.  This is a design choice that depends on the particular use case and tools implement data cleaning, for example.  Although data might be cleaned external to CommCare, CommCare APIs can be used to automate case updates.</li>
            <li>Automated data cleaning and other data processing: Azure offers a number of tools to implement data flows, including Data Factory, Synapse, Data Lake Analytics and others.  It is also possible to automate data processing using a number of other technologies (for example, Automation Accounts).  The choice of the data processing will depend on specific need and existing tools.  </li>
            <li>Manual data cleaning and other data processing: It's important to recognize that often data cleaning and other data processing takes place in Excel and other tool outside the automated flow.  This data is important to integrate into the data pipeline.  Loading such updates can be implemented using Azure Logic Apps and Function Apps based on standardized data formats.</li>
            <li>Reporting data store/PowerBI: We recommend exposing data for reporting using Power BI Datamarts. This methoid allows masking or removal of PII and simplifies access management.  This improves security and data protection posture.  It also allows connection to the data by variety of methods.  Power BI reports can use Datamarts natively or can view them as conventional Power BI datasets.  For additional control, Power BI datamarts accept connections to SQL databases.  They can be used as data connections in Excel.</li>
        </ul>
        </>
        },
        // {
        //      id: 'ref-arch-tech-arch',
        //     name: 'ref-arch-tech-arch-title',
        //     desc: 'ref-arch-tech-arch-desc',
        //     content: <></>
        // },
        {
            id:'ref-arch-security-arch',
            name: 'ref-arch-security-arch-title',
            desc: 'ref-arch-security-arch-desc',
            content: <>
    Security architecture considerations:
    <ul>
        <li>By exposing data through controlled methods, such as Power BI datamarts, PII can be removed or protected as required by the use case</li>
        <li>Access to resources can be managed.  By limited direct reporting connections to CommCare, risk of data leaks is removed</li>
        <li>Using secure implementation of DET export tool reduces chances of unauthorized access over using tools like CommCare sync.</li>
        <li>Tax numbers, IBANs, names and other identifying attributes should be removed, masked or hashed in views and datamarts.</li>
    </ul>
            </>
        },
        // {
        //     id:'ref-arch-deployment-arch',
        //     name: 'ref-arch-deployment-arch-title',
        //     desc: 'ref-arch-deployment-arch-desc',
        //     content: <></>
        // }
    ]
    };
    const configs:IReferenceArchitectureConfig[] = [configGeneral, configCommcareAzurePowerbi];
 


    return (
    <>
        <Routes>
            <Route  path="/" element={
                <Box sx={{display:"flex", flexDirection: "column", margin:3}}>
                    <Typography variant="h5" component="div" sx={{paddingBottom:3}}>Table of contents</Typography>
                    {
                    configs.map(({id, title})=><Link to={id} relative="route">{title}</Link>)
                    }
                    <Typography variant="body1" sx={{marginTop:3}}>We are always adding new content, including AWS and GCP stacks.  Please contact us with any questions or ideas.</Typography>
                </Box>}/>
            {configs.map( (config)=><Route path={config.id} element={<ReferenceArchitecture config={config} />}/>)}
        </Routes>
    </>
    )
}

export default ReferenceArchitectureToc;