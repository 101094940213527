import { useState} from 'react'
import { Typography, Button, Card, CardContent, CardHeader, Stack, FormControl, FormHelperText, Input, InputLabel, Box,  Paper } from "@mui/material";
import { CreateCommcareInstanceDto } from '../../datamodel/dtos/CreateCommcareInstanceDto';

interface ICommcareFormProps {
    orgId:string,
    cancel:()=>void,
    submit:(commcareInstance:CreateCommcareInstanceDto)=>void
} 


const CommcareForm = ( props:ICommcareFormProps) => {
    const {orgId, cancel, submit} = props;
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [username, setUsername] = useState("");
    const [key, setKey] = useState("");
    const [isUsingSaas] = useState(false);  //TODO: always false?
    const [projectName, setProjectName] = useState("");
    const [appId, setAppId] = useState("");
    const [server, setServer] = useState("");
    
    
    const [step, setStep] = useState(0);
    const maxSteps = 2
    // const [, set] = useState("");
    // const [, set] = useState("");
    
    const handleFormSubmit = async ()=> {
        let submitData:CreateCommcareInstanceDto = {
            name: name,
            description: description,
            username: username,
            key: key,
            org: orgId,
            isUsingSaas:isUsingSaas,
            projectName:projectName,
            appId:appId,
            server:server
        }

        submit( submitData);
        
    }

    const handleCancel = ()=> {
        cancel();
    }

    const handleBackClick = () => {
        if(step > 0) setStep(step - 1);
    }

    const handleNextClick = () => {
        if(step < maxSteps-1) setStep( step + 1);
    }
    const formControlStyle = {paddingTop:3};

    return (
        <Card elevation={0}>
            <CardHeader
                title={
                    <Typography variant='h5'>Add Commcare Instance</Typography>
                }
            />
            <CardContent>
                
            
            <form>
        { step===0 && 
            <Stack direction={'column'}> 
        <FormControl sx={formControlStyle}>
            <InputLabel htmlFor="domain-name-input">Commcare Domain</InputLabel>
            <Input id="domain-name-input" aria-describedby="domain-name-input-text" value={name} onChange={(e)=>setName(e.target.value)}/>
            <FormHelperText id="domain-name-input-text">Commcare domain name.</FormHelperText>
        </FormControl>
        <FormControl sx={formControlStyle}>
            <InputLabel htmlFor="desc-input">Description</InputLabel>
            <Input multiline id="desc-input" aria-describedby="desc-input-text" value={description} onChange={(e)=>setDescription(e.target.value)}/>
            <FormHelperText id="desc-input-text">Enter any notes a comments</FormHelperText>
        </FormControl>
        </Stack>
        }
        { step===1 && 
        <Paper elevation={0}>
            <Stack>
        <Typography variant='body1'>In order to access APIs, please provide username and key.  This data will be stored securely in Azure Key Vault.</Typography>
        <FormControl sx={formControlStyle}>
            <InputLabel htmlFor="username-input">Username</InputLabel>
            <Input multiline id="username-input" aria-describedby="username-input-text" value={username} onChange={(e)=>setUsername(e.target.value)}/>
            <FormHelperText id="username-input-text">Username associated with the key.</FormHelperText>
        </FormControl>
        <FormControl sx={formControlStyle}>
            <InputLabel htmlFor="key-input">Key</InputLabel>
            <Input multiline id="key-input" aria-describedby="key-input-text" value={key} onChange={(e)=>setKey(e.target.value)} />
            <FormHelperText id="key-input-text">Key that will be used to access the API.</FormHelperText>
        </FormControl>
        <FormControl sx={formControlStyle}>
            <InputLabel htmlFor="project-input">Project name</InputLabel>
            <Input multiline id="project-input" aria-describedby="project-input-text" value={projectName} onChange={(e)=>setProjectName(e.target.value)} />
            <FormHelperText id="project-input-text">Project name that will be used to access the API.</FormHelperText>
        </FormControl>
        <FormControl sx={formControlStyle}>
            <InputLabel htmlFor="appid-input">App ID: </InputLabel>
            <Input multiline id="appid-input" aria-describedby="appid-input-text" value={appId} onChange={(e)=>setAppId(e.target.value)} />
            <FormHelperText id="appid-input-text">App name that will be used to access the API.</FormHelperText>
        </FormControl>
        <FormControl sx={formControlStyle}>
            {/*<FormControlLabel
            value={isUsingSaas}
            control={<Checkbox />}
            label="Using CommCare HQ SaaS"
            labelPlacement="end"
            onChange={(e:any)=>setIsUsingSaas(e.target.checked)}
            /> */}
         <FormControl 
        //  disabled={isUsingSaas}
            sx={formControlStyle}>
            <InputLabel htmlFor="server-input">Server IP or domain for API access</InputLabel>
            <Input id="server-input" aria-describedby="server-input-text" value={server} onChange={(e)=>setServer(e.target.value)} />
            <FormHelperText id="server-input-text">If hosting on your own, system needs to know the base URL of the API. </FormHelperText>
        </FormControl>
         
  
        </FormControl>
        </Stack>
        </Paper>
        }
        <Box sx={{paddingTop:3}}>
            <Button variant='outlined' onClick={handleCancel} sx={{margin:1}} >Cancel</Button>
            <Button disabled={step===0}  variant='contained' onClick={handleBackClick} sx={{margin:1}}>Back</Button>
            {step === maxSteps-1 ? 
            <Button variant='contained' onClick={handleFormSubmit} sx={{margin:1}}>Add</Button>
            :<Button variant='contained' onClick={handleNextClick} sx={{margin:1}}>Next</Button>
            
}       
        </Box>
        
        </form>
        </CardContent>
        </Card>
    )
}

export default CommcareForm;