import Datasheet from "../Datasheet";
import { ITerraformCloudConfig } from "../../datamodel/interfaces/terraform.interfaces";

interface ITerraformSummaryProps {
    terraformConfig: ITerraformCloudConfig
}

const TerraformSummary = (props: ITerraformSummaryProps) => {
    return (
        <>
            <Datasheet data={props.terraformConfig} keys={[
                { keyValue: "_id", displayName: "ID" },
                { keyValue: "orgName", displayName: "Organization Name" },
                { keyValue: "projectName", displayName: "Project Name" },
                { keyValue: "workspaceName", displayName: "Workspace Name" },
                { keyValue: "workspaceId", displayName: "Workspace ID" },
                { keyValue: "maskedKey", displayName: "Key" },
                { keyValue: "validationStatus", displayName: "Validation status" },
                { keyValue: "validationMessage", displayName: "Validation message" }
            ]} />
            {/* <Button variant="contained" onClick={()=>setConfigViewMode(ConfigViewMode.EDIT)}>Edit</Button> */}
        </>
    )
}

export default TerraformSummary;
