import { Box, Tab, Tabs, Typography } from "@mui/material";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import ReportConnect from "../ReportConnect";
import { useState } from "react";
import { a11yProps, TabPanel } from "../Tab";

interface IReportingProps {
    org:IOrganization
}

const Reporting = (props:IReportingProps) => {
    const {org} = props;
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <>
<Typography variant="h4"><br/></Typography>
    
    <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
        <Tabs
            orientation="vertical"
            variant="standard"
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="IT Config Tabs"
            sx={{ borderRight: 1, borderColor: 'divider' }}
        >
            <Tab label="Configure PowerBI" {...a11yProps(0)} />
            <Tab label="Common Reports" {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={tabIndex} index={0}>
        <ReportConnect org={org} />    
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
        
        </TabPanel>
    </Box>    
</>
}

export default Reporting;