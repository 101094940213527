import { useState } from 'react'
import { ITerraformCloudConfig, TerraformConfigValidationStatus } from '../../datamodel/interfaces/terraform.interfaces';
import { Button, TextField, Typography } from '@mui/material';
import Datasheet from '../Datasheet';
import TerraformConfigForm from './TerraformConfigForm';

interface ITerraformConfigList {
    orgId: string,
    terraformConfig:ITerraformCloudConfig|undefined,
    saveConfig:(c:ITerraformCloudConfig)=>void,
    updateConfig:(c:ITerraformCloudConfig)=>void,

}

enum ConfigViewMode {
    VIEW = 'view',
    EDIT = "edit"
}

const TerraformConfigList = (props: ITerraformConfigList) => {
    const { orgId, terraformConfig } = props;
    const [configViewMode, setConfigViewMode] = useState<ConfigViewMode>(ConfigViewMode.VIEW);

    

    const cancelConfigSave = ()=> {
        if(terraformConfig ) {
            setConfigViewMode(ConfigViewMode.VIEW);
        }
        else {
            alert('Please add config to continue');
        }
    }

    const saveConfigInternal = async (config: ITerraformCloudConfig) => {
        props.saveConfig(config);
        setConfigViewMode(ConfigViewMode.VIEW);
    }

    const updateConfigInternal = async (config:ITerraformCloudConfig) => {
        props.updateConfig(config);
        setConfigViewMode(ConfigViewMode.VIEW)
    }

    
    return (<>
        {(!terraformConfig) && 
            <TerraformConfigForm orgId={orgId} handleSubmit={saveConfigInternal} 
            allowCancel={false} handleCancel={cancelConfigSave} />
        }

        {terraformConfig && configViewMode === ConfigViewMode.VIEW &&
            <>
            <Datasheet data={terraformConfig} keys={[
                { keyValue: "_id", displayName: "ID" },
                { keyValue: "orgName", displayName: "Organization Name" },
                { keyValue: "projectName", displayName: "Project Name"},
                { keyValue: "workspaceName", displayName: "Workspace Name" },
                { keyValue: "workspaceId", displayName: "Workspace ID"},
                { keyValue: "maskedKey", displayName: "Key" },
                { keyValue: "validationStatus", displayName: "Validation status"},
                { keyValue: "validationMessage", displayName: "Validation message"}
            ]} />
            <Button variant="contained" onClick={()=>setConfigViewMode(ConfigViewMode.EDIT)}>Edit</Button>
            { terraformConfig.validationStatus === TerraformConfigValidationStatus.VALIDATED && <> 
            <Typography variant="h5">Deployment Step</Typography>
            <Typography variant='body1' component="div">
                In this step the following are deployed:
                <ul>
                    <li>Resource group: <TextField></TextField></li>
                    <li>Key vault: <TextField></TextField></li>
                    <li>Storage account: <TextField></TextField></li>
                </ul>
            
            </Typography>
            <Button>Deploy</Button>
            
            </>
            }
            </>
        }
        
        {terraformConfig && configViewMode === ConfigViewMode.EDIT &&
            <>
                <TerraformConfigForm 
                orgId={orgId} 
                handleSubmit={updateConfigInternal}
                handleCancel={cancelConfigSave} 
                data={terraformConfig}
                allowCancel={true} />
            </>
        }        
    </>
    )
}

export default TerraformConfigList;