import { Button, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import CommcareList from "../../commcare/CommcareList";
import { IOrganization } from "../../../datamodel/interfaces/organization.interfaces";
import CommcareDetail from "../../commcare/CommcareDetail";
import { ICommcareInstance } from "../../../datamodel/interfaces/commcare.interfaces";
import AddCommcareDialog from "../../commcare/AddCommcareDialog";
import { CreateCommcareInstanceDto } from "../../../datamodel/dtos/CreateCommcareInstanceDto";
import { CommcareApi } from "../../../api/CommcareApi";
import { IDataCollectionFormContainer } from "../../../datamodel/interfaces/datacollectioninterfaces";
import KoboList from "../../kobo/KoboList";
import { KoboApi } from "../../../api/KoboApi";
import KoboDetail from "../../kobo/KoboDetail";
import AddKoboDialog from "../../kobo/AddKoboDialog";
import { CreateKoboInstanceDto } from "../../../datamodel/dtos/CreateKoboInstanceDto";
import { IKoboInstance } from "../../../datamodel/interfaces/IKoboInstance.interface";

interface IDataCollectionConnectionConfigProps {
    org: IOrganization
}

const DataCollectionConnectionConfig = (props: IDataCollectionConnectionConfigProps) => {
    const { org } = props;
    
    /// manage commcare instance list
    const [commcareInstanceList, setCommcareInstanceList] =  useState<ICommcareInstance[]>([]);
    const [currentCommcareInstance, setCurrentCommcareInstance] = useState<ICommcareInstance | undefined>()
    const [showAddCommcareDialog, setShowAddCommcareDialog] = useState(false);

    const [koboInstanceList, setKoboInstanceList] =  useState<IKoboInstance[]>([]);
    const [currentKoboInstance, setCurrentKoboInstance] = useState<IKoboInstance | undefined>()
    const [showAddKoboDialog, setShowAddKoboDialog] = useState(false);
    const [koboInstanceId, setKoboInstanceId] = useState<string|undefined>();

    ///kobo functions
    const refreshKoboInstanceList = useCallback(() => {
        if (org) {
          KoboApi.getKoboInstances(org._id).then(
            (result) => {
                setKoboInstanceList(result)
            }
          )
        }
      }, [org]);

    const addKoboInstance = async (newKoboInstance: any)=> {
        const savedKoboInstance = await KoboApi.saveInstance(org._id, newKoboInstance);
        setKoboInstanceId(savedKoboInstance._id)
        refreshKoboInstanceList();
        //setCurrentKoboInstance( commcareInstanceList.find((ci)=>ci._id === savedKoboInstance._id))
    }

    function addKoboInstanceStart(): void {
        setShowAddKoboDialog(true);
    }
    
    const updateKoboInstanceMetadata = async (koboInstance: IKoboInstance) => {
        //trigger an update to kobo metadata
        await KoboApi.loadMetadata(koboInstance._id, org._id);
        setKoboInstanceId(koboInstance._id);
        refreshKoboInstanceList();
        //setCurrentKoboInstance(koboInstanceList.find(ci=>ci._id===koboInstance._id));
    }

    const refreshKoboInstance = () => {
        throw new Error("refreshKoboInstance not implemented");
    }
    
    const handleCancelAddKoboInstance = () => {
        setShowAddKoboDialog(false);
    }

    const handleSubmitAddKoboInstance = async (instance: CreateKoboInstanceDto) => {
        await addKoboInstance(instance);
        setShowAddKoboDialog(false);
    }

    const saveKoboMetadataUpdate = async (metadata:IDataCollectionFormContainer
        , koboId:string) => {
        await KoboApi.updateMetadata(metadata, koboId, org._id);
        setKoboInstanceId(koboId);
        refreshKoboInstanceList();
        //setCurrentKoboInstance(koboInstanceList.find(ci=>ci._id===koboId));
    }

    useEffect(()=> {
        if( currentKoboInstance && !koboInstanceId) setKoboInstanceId( currentKoboInstance._id);
        if( !koboInstanceId) return;

        setCurrentKoboInstance(koboInstanceList.find(ci=>ci._id===koboInstanceId));
    }, [koboInstanceList, koboInstanceId, currentKoboInstance])
   

    /// commcare function
    const refreshCommcareInstanceList = useCallback(() => {
        if (org) {
          CommcareApi.getCommcareInstances(org._id).then(
            (result) => {
                setCommcareInstanceList(result)
            }
          )
        }
      }, [org]);

    const addCommcareInstance = async (newCommcareInstance: CreateCommcareInstanceDto)=> {
        const savedCommcareInstance = await CommcareApi.saveInstance(org._id, newCommcareInstance);
        refreshCommcareInstanceList();
        setCurrentCommcareInstance( commcareInstanceList.find((ci)=>ci._id === savedCommcareInstance._id))
    }

    const updateCommcareInstanceMetadata = async (commcareInstance: ICommcareInstance) => {
        //trigger an update to commcare metadata
        await CommcareApi.loadMetadata(commcareInstance._id, org._id);
        refreshCommcareInstanceList();
        setCurrentCommcareInstance(commcareInstanceList.find(ci=>ci._id===commcareInstance._id));
    }

    const saveCommcareMetadataUpdate = async (metadata:IDataCollectionFormContainer
        , commcareId:string) => {
        await CommcareApi.updateMetadata(metadata, commcareId, org._id);
        refreshCommcareInstanceList();
        setCurrentCommcareInstance(commcareInstanceList.find(ci=>ci._id===commcareId));
    }

    
    ///add commcare dialog functions
    function addCommcareInstanceStart(): void {
        setShowAddCommcareDialog(true);
    }
    
    const handleCancelAddCommcareInstance = () => {
        setShowAddCommcareDialog(false);
    }

    const handleSubmitAddCommcareInstance = async (instance: CreateCommcareInstanceDto) => {
        await addCommcareInstance(instance);
        setShowAddCommcareDialog(false);
    }
    //// end add commcare instance dialog functions


    const refreshCommcareInstance = () => {
        throw new Error("refreshCommcareInstance not implemented");
    }

    
    useEffect( ()=> {
        refreshCommcareInstanceList();
        refreshKoboInstanceList();
    }, [refreshCommcareInstanceList, refreshKoboInstanceList])

    useEffect( ()=> {
        if( commcareInstanceList.length > 0 && currentCommcareInstance===undefined ) {
            setCurrentCommcareInstance(commcareInstanceList[0]);
        }
    }, [commcareInstanceList,currentCommcareInstance])

    useEffect( ()=> {
        if( koboInstanceList.length > 0 && currentKoboInstance===undefined ) {
            setCurrentKoboInstance(koboInstanceList[0]);
        }
    }, [koboInstanceList, currentKoboInstance])



    return (
        <>
            <AddCommcareDialog
                org={org}
                handleCancelAddCommcareInstance={handleCancelAddCommcareInstance}
                handleSubmitAddCommcareInstance={handleSubmitAddCommcareInstance}
                showAddCommcareDialog={showAddCommcareDialog}
            />

            <AddKoboDialog
                org={org}
                handleCancelAddKoboInstance={handleCancelAddKoboInstance}
                handleSubmitAddKoboInstance={handleSubmitAddKoboInstance}
                showAddKoboDialog={showAddKoboDialog}
            />

            {
                commcareInstanceList.length === 0 &&
                koboInstanceList.length===0 && <div>
                <Button onClick={addKoboInstanceStart}>Add Kobo Instance</Button>
                <Button onClick={addCommcareInstanceStart}>Add Commcare Instance</Button>
                </div>
            }
            

{commcareInstanceList.length > 0 &&
            <Paper elevation={3} >
                <CommcareList org={props.org}
                    commcareInstanceList = {commcareInstanceList}
                    addCommcareInstance={addCommcareInstanceStart}
                    currentCommcareInstance={currentCommcareInstance}
                    setCurrentCommcareInstance={setCurrentCommcareInstance}
                >
                    { currentCommcareInstance && 
                        <CommcareDetail
                                refresh={refreshCommcareInstance}
                                requestUpdateMetadataFromCommcareApi={updateCommcareInstanceMetadata}
                                saveMetadataUpdate={saveCommcareMetadataUpdate}
                                org={org}
                                setCurrentCommcareInstance={setCurrentCommcareInstance}
                                commcareInstance={currentCommcareInstance} />
                    }
        
                </CommcareList>
            </Paper>
}
{ koboInstanceList.length > 0 &&
            <Paper elevation={0} >
                <KoboList org={props.org}
                    koboInstanceList = {koboInstanceList}
                    addKoboInstance={addKoboInstanceStart}
                    currentKoboInstance={currentKoboInstance}
                    setCurrentKoboInstance={setCurrentKoboInstance}
                >
                    { currentKoboInstance && 
                        <KoboDetail
                                mode="overview"
                                refresh={refreshKoboInstance}
                                requestUpdateMetadataFromKoboApi={updateKoboInstanceMetadata}
                                saveMetadataUpdate={saveKoboMetadataUpdate}
                                org={org}
                                setCurrentKoboInstance={setCurrentKoboInstance}
                                koboInstance={currentKoboInstance}
                                showDataProtectionButton={false} />
                    }

                </KoboList>
            </Paper>
}


        </>
    )
}

export default DataCollectionConnectionConfig;