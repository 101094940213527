export interface IDataCollectionFormContainer  {
    name:string,
    subContainers:IDataCollectionFormContainer[],
    forms:IDataCollectionForm[]

}

export interface IDataCollectionForm  {
    name:string,
    questions:IDataCollectionQuestion[]
}

export enum ProtectionActions {
    MASK='mask',
    HASH='hash',
    NEVER_SHOW='exclude'
}

export interface IDataCollectionQuestionProperties {
    isProtectedInfo:boolean
    , protectionAction?: ProtectionActions
    , semanticValue?:string
    //, semanticLabels: string[]
}

export interface IDataCollectionQuestion {
    label:string
    properties?: IDataCollectionQuestionProperties
}