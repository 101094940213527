import { Link, Route, Routes } from "react-router-dom";
import ExportConfig from "./exportConfig/exportConfig";

const CommcareSetup = () => {
    return (
        <Routes>
            <Route path="/" element={<>
                <div>CommcareSetup</div>
                <Link to="export">Export</Link>
            </>
            } />
            <Route path="/export/*" element={<ExportConfig/>}/>
        </Routes>
    )
}

export default CommcareSetup;