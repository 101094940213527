export class TokenHelper {
    static tokenKey:string = 'jwt'
    static async saveToken(token:string) {
        console.log("TokenHelper.saveToken", token)
        sessionStorage.setItem(TokenHelper.tokenKey, token);
    }

    static getToken():string|null {
        //console.log("TokenHelper.getToken")
        const token =  sessionStorage.getItem(TokenHelper.tokenKey);
        //console.log(token);
        return token;
    }
}