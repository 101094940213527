import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';


// import { Container, Typography } from "@mui/material";

// export default function Home() {
//     return (<Container sx={{ color: 'text.primary' }}>
//         <Typography >
//             Welcome to our website, where we believe that data is key to making informed decisions that have the power to transform our world for the better. As a non-profit organization, our mission is to provide NGOs with the tools they need to access, analyze, and visualize public data in innovative and meaningful ways.
//         </Typography>
//         <Typography>
//             We know that data can be overwhelming, and that’s why we’re here to help. Our team of experts uses sophisticated techniques to turn complex data sets into easy-to-understand visualizations and analyses that can inform decision-making at all levels of an organization.
//         </Typography>
//         <Typography>
//             We believe that data-driven decision-making is essential for creating positive social impact, and we’re passionate about empowering NGOs to make informed decisions that drive change. We work closely with our clients to understand their unique needs and provide tailored solutions that help them achieve their goals.
//         </Typography>
//         <Typography>
//             Whether you’re a small NGO just getting started or a large organization with complex data needs, we’re here to help. Our services are designed to be flexible, affordable, and accessible to organizations of all sizes.
//         </Typography>
//         <Typography>
//             Join us in our mission to use data for good and make a difference in the world. Explore our website to learn more about our services and how we can help your organization achieve its full potential through data-driven decision-making.
//         </Typography>
//         </Container>
//     )
// }


const Home = () => {
  const {t} = useTranslation('home');
  const theme = useTheme();
  return (
    <Container sx={{
        margin: theme.spacing(10),
        // textAlign: 'center',
    }}>
      <Typography variant="h4" gutterBottom>
        {t('header')}
      </Typography>
      <Grid container spacing={8} >
        <Grid item lg={4} xs={10}>    
          <Typography variant="body1" gutterBottom>{t('info1')}</Typography>
        </Grid>
        <Grid item lg={4} xs={12}>        
          <Typography variant="body1" gutterBottom>{t('info2')}</Typography>
        </Grid>
        <Grid item lg={4} xs={12}>        
          <Typography variant="body1" gutterBottom>{t('info3')}</Typography>
        </Grid>
    {/* 
    <Button variant="contained" color="primary" >
        Learn More
    </Button>  
    */}
      </Grid>
    </Container>
  );
};

export default Home;
