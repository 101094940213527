import { Button, Card, CardActions, CardContent, CardHeader, Box, Typography, FormControl, FormHelperText, Input, InputLabel, Stack } from '@mui/material'
import { useState } from 'react'
import { CreateOrgDto } from '../../datamodel/dtos/CreateOrgDto'

interface IOrgFormProps {
    addOrg: (org:CreateOrgDto) => void,
    cancel: ()=> void
}

const OrgForm = (props:IOrgFormProps) => {
    const [orgName, setOrgName] = useState("");
    const [orgDescription, setOrgDescription] = useState("");
    
    const {addOrg, cancel} = props;


    const handleSubmit = () => {
        //OrgApi.saveOrg({orgName: orgName})
        let org:CreateOrgDto = {
            orgName:orgName,
            description:orgDescription
        };

        addOrg(org);
    }

    const handleCancel = ()=> {
        setOrgName("");
        cancel();
    }

    return (<form>
        <Card>
            <CardHeader
                title={
                    <Box>
                        <Typography variant='h6'>Add new</Typography>
                        <Typography variant='body1'>
                            Define program or organization that will contain future elements like data collection, exports, and everything else.
                        </Typography>
                    </Box>
                }
            />
            <CardContent>
                <Stack>
                <FormControl sx={{padding:3}}>
                    <InputLabel htmlFor="org-name">Name</InputLabel>
                    <Input
                        id="org-name"
                        aria-describedby="org-name-text"
                        value={orgName}
                        onChange={(event) => setOrgName(event.target.value)}
                    />
                    <FormHelperText id="org-name-text">Add an unique name.</FormHelperText>
                </FormControl>
                <FormControl sx={{padding:3}}>
                    <InputLabel htmlFor="org-desc">Description</InputLabel>
                    <Input
                        id="org-desc"
                        aria-describedby="org-desc-text"
                        value={orgDescription}
                        onChange={(event) => setOrgDescription(event.target.value)}
                    />
                    <FormHelperText id="org-desc-text">Add a description or some notes for this org.</FormHelperText>
                </FormControl>
                </Stack>
                {/* <TextField id="org-name" label="Org name" variant="standard" value={orgName} onChange={(event)=> setOrgName(event.target.value)} /> */}
            </CardContent>
            <CardActions>
                <Button variant='outlined' onClick={handleCancel}>Cancel</Button>
                <Button variant='contained' onClick={handleSubmit}>Save</Button>
            </CardActions>

        </Card>
    </form>
    )
}

export default OrgForm;