import { Box, Tab, Tabs, Typography } from "@mui/material";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import AzureConfigSettingsHome from "../azure/AzConfigSettingsHome";
import TerraformDeployHome from "../terraform/deployment/TerraformDeployHome";
import { a11yProps, TabPanel } from "../Tab";
import { useState } from "react";

interface IPublishProps {
    org:IOrganization
}

const Publish = (props:IPublishProps)=> {
    const {org} = props;
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <>
    <Typography variant="h4"><br/></Typography>
    
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="standard"
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="IT Config Tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Configure Data Sharing" {...a11yProps(0)} />
                <Tab label="Deploy" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
            <AzureConfigSettingsHome org={org}/>
        
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
            <TerraformDeployHome org={org} />
            </TabPanel>
        </Box>    
    </>


}

export default Publish;