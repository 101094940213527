import { Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { IStepProps } from "../IStepProps";
import MainSummary from "./main-summary";

interface IMainStepProps extends IStepProps {
}

const MainStep = (props:IMainStepProps) => {
    const numberOfSubsteps = 3;
    const {back,next, config} = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [subscriptionId, setSubscriptionId] = useState<string>(config.settings.subscriptionId);
    const [readyForDeployment, setReadyForDeployment] = useState<boolean>(false);
    
    const nextSubstep = ()=>{ 
        config.settings.subscriptionId = subscriptionId;
        config.readyForDeployment = readyForDeployment;
        currentStep < (numberOfSubsteps-1) ?  setCurrentStep(currentStep+1) : next(config);
    }

    const previousSubstep = ()=>{
        currentStep === 0 ?  back() : setCurrentStep(currentStep-1);
    }

    const MainStep1Info = ()=> {
        return (<>
            <Typography variant="h6">Subscription</Typography>
            <Typography variant="body1">We need information about your subscription.</Typography>
            </>
        )
    }

    const MainStep2Info = ()=> {
        return (<>
            <Typography variant="h6">Subscription ID</Typography>
            <Typography variant="body1">Subscription ID is the key piece of info.</Typography>
            </>
        )
    }
    const MainStep3Info = ()=> {
        return (<>
            <Typography variant="h6">Review</Typography>
            <Typography variant="body1">Please review this info.</Typography>
            </>
        )
    }
    

    return (
        <>
        <div>{JSON.stringify(config)}</div>
        {currentStep === 0 && <MainStep1Info/> }
        {currentStep === 1 && <>
            <MainStep2Info/>
            <TextField label="subscription" value={subscriptionId} onChange={(e)=>setSubscriptionId(e.target.value)}/>
        </>}
        {currentStep === 2 && 
            <>
            <MainStep3Info/>
            <div>
                    <MainSummary showEdit={false} onEdit={() => { }} 
                            mainSettings={config} 
                    />
                    <FormControlLabel label="Ready for Deployment"
                        control={
                            <Checkbox checked={readyForDeployment} onChange={(e) => setReadyForDeployment(e.target.checked)}
                            />
                        } />
                </div>
            </>
        }
        <Button onClick={previousSubstep}>Back</Button>
        <Button onClick={nextSubstep}>Continue</Button>
        <div style={{ textAlign: "right" }}> Step {currentStep + 1} of {numberOfSubsteps}</div>

        </>
    )
}

export  default MainStep;