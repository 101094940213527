import { Button, Typography } from "@mui/material";
import {  IDataCollectionFormContainer } from "../../datamodel/interfaces/datacollectioninterfaces"
import { useState } from "react";
import ApplicationNode from "./ApplicationNode";

interface IApplicationTreeProps {
    rootContainer: IDataCollectionFormContainer,
    ontology: any,
    onCancel:()=>void,
    onSave: (r:IDataCollectionFormContainer)=>void
}

const ApplicationTree = (props: IApplicationTreeProps) => { 
    const {rootContainer, ontology } = props;

    const [draftRootContainer, setRootContainer] = useState<IDataCollectionFormContainer>(JSON.parse(JSON.stringify(rootContainer)))
    const [isDirty, setIsDirty] = useState(false);

    const resetToPropContainer = ()=> {
        setRootContainer(JSON.parse(JSON.stringify(rootContainer)));
        setIsDirty(false);
    }


    const handleSaveClick = ()=> {
        props.onSave(draftRootContainer);
        setIsDirty(false);
    }

    const handleDiscardChangesClick = ()=> {
        resetToPropContainer();
    }

    const handleCancelClick = ()=> {
        JSON.parse(JSON.stringify(rootContainer));
        props.onCancel();
    }

    const buttonStyle = {
        marginRight:1,
        marginLeft:1
    }

    return (<>

        <Typography variant="h5" component={"span"}> Applications ({draftRootContainer.subContainers.length})
           
        </Typography>

        {draftRootContainer.subContainers.map((element, i) => 
        <ApplicationNode
            ontology={ontology}
            key={`an_${i}`}
            container={element} depth={1}
            onChange={(a) => {
                const nextDraftContainer = {...draftRootContainer};
                nextDraftContainer.subContainers[i] = a;
                setRootContainer(nextDraftContainer);
                setIsDirty(true);
            }}
        />)}

        <div style={{marginTop:7}}> 
            <Button sx={buttonStyle} variant="outlined" onClick={handleCancelClick}>Cancel</Button>
            <Button sx={buttonStyle} variant="outlined" onClick={handleDiscardChangesClick} disabled={!isDirty} >Reset</Button>
            <Button sx={buttonStyle} variant="contained" onClick={handleSaveClick} disabled={!isDirty} >Save</Button>
        </div>
    </>
    )
}

export default ApplicationTree;