import { Box, Button } from "@mui/material";
import UserSummary from "./UserSummary";
import OrgSummary from "./OrgSummary"
import { useEffect, useState } from 'react';
import { ProfileApi } from "../../api/user/ProfileApi";
import { IUser } from "../../datamodel/interfaces/user.interfaces";
import { Route, Routes, useNavigate } from "react-router-dom";



const UserHome = () => {
    const [profile, setProfile] = useState<IUser>();
    const navigate = useNavigate();
    useEffect(() => {
        ProfileApi.getProfile().then(
            (p) => setProfile(p)
        );
    }, [])

    return (
        <Box sx={{padding:3}}>

            {/* <Typography variant="h6">User home</Typography> */}
            <div>
                <Button onClick={()=>navigate("programs")}>Programs</Button>
                <Button onClick={()=>navigate("user")}>User info</Button>
            </div>
            <Routes>
                <Route path="/" element = {
                    <OrgSummary />
                }/>
                <Route path="programs" element = {
                    <OrgSummary />
                }/>
                 <Route path="user" element = {
                    profile && <UserSummary profile={profile} />
                }/>

                

            </Routes>

            {/* <Grid container>
                <Grid item xs={6}>
                    <Card sx={{ margin: 2 }}>
                        <CardHeader
                            title={
                                <Typography variant="h6">User</Typography>
                            } />
                        <CardContent>
                            {profile && <UserSummary profile={profile} />}
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton><EditOutlinedIcon /></IconButton>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card sx={{ margin: 2 }}>
                        <CardHeader
                            title={
                                <Link to="/org"><Typography variant="h6">Registered orgs</Typography></Link>
                            }
                        />
                        <CardContent>
                            <OrgSummary />
                        </CardContent>
                        <CardActions disableSpacing>
                            <IconButton aria-label="add to favorites">
                                <AddCircleOutlineOutlinedIcon />
                            </IconButton>
                            <IconButton aria-label="add to favorites">
                                <QuestionMarkOutlinedIcon />
                            </IconButton>
                        </CardActions>


                    </Card>
                </Grid>
            </Grid> */}
        </Box>
    )
}

export default UserHome;