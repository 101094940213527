import './App.css';
import Home from './components/content/home';
import Logo from './components/content/logo';
import { Routes, Route, Outlet } from 'react-router-dom';
import UkraineTab from './components/content/Ukraine';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import CoreTech from './components/content/core-tech';
import RoadmapPage from './components/content/roadmap';
import { useTranslation } from 'react-i18next';
import ContactUs from './components/content/contact-us';
import LandscapeContainer from './components/landscape/LandscapeContainer';
import ReferenceArchitectureToc from './components/ref-arch/ReferenceArchitectureToC';
import Login from './components/user/Login';
import Profile from './components/user/Profile';
import OrgHome from './components/org/OrgHome';
import HiddenMenu from './components/HiddenMenu';
import UserHome from './components/home/UserHome';


export const themeOptions: ThemeOptions = {
  typography: {
    subtitle1: {
      fontSize: '1.4rem',
      fontWeight: 'bold'
    },
    body1: {
      fontSize: '1.2rem',
    },
  },
};

const theme = createTheme(themeOptions);  

function App() {
   const {t} = useTranslation();
   // eslint-disable-next-line
   const pages = [
    // {name:'Ukraine', route:"ukraine"}, 
    // {name:"Roadmap", route:"roadmap"},
    {name: t('tech-landscape'), route: ""},
    {name: "Reference Architecture", route: "reference-architecture"},
    {name: "Contact", route: "contact"}
    // {name:t('our team'), route:"about"}
    ]

  return ( 
    <Box height="100vh">
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <Logo/>
        {/* 
        <LangSwitcher />
        <Header pages={pages}/>
        */}
        <Box sx={{maxWidth:1500}}>
        <Routes>
          <Route path="/" element={<LandscapeContainer />}/>
          <Route path="/about" element={<Home />}/>
          <Route path="core-tech" element={<CoreTech/>} />
          <Route path="roadmap" element={<RoadmapPage/>} />
          <Route path="ukraine" element={<UkraineTab />} />
          <Route path="landscape" element={<LandscapeContainer/>} />
          <Route path="reference-architecture/*" element={<ReferenceArchitectureToc/>} />
          <Route path="contact" element={<ContactUs/>} />
          <Route path="login" element={<Login />} />
          <Route path="profile" element={<Profile />} />
          <Route path="menu" element={<HiddenMenu />} />
          <Route path="user-home/*" element={<UserHome />} />
          <Route path="org/*" element={<OrgHome />} />
          {/* <Route path="deployment" element={<Deployment/>} /> */}

          {/* <Route path="org/:orgId" element={<OrgHome />} />
        
          <Route path="org/:orgId/commcare/:commcareInstanceId" element={<CommcareHome />} /> */}
          {/* <Route path="org/:orgId/commcare/:commcareId" element={<CommcareDetail />} /> */}
          
          
          
            {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
            {/* <Route path="*" element={<NoMatch />} /> */}
          
        </Routes>
        <Outlet />
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default App;
