import {  Select, MenuItem,  FormControlLabel, Checkbox, Link as MuiLink, TableRow, TableCell, SelectChangeEvent } from "@mui/material";
import { ReactNode, useState } from "react";
import { IDataCollectionQuestion, ProtectionActions } from "../../datamodel/interfaces/datacollectioninterfaces";

interface IQuestionNodeProps {
    question: IDataCollectionQuestion
    depth: number,
    ontology: any,
    onChange: (newQuestion: IDataCollectionQuestion) => void
}

const QuestionNode = (nodeProps: IQuestionNodeProps) => {
    const { question,  ontology } = nodeProps;
    const [isProtected, setIsProtected] = useState<boolean>(question.properties !== undefined && question.properties.isProtectedInfo);

    if (!question.properties) {
        question.properties = {
            isProtectedInfo: false,
            protectionAction: undefined
        }
    }
    
    // eslint-disable-next-line
    const [isOpen, setIsOpen] = useState(false);
    const [protectionAction, setProtectionAction] = useState<ProtectionActions | undefined>(question.properties.protectionAction);
    const [semanticValue, setSemanticValue] = useState<string>(question.properties.semanticValue ?? "");

    const triggerOnChange = (newIsProtected: boolean, newProtectionAction: ProtectionActions | undefined
        , semanticVal: string|undefined) => {
        console.log("newIsProtected2", newIsProtected)

        question.properties = {
            isProtectedInfo: newIsProtected!,
            protectionAction: newProtectionAction,
            semanticValue:semanticVal
        }
        console.log(question);
        nodeProps.onChange(question);
    };

    // const onQuestionChange = (newIsProtected: boolean, newProtectionAction: ProtectionActions | undefined) => {
    //     setIsProtected(newIsProtected);
    //     setProtectionAction(newProtectionAction);
    //     alert(1);
    // }

    const handleCheckChangeIsProtected = (e: any) => {
        const newIsProtected = e.target.checked;
        console.log("newIsProtected", newIsProtected)
        const newProtectionAction = newIsProtected ? ProtectionActions.HASH : undefined;
        setIsProtected(newIsProtected);
        setProtectionAction(newProtectionAction)
        triggerOnChange(newIsProtected, newProtectionAction, semanticValue);
    }

    const editProtectionAction = () => {

        const newProtectionAction = protectionAction === ProtectionActions.HASH ?
            ProtectionActions.MASK :
            protectionAction === ProtectionActions.MASK ?
                ProtectionActions.NEVER_SHOW :
                ProtectionActions.HASH;

        setProtectionAction(newProtectionAction);
        triggerOnChange(isProtected, newProtectionAction, semanticValue);
    }

    function handleSemanticValueChange(event: SelectChangeEvent, child: ReactNode): void {
        const semanticVal = event.target.value as string;
        setSemanticValue(semanticVal);
        triggerOnChange(isProtected, protectionAction, semanticVal);
    }

    return (
        <TableRow>
            <TableCell>
                {question.label}
            </TableCell>
            <TableCell>
                <FormControlLabel label=""
                    control={
                        <Checkbox checked={isProtected} onChange={handleCheckChangeIsProtected} ></Checkbox>
                    } />
            </TableCell>
            <TableCell>
                {isProtected && <MuiLink onClick={editProtectionAction}>{protectionAction}
                </MuiLink>}
            </TableCell>
            <TableCell>
                <Select size="small"
                value={semanticValue}
                label="Age"
                onChange={handleSemanticValueChange}>
                    {ontology.data.map( (d:any)=><MenuItem key={d.id} value={d.semantic_label}>{d.name} {d.sensitive ? "(sensitive)": ""}</MenuItem>
                    )}
                    {/* <MenuItem value="name">Name</MenuItem> */}
                
                    
                </Select>
            </TableCell>
        </TableRow>
    )
}

export default QuestionNode;