import { Box, Button, Dialog, DialogActions, DialogContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";

const ProcessRequestDataSharing = (props: any) => {
    const { request } = props;
    const [historyList, setHistoryList] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    
    function cancelShare(): void {
        setIsOpen(false);
    }

    function confirmShare(): void {
        const newList = [...historyList, {
            date: new Date(),
            status: "pending"
        }]

        setHistoryList(newList);
        setIsOpen(false);

    }

    function startShare(): void {
        setIsOpen(true);
    }

    return <>
        
        <Dialog open={isOpen}>
            <DialogContent>
                <Typography variant="body2">Confirm sharing data?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelShare}>Cancel</Button>
                <Button onClick={confirmShare}>Confirm</Button>
            </DialogActions>
        </Dialog>

        <Box sx={{ marginTop: 3 }}>
            <div><b>{request.name}</b></div>
            <Button variant="contained" onClick={startShare}>Share data</Button>
            <Paper elevation={2} sx={{padding:2, marginTop:3}}>
                <Typography variant="h6">History</Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Status</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
        {historyList.map( (item, index)=> <TableRow key={`item_${index}`}>
            <TableCell>{item.date.toUTCString()}</TableCell>
            <TableCell>{item.status}</TableCell>
            
        </TableRow> )}
                    </TableBody>
                </Table>
            </Paper>
        </Box>

    </>

}

interface IProcessDataSharingProps {
    requests: any[]
}
const ProcessDataSharing = (props: IProcessDataSharingProps) => {
    const { requests } = props;
    return <>
        <Typography variant="h4">Data Sharing</Typography>

        {requests.map((request: any) => <ProcessRequestDataSharing request={request} />)}

    </>
}

export default ProcessDataSharing;