import { CodeResponse } from '@react-oauth/google';
import {ApiHelper} from '../ApiHelper'
import { TokenHelper } from '../TokenHelper';
import { AuthenticationResult } from '@azure/msal-common';

export class UserApi {
    static cachedUser:any = null;

    static async login(username:string, password:string) {
        const data = {"username": username, "password": password};
        let res = await ApiHelper.post("auth-passport-local/login", data);
        let token = res.data.access_token;
        await TokenHelper.saveToken(token);

        return true;
    }

    static async loginGoogleFromCode(googleCodeResponse: CodeResponse) {
        let res = await ApiHelper.post("auth-passport-local/login/google", googleCodeResponse);
        let token = res.data.access_token;
        await TokenHelper.saveToken(token);
        console.log("google client token", token);

        return true;
    }

    static async loginMicrosoft(authenticationResult: AuthenticationResult) {
        let res = await ApiHelper.post("auth-passport-local/login/microsoft", authenticationResult);
        let token = res.data.access_token;
        await TokenHelper.saveToken(token);
        console.log("microsoft client token", token);
    }


}