import axios, { AxiosRequestConfig } from "axios";
import {TokenHelper} from './TokenHelper'

export class ApiHelper {
    private static getUnauthenticatedConfig = ():AxiosRequestConfig<any> => {
        return {headers: {"Content-Type":"application/json"}}
    };
    
    private static getAuthenticatedConfig = ():AxiosRequestConfig<any> => { 
        return {
            headers: {
                "Content-Type":"application/json",
                "Authorization": `Bearer ${TokenHelper.getToken()}`
            }
        }
    };

    private static makeUrl(url:string) {
        let newUrl =  `${process.env.REACT_APP_BASE_URL}${url}`;
        return newUrl;
    }

    public static async get(url:string) {
        let res = await axios.get(ApiHelper.makeUrl(url));
        return res;
    }

    public static async post(url:string, data:any) {
        let res = await axios.post(ApiHelper.makeUrl(url), data, ApiHelper.getUnauthenticatedConfig() );
        return res;
    }

    static async authenticatedGet(url:string) {
        // console.log("authenticatedGet:ApiHelper.authenticatedConfig", ApiHelper.getAuthenticatedConfig());
        // console.log("authenticatedGet:url", url);

        let res = await axios.get(ApiHelper.makeUrl(url), ApiHelper.getAuthenticatedConfig());
        return res;
    }

    static async authenticatedPost(url:string, data:any) {
        const opt =  ApiHelper.getAuthenticatedConfig();
        const fullUrl = ApiHelper.makeUrl(url)
        let res = await axios.post(fullUrl, data, opt);
        return res;
    }


    static async authenticatedPut(url:string, data:any) {
        let res = await axios.put(ApiHelper.makeUrl(url), data, ApiHelper.getAuthenticatedConfig());
        return res;
    }

    static async authenticatedPatch(url:string, data:any) {
        let res = await axios.patch(ApiHelper.makeUrl(url), data, ApiHelper.getAuthenticatedConfig());
        return res;
    }

    static async authenticatedDelete(url:string) {
        let res = await axios.delete(ApiHelper.makeUrl(url), ApiHelper.getAuthenticatedConfig());
        return res;
    }


}