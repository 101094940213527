import { Typography, Tooltip, Box } from "@mui/material";
//import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListIcon from '@mui/icons-material/List';

interface ReferenceArchitectureSectionProps {
    name: string,
    tooltip:string,
    id:string
}


const ReferenceArchitectureSection = (props:React.PropsWithChildren<ReferenceArchitectureSectionProps>) => {
    const {name, tooltip, id} = props;
    return (
        <Box sx={{marginBottom:5}}>
            <Typography variant="h6" id={id}>
               {name}
            {/* <Tooltip title={tooltip}>
                    <InfoOutlinedIcon color="primary" fontSize="small"/>
            </Tooltip> */}
            </Typography>
            <Typography variant="subtitle2">
                {tooltip}
            </Typography>
            <Box>
                {props.children}
            </Box>
            <a href={`#toc`}>
                <Tooltip title="Back to Table of Contents">
                    <ListIcon fontSize="medium"/>
                </Tooltip>
            </a>
        </Box> 
    )
}

export default ReferenceArchitectureSection;