import { Link } from "react-router-dom";

const HiddenMenu = () => {
    return (
        <div>
            Menu <br/>
            <Link to="/login">Login</Link> <br/>
            <Link to="/user-home">User home</Link> <br/>
            <Link to="/profile">Profile</Link> <br/>
            <Link to="/org">Orgs</Link>
        </div>
    )

}

export default HiddenMenu;