import {  Checkbox,  Grid } from '@mui/material';
import {ReactNode, useState} from 'react'


interface IChecklistItem {
    description:ReactNode
}


interface ISetupChecklistProps {
    checklistItems:IChecklistItem[]
}


const SetupChecklist = (props:ISetupChecklistProps)=>{
    const {checklistItems} = props;
    const [checkboxStatus, setCheckboxStatus] = useState<Boolean[]>(Array(checklistItems.length).fill(false))

    return (<Grid container>
    {checklistItems.map((item, i)=>{
    
    
    return <>
        <Grid   key={`${i}checkbox1`} item xs={1} sx={{background: i%2===0? 'lightgray': 'none'}}>
        <Checkbox  onChange={(e)=>{
            const temp = [...checkboxStatus];
            temp[i]=e.target.checked;
        setCheckboxStatus(temp);
    }}/></Grid>
    <Grid item  key={`${i}checkbox2`}  xs={11} sx={{background: i%2===0? 'lightgray': 'none'}}>
     {item.description}
    </Grid>
    </>})}
    </Grid>)
}

export default SetupChecklist;