import { Box, Tab, Tabs, Typography } from "@mui/material";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import { useCallback, useEffect, useState } from "react";
import { a11yProps, TabPanel } from "../Tab";
import requests from './DataSharing/requests.json'
import DataSharingRequestsConfig from "./DataSharing/DataSharingRequestsConfig";
import ProcessDataSharing from "./DataSharing/ProcessDataSharing";
import { IDataCollectionFormContainer } from "../../datamodel/interfaces/datacollectioninterfaces";
import { KoboApi } from "../../api/KoboApi";
import { IKoboInstance } from "../../datamodel/interfaces/IKoboInstance.interface";

interface IDataSharingProps {
    org:IOrganization
}

const DataSharing = (props:IDataSharingProps) => {
    const {org} = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [formDataFromDB, setFormDataFromDB] = useState<IDataCollectionFormContainer>(
        { name:"",
        subContainers:[],
        forms:[]}
    );

    const loadKoboInstance = useCallback( ()=>{
        KoboApi.getKoboInstances(org._id).then(
            (ci:IKoboInstance[])=> {
                if(ci.length === 0) {
                    console.log("KoboInstance List not available");
                    return;
                } else if( !ci[0].parsedMetadata){
                    console.log("No parsed metadata");
                } else {
                    setFormDataFromDB(ci[0].parsedMetadata!);
                }
            }
        )
    }, [org._id])

    useEffect(loadKoboInstance, [loadKoboInstance]);


    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <>
    <Typography variant="h4"><br/></Typography>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="standard"
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="IT Config Tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Configure Data Sharing" {...a11yProps(0)} />
                <Tab label="Share Data" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <DataSharingRequestsConfig requests={requests} formData={formDataFromDB}/>
                
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
            <ProcessDataSharing requests={requests}/>
            </TabPanel>
        </Box>    
    </>
}

export default DataSharing;