import { IStepSummaryProps } from "../IStepSummaryProps";
import { IConfigResourceSettings } from "../../../../../datamodel/interfaces/deployment-settings.interfaces";
import Datasheet from "../../../../Datasheet";
import InfrastructureSummary from "./infrastructure-summary";

interface IMainSummaryProps extends IStepSummaryProps {
    mainSettings:IConfigResourceSettings|undefined,
}

const MainSummary = (props:IMainSummaryProps)=> {
    const {onEdit, mainSettings, showEdit}=props;
    return (
        mainSettings 
        ? <InfrastructureSummary 
            title={"Subscription"} 
            onEdit={onEdit} 
            showEdit={showEdit} 
            editDisabled={false}
            isReadyForDeployment = {mainSettings.readyForDeployment}
        >
            
            {mainSettings?.settings &&
                 <Datasheet data={ 
                        {
                            subscriptionId: mainSettings.settings.subscriptionId
                        }
                    } keys={[ {displayName: "Subscription ID", keyValue: "subscriptionId"}]} />
            }
        </InfrastructureSummary>
        :<div>Loading main...</div>
    )
}

export default MainSummary;