import { ICoreObject } from "./coreobject.interface"

export enum AzResourceType {
    MAIN='main',
    RESOURCE_GROUP = 'resource_group',
    KEY_VAULT = 'key_vault',
    STORAGE_ACCOUNT='storage_account',
    EXPORT_DATABASE='export_db',
    COMMCARE_EXPORT='commcare_export'
}

export interface IConfigResourceSettings extends ICoreObject {
    orgId:string, //parent id of the setting
    configId:string,
    resourceType: AzResourceType,
    resourceDisplayName:string,
    settings: any,
    readyForDeployment: boolean
}

export interface IAzConfigSettings extends ICoreObject {
    orgId: any;
    subscriptionId:string,
    location:string,
    //displaySettingsValues:IConfigResourceSettings[],
    settingsValues: IConfigResourceSettings[]
}


//resource type metadata - what settings are available by resource type
// export interface IConfigSettingMetadata {
//     resourceType: AzResourceType,
//     availableSettings: {
//         id:string,  //resource_group_name
//         displayName:string  //Resource group name
//         //TODO: consider adding links for more info
//     }[]
// }


// export interface IConfigSettingValue {
//     settingId:string,
//     value:string
// }

