/// Class to support configuration of azure resources for deployment with Terraform

import { AxiosResponse } from "axios";
import { IConfigResourceSettings } from "../../datamodel/interfaces/deployment-settings.interfaces";
import { ApiHelper } from "../ApiHelper";

export class AzConfigApi {

    //orgid - really means program
    //resourceConfigId - this is defined in the domain model (for example - we have one resource group for all the resources in the pipeline)
    public static async getConfig(orgId: string, resourceConfigId: string) {
        const url:string = `most-unique/${orgId}/az-settings/${resourceConfigId}`;
        const res = await ApiHelper.authenticatedGet(url);
        return res.data;

        // // return res.data;
        // let result:IConfigResourceSettings;

        // if (resourceConfigId === 'resource_group') {
        //     result =  {
        //         orgId: orgId,
        //         configId: 'resource_group',
        //         resourceType: AzResourceType.RESOURCE_GROUP,
        //         settings: {
        //             name: "",
        //             location: ""
        //         }
        //     }
        // }
        // else if (resourceConfigId === 'key_vault') {
        //     result =  {
        //         orgId:orgId,
        //         configId: 'key_vault',
        //         resourceType: AzResourceType.KEY_VAULT,
        //         settings: {
        //             name: "",
        //             location: ""
        //         }
        //     }
        // }
        // else if (resourceConfigId === 'storage_account') {
        //     result =     {
        //         orgId:orgId,
        //         configId:'storage_account',
        //         resourceType: AzResourceType.STORAGE_ACCOUNT,
        //         settings: {
        //             name: "",
        //             location:""
        //         }
        //     }
        
        // }
        // else {
        //     throw new Error();
        // }

        // return result;
    }

    public static async saveSetting(orgId:string,  config:IConfigResourceSettings) {
        
        let res:AxiosResponse<any, any>;
        

        if(config._id) {
            const url=`az-settings/${config._id}`;
            res = await ApiHelper.authenticatedPut(url, config);
        }
        else 
        {
            throw new Error();
            // const url2=`terraform-config/:terraformConfigId/az-settings`;
            // res = await ApiHelper.authenticatedPost(url2, config);
        
        }
        
        return res.data;
    }
}