import { IStepSummaryProps } from "../IStepSummaryProps";
import { IConfigResourceSettings } from "../../../../../datamodel/interfaces/deployment-settings.interfaces";
import Datasheet from "../../../../Datasheet";
import InfrastructureSummary from "./infrastructure-summary";

interface IKeyVaultSummaryProps extends IStepSummaryProps {
    keyVaultSettings:IConfigResourceSettings|undefined,
    resourceGroupDependency:IConfigResourceSettings|undefined,
    resourceGroupValidator:(rg:IConfigResourceSettings|undefined)=>boolean
}

const KeyVaultSummary = (props:IKeyVaultSummaryProps)=> {
    const {onEdit, keyVaultSettings, resourceGroupValidator, resourceGroupDependency, showEdit}=props;
    return (
        keyVaultSettings 
        ? <InfrastructureSummary 
            title={"Key Vault"} 
            onEdit={onEdit} 
            showEdit={showEdit} 
            editDisabled={!resourceGroupValidator(resourceGroupDependency)}
            isReadyForDeployment = {keyVaultSettings.readyForDeployment}
        >
            
        {resourceGroupDependency && (!resourceGroupValidator(resourceGroupDependency)) ? <div>Resource group must be defined before key vault</div> :<></>}
        <div style={{maxWidth:400}}>
            {keyVaultSettings?.settings &&
                 <Datasheet data={ 
                        {
                            name: keyVaultSettings.settings.name, 
                            resourceGroupName:resourceGroupDependency?.settings.name
                        }
                    } keys={[ {displayName: "Name", keyValue: "name"}, {displayName:"Resource Group Name", keyValue:"resourceGroupName"}]} />
            }
        </div>
        </InfrastructureSummary>
        :<div>Loading...</div>
    )
}

export default KeyVaultSummary;