import { ReactNode } from "react";
import Datasheet from "./Datasheet";
import DopCollapse from "./DopCollapse";

interface IDatasheetFromObjectProps {
    dataObject:any,
    title:ReactNode,
    isRecursive:boolean,
    excludeNamePattern?: RegExp,
    depth?:number
} 

const DatasheetFromObject = (props:IDatasheetFromObjectProps) => {
    const keys = Object.keys(props.dataObject);
    let keySpec:any[] = [];
    const arraysToRecurse:any[] = [];
    const arrayKeysToRecurse:string[] = [];
    const objectsToRecurse:any[]=[];
    const objectKeysToRecurse:string[]=[];

    keys.forEach( key=> {
        if(Array.isArray( props.dataObject[key]))
        {
            arraysToRecurse.push(props.dataObject[key]);
            arrayKeysToRecurse.push(key);
        }
        else if ( typeof props.dataObject[key]==='object') {
            objectsToRecurse.push(props.dataObject[key]);
            objectKeysToRecurse.push(key);
        }
        else
        { 
            keySpec.push(
                {keyValue: key, displayName: key}
            );
        }
    })

    const children = <>
    {objectsToRecurse.map( (dataObj, i)=><DatasheetFromObject key={`child0_${i}`} dataObject={dataObj} 
    
    title={`${props.title} ${objectKeysToRecurse[i]}`} isRecursive={true} excludeNamePattern={props.excludeNamePattern} depth={(props.depth ?? 0)+1}/>)}
        
        {arraysToRecurse.map( 
            (array,i)=> 
                array.map( 
                    (dataObj:any, j:number) => <DatasheetFromObject key={`child1_${i}_${j}`} dataObject={dataObj} title={`${props.title} ${arrayKeysToRecurse[i]} #${j+1}`} isRecursive={true} excludeNamePattern={props.excludeNamePattern} depth={(props.depth ?? 0)+1}/>
                )
        )}
    </>

    return <>
        <Datasheet title={props.title} data={props.dataObject} keys={keySpec} excludeNamePattern={props.excludeNamePattern}/>
        
        {(props.depth && props.depth > 0 && (objectKeysToRecurse.length > 0 || arraysToRecurse.length >0)) ?
        <DopCollapse title={<>{props.title} Details</>} sx={{ marginTop: 0, marginBottom: 0, padding: 0 }
    }>
            {children}
        </DopCollapse>
        : <>{children}</>
        }
        </> 
}

export default DatasheetFromObject;