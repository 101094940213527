import {useEffect, useState} from 'react'
import { ProfileApi } from '../../api/user/ProfileApi';
import HiddenMenu from '../HiddenMenu';
import { IUser } from '../../datamodel/interfaces/user.interfaces';

const Profile=()=> {
    const [user, setUser] = useState<IUser>()
    useEffect( 
            () => {
                ProfileApi.getProfile().then(
                    (u)=>setUser(u)
                )
        }, [] );

    return (
        <>
        <div>Profile: {user?._id}, {user?.username}</div>
        <HiddenMenu/>
        </>
    )
}

export default Profile;