import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { ITerraformCloudConfig, TerraformConfigValidationStatus } from "../../../datamodel/interfaces/terraform.interfaces";
import DopCollapse from "../../DopCollapse";
import TerraformSummary from "../../terraform/TerraformSummary";
import { useCallback, useEffect, useState } from "react";
import { InfrastructureApi } from "../../../api/InfrastructureApi";
import TerraformConfigForm from "../../terraform/TerraformConfigForm";
import { IOrganization } from "../../../datamodel/interfaces/organization.interfaces";

interface ITerraformConnectionConfig {
    org: IOrganization
}

const TerraformConnectionConfig = (props:ITerraformConnectionConfig)=> {
    const {org} = props;
    const [isAddTerraformOpen, setIsAddTerraformOpen] = useState(false)
    const [terraformConfig, setTerraformConfig] = useState<ITerraformCloudConfig>();
    


////Terraform config
const refreshTerraformConfig = useCallback(
    () => {
        InfrastructureApi.getInfrastructureConfigs(org._id).then(
            (config: ITerraformCloudConfig[]) => {
                if (config.length > 1) {
                    throw new Error('more than 1 terraform config. One or zero expected.')
                }

                setTerraformConfig(config.length === 0 ? undefined : config[0])
            }
        );
    }
    , [org]
);

const saveConfigInternal = async (config: ITerraformCloudConfig) => {
    console.log("terraform-config", config);
    if (config._id) {
        await InfrastructureApi.update(config._id, config, org._id);
    }
    else {
        await InfrastructureApi.create(config, org._id);
    }
    refreshTerraformConfig();
    setIsAddTerraformOpen(false);

}
/// Terraform config related UI events
const cancelConfigSave = () => {
    setIsAddTerraformOpen(false);
}

const handleAddTerraformConfigClick = () => {
    setIsAddTerraformOpen(true);
}

useEffect(() => {
    refreshTerraformConfig();
}, [refreshTerraformConfig])

const buttonStyle = {
    marginTop: 2,
    marginRight: 2
}

const handleValidateClick = async () => {
    if (!terraformConfig) throw new Error("terraform config not defined");
    await InfrastructureApi.validate(terraformConfig, org._id);
    refreshTerraformConfig();
}

const handleEditConfigClick = () => {
    setIsAddTerraformOpen(true);
}



    return <>
    <Dialog open={isAddTerraformOpen} >
                <DialogContent>
                    <Typography variant='h6'>Terraform Setup</Typography>
                    <TerraformConfigForm
                        orgId={org._id}
                        handleSubmit={saveConfigInternal}
                        allowCancel={true}
                        handleCancel={cancelConfigSave}
                        data={terraformConfig}
                    />
                </DialogContent>
            </Dialog>

            {
                            terraformConfig
                                ? <DopCollapse title={<b>Connect to Terraform</b>} isCollapsible={false} elevation={0}>
                    <Box sx={{ borderTop: 2, marginTop: 3, borderTopColor: "lightgray" }}>
                        <div>
                                    <TerraformSummary terraformConfig={terraformConfig} />
                                    {
                                        terraformConfig.validationStatus === TerraformConfigValidationStatus.PENDING &&
                                        <Button sx={buttonStyle} onClick={handleValidateClick}>Validate</Button>
                                    }
                                    <Button sx={buttonStyle} onClick={handleEditConfigClick}>Edit Config</Button>

                                </div>
                                
                    </Box>
                </DopCollapse>
                : <Button sx={buttonStyle} onClick={handleAddTerraformConfigClick}>Add Terraform Config</Button>

            }
    </>
}

export default TerraformConnectionConfig;