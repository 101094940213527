import { ApiHelper } from "./ApiHelper";

//class to access terraform data through our own service
export default class TerraformApi {
    //org/:orgId/configuration-versions
    private static getConfigVersionsBaseUrl(orgId:string) {
        return `org/${orgId}/configuration-versions`;
    }

    private static getRunsBaseUrl(orgId:string) {
        return `org/${orgId}/terraform-runs`;
    }

    static async getConfigList(orgId:string) {
        const res = await ApiHelper.authenticatedGet(TerraformApi.getConfigVersionsBaseUrl(orgId));
        return res.data as any;
    }

    /// RUNS
    static async getRunsList(orgId:string) {
        const url = TerraformApi.getRunsBaseUrl(orgId);
        const res = await ApiHelper.authenticatedGet(url);
        return res.data as any;
    }

    static async applyPlannedRun(orgId:string, runId:string) {
        const url = `${TerraformApi.getRunsBaseUrl(orgId)}/${runId}/apply`;
        const res = await ApiHelper.authenticatedPatch(url, {});
        return res.data as any;
    }

    static async discardPlannedRun(orgId:string, runId:string) {
        const url = `${TerraformApi.getRunsBaseUrl(orgId)}/${runId}/discard`;
        const res = await ApiHelper.authenticatedPatch(url, {});
        return res.data as any;
    }

    static async createRun(orgId: any) {
        const url = `org/${orgId}/terraform-config/start`;
        await ApiHelper.authenticatedPost(url, {});
        return {};
    }

    static async createRunDestroyAll(orgId: string) {
        const url = `org/${orgId}/terraform-config/destroy-all`;
        await ApiHelper.authenticatedPost(url, {});
        return {};
    }
    


    
}