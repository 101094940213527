import { Box, Tab, Tabs, Typography } from "@mui/material";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import DataSettingsAndProtectionConfig from "./DataConfig/DataSettingsAndProtectionConfig";
import ViewDataConfig from "./DataConfig/ViewDataConfig";
import { a11yProps, TabPanel } from "../Tab";
import { useState } from "react";
import MoreDataSourcesConfig from "./DataConfig/MoreDataSourcesConfig";

interface IDataConfigProps {
    org:IOrganization
}

const DataConfig = (props:IDataConfigProps)=> {
    const {org} = props;
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    return <>
    <Typography variant="h4"><br/></Typography>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="standard"
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="IT Config Tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Data Collection" {...a11yProps(0)} />
                <Tab label="Additional Data Sources" {...a11yProps(1)} />
                {/* <Tab label="Database View Config" {...a11yProps(2)} /> */}
                
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <DataSettingsAndProtectionConfig org={org}/>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <MoreDataSourcesConfig/>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <ViewDataConfig />
            </TabPanel>
        </Box>    
    </>
}

export default DataConfig;