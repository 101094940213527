import { IUser } from "../../datamodel/interfaces/user.interfaces";
import { ApiHelper } from "../ApiHelper";

export class ProfileApi {

    //get profile of current logged user
    public static async  getProfile():Promise<IUser> {
       let res = await ApiHelper.authenticatedGet("profile");
       //let user = res.data;
       return res.data as IUser;
    }
}