import {  Button,  Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { ICommcareInstance } from '../../datamodel/interfaces/commcare.interfaces';
import { IOrganization } from '../../datamodel/interfaces/organization.interfaces';
import DopCollapse from '../DopCollapse';
import { PropsWithChildren } from 'react';

interface ICommcareListProps {
    org: IOrganization,
    commcareInstanceList: ICommcareInstance[],
    currentCommcareInstance: ICommcareInstance | undefined,
    addCommcareInstance: () => void
    setCurrentCommcareInstance: (ci: ICommcareInstance) => void
}

const CommcareList = (props: ICommcareListProps & PropsWithChildren) => {
    const { addCommcareInstance, commcareInstanceList } = props;
    //const   {orgId} = useParams();
    //const [selectedCommcareInstance, setSelectedCommcareInstance] = useState<ICommcareInstance>();
    
    const handleAddCommcareInstance = () => {
        addCommcareInstance();
    }

    return ( <>
        <DopCollapse
            title={<>
                Commcare Instances ({commcareInstanceList.length})
                </>
            } >

        
                        {commcareInstanceList.length === 0 && <Button onClick={handleAddCommcareInstance}>Add Commcare Instance</Button>}
                        {commcareInstanceList.length === 0 ?
                            <Typography variant='subtitle2' >At the moment there are no CommCare instances.  Start by adding one.</Typography >
                            :
                            <>
                                {Array.isArray(commcareInstanceList) && <Table size="small">

                                    <TableBody>
                                        {commcareInstanceList.map((commcareInstance, i) => {
                                            return (
                                                <TableRow key={commcareInstance._id}>
                                                    <TableCell>{commcareInstance.name}</TableCell>
                                                    <TableCell>{commcareInstance.description}</TableCell>
                                                    <TableCell>{commcareInstance.username}</TableCell>

                                                    <TableCell>{commcareInstance._id}</TableCell>
                                                    <TableCell>
                                                        {/* <Link 
                                                            onClick={()=>props.setCurrentCommcareInstance(commcareInstance)}
                                                            //to={`commcare/${commcareInstance._id}`}
                                                        >Manage</Link>
                                                        <Button component={Link}>M</Button> */}

                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                }
                            </>
                        }

                        {props.children}
        </DopCollapse>
        
        </>
    )
}

export default CommcareList;