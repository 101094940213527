import { TableContainer, Box, Table, 
    // TableHead, TableFooter,
    TableRow, 
    TableCell, TableBody, Typography, IconButton, Collapse } from "@mui/material";
import { ISession, IUser, IUserProvider } from "../../datamodel/interfaces/user.interfaces";
import {useState} from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


interface IUserSummaryProps {
  profile:IUser
}



const  ProviderRow = (props: { provider: IUserProvider }) => {
  const { provider } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={2}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant='h5' sx={{fontWeight: 'bold'}} component="span"> Login provider: {provider.name} </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label={`${provider.providerData.name}-detail`}>
                <TableBody>
                { provider.name === "google" && <>
        <TableRow>
          <TableCell align="right">Name</TableCell>
          <TableCell align="left">{provider.providerData.name}</TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell align="right">email</TableCell>
          <TableCell align="left">{provider.providerData.email}</TableCell>
        </TableRow> */}
        <TableRow>
          <TableCell align="right">Verified email</TableCell>
          <TableCell align="left">{provider.providerData.verified_email? "true": "false"}</TableCell>
        </TableRow>
        </>}

        { provider.name === "microsoft" && <>
        <TableRow>
          <TableCell align="right">Name</TableCell>
          <TableCell align="left">{provider.providerData.displayName}</TableCell>
        </TableRow>
        </>}

        <TableRow>
          <TableCell align="right">userId</TableCell>
          <TableCell align="left">{provider.userId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Date created</TableCell>
          <TableCell align="left">{provider.createdDate}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="right">Date updated</TableCell>
          <TableCell align="left">{provider.updatedDate}</TableCell>
        </TableRow>
        
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const  LastSessionRow = (props: { session: ISession }) => {
  const { session } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={2}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant='h5' sx={{fontWeight: 'bold'}} component="span"> Last login </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                <TableRow>
              <TableCell align="right">Provider</TableCell>
              <TableCell align="left">{session.providerName}</TableCell>
            </TableRow>
            
            
            <TableRow>
              <TableCell align="right">Date</TableCell>
              <TableCell align="left">{session.date}</TableCell>
            </TableRow>

                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}


const UserSummary = (props:IUserSummaryProps) => {
    const {profile} = props;




    
    return (
        <>
        <TableContainer component={Box}>
      <Table size="small">
        {/* <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
            
            <TableRow>
              <TableCell align="right">email:</TableCell>
              <TableCell align="left">{profile.email}</TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell align="right">ID:</TableCell>
              <TableCell align="left">{profile._id}</TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell align="left" colSpan={2}>
              </TableCell>
            </TableRow>
            
            <LastSessionRow session={profile.lastSession} />
            
            { profile.providers.map( (p)=> <ProviderRow key={p.name} provider={p}/>)}




            
        </TableBody>
        {/* <TableFooter>
            Hello there!
        </TableFooter> */}
      </Table>
    </TableContainer>
        </>

    )
}

export default UserSummary;