import { ILandscapeItem } from "../../api/landscape/ILandscapeItem";
import { ILandscapeSection } from "../../api/landscape/ILandscapeSection";
import LandscapeItem from "./LandscapeItem";
import { Box, Card, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from "react";
import LandscapeSectionDetail from "./LandscapeSectionDetail";

// const fetcher =  (key:string) =>  {  
//     console.info(`LandscapeSection: fetching data for ${key}`);
//     const sectionParts = key.split("/");
//     const sectionName  = sectionParts[sectionParts.length-1];
//     return new LandscapeSectionApi().get(sectionName).then((section:ILandscapeSection) => {
//     return section;
// }); } // fetch(url).then((res) => res.json());


interface ILandscapeSectionProps extends ILandscapeSection {
    sectionIndex: number
}

const LandscapeSection = ( props: ILandscapeSectionProps) => {
    const [isInfoOpen, setInfoOpen] = useState(false)


    const handleInfoClick = ()=> {
        setInfoOpen(true);
    }

    const handleClose = ()=> {
        setInfoOpen(false);
    }
    
    return (
        <>
        {isInfoOpen && <LandscapeSectionDetail  {...props} isOpen={isInfoOpen} handleClose={handleClose}/>}
        <Grid item xs={props.width}>
            <Card elevation={0} variant="outlined" sx={{borderWidth:2}}>
            <CardHeader 
            
                sx={{backgroundColor: "rgb(109,61,143)"}}
                action={ 
                    <IconButton aria-label="info" onClick={handleInfoClick}>
                <InfoOutlinedIcon sx={{color: 'white'}} fontSize="large"/> </IconButton> }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title = {
                <Typography sx={{color: "white", fontWeight: "bold"}}>
                    {props.name}
                </Typography>     
            }
        />
                
            <CardContent >
            <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    '& > :not(style)': {
                    m: 1,
                    width: 128,
                    height: 128,
                    },
                }} >
                {props.items?.map( (item:ILandscapeItem, itemIndex:number)=>{return <LandscapeItem key={item.name} {...item} itemIndex={itemIndex}/>})}
            </Box>
            </CardContent>
            </Card>
        </Grid>
        </>
    )
}

export default LandscapeSection;