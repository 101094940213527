import { Container, Typography } from "@mui/material";

export default function UkraineTab () {
    return (   
        <Container>
        <Typography variant="h3">
            War in Ukraine
        </Typography>
        <Typography variant="body1" gutterBottom>
            With experience and knowledge of context, we seek to support NGOs working in Ukraine with data and technology.
        </Typography>
        <Typography variant="body1" gutterBottom>
            Our first effort is to leverage available data to create maps and tools that can serve as foundation for decision making and future fundraising.
        </Typography>
        </Container>
    )
}