import {  useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import { ICommcareInstance } from "../../datamodel/interfaces/commcare.interfaces";
import Datasheet from '../Datasheet';
import { IDataCollectionFormContainer } from '../../datamodel/interfaces/datacollectioninterfaces';
import ApplicationSummary from './commcare-form/ApplicationSummary';
import ApplicationTree from '../data-portection-form/ApplicationTree';
import ontology from '../../ontology.json'

interface ICommcareDetailProps {
    org: IOrganization,
    commcareInstance: ICommcareInstance
    refresh:()=>void,
    setCurrentCommcareInstance:(c:ICommcareInstance)=>void,
    requestUpdateMetadataFromCommcareApi:(c:ICommcareInstance)=>void //update metadat from commcare
    saveMetadataUpdate:(metadata:IDataCollectionFormContainer, commcareId:string)=>void
    // navigationPaths: {
    //     exportSetupPath: string,
    //     reportingSetupPath: string,
    //     monitoringSetupPath: string,
    //     sharingAndExportPath: string
    // }

}

const CommcareDetail = (props: ICommcareDetailProps) => {
    const {  commcareInstance } = props;
    const [isUpdateFormOpen, setIsUpdateFormOpen] = useState<boolean>(false);
    

    // const loadCommcareInstance = useCallback( ()=>{
    //     commcareId && 
    //     CommcareApi.getCommcareInstance(commcareId,org._id).then(
    //         (ci:ICommcareInstance)=> {
    //             setCommcareInstance(ci);
    //         }
    //     )
    // }, [])

    // const navigate = useNavigate();

    // useEffect(() => {
    //     commcareInstance && MonitoringEventsApi.getCommcareEventsList(org._id, commcareInstance._id)
    //         .then((result) => setEventList(result));

    // }, [org, commcareInstance])



    // const startSetup = (path: string) => {
    //     navigate(path);
    // }


    const styles = {

        stepContainer: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        steps: {
            margin: 2,
            padding: 2,
            width: 250,
            minWidth: 200,
            flexWrap: "wrap"
        },
        header: {
            margin: 2,
            padding: 2,
            maxWidth: 800
        }
    }

    const handleUpdateContainer = async (c:IDataCollectionFormContainer)=> {
        //update 
        props.saveMetadataUpdate(c, commcareInstance._id);
    }

    const handleLoadMetadata = async () => {
        props.requestUpdateMetadataFromCommcareApi(commcareInstance);
    }

    const showUpdateForm = () => {
        setIsUpdateFormOpen(true);
    }

    return (
        <Box sx={styles.header} >
            <Box sx={styles.header}  >
                <Typography variant='h6'> Commcare Instance Detail</Typography>
                    <>
                        <Datasheet data={
                            {...commcareInstance, 
                                'rawMetadataStr': commcareInstance.rawMetadata ? JSON.stringify(commcareInstance.rawMetadata).slice(0, 240)+"..." : "",
                                'parsedMetadataStr': commcareInstance.parsedMetadata ? JSON.stringify(commcareInstance.parsedMetadata).slice(0, 240)+"..." : ""
                            }
                        } keys={[
                            { keyValue: "name", displayName: "Name" },
                            { keyValue: "description", displayName: "Description" },
                            //{ keyValue: "isUsingSaas", displayName: "SaaS or Self-hosting", altValueFunction: () => commcareInstance.isUsingSaas ? "SaaS" : "Self-hosting" },
                            { keyValue: "username", displayName: "Username" },
                            { keyValue: "_id", displayName: "ID" },
                            { keyValue: "projectName", displayName: "Project Name"},
                            //{ keyValue: "appId", displayName: "Application ID"},
                            { keyValue: "server", displayName: "Server"},
                            { keyValue: "maskedKey", displayName: "Masked Key"},
                            { keyValue: "rawMetadataStr", displayName: "Commcare Raw Metadata"},
                            { keyValue: "parsedMetadataStr", displayName: "Parsed Metadata"}

                            // {keyValue: "username", displayName: "Description"},
                        ]} />
                        <div>
                            <Button onClick={handleLoadMetadata}>Load Metadata</Button>
                            {/* <Button onClick={handleViewMetadata}>View Metadata</Button> */}
                        </div>
                        {commcareInstance.parsedMetadata && <>
                            <ApplicationSummary rootContainer={commcareInstance.parsedMetadata} />
                            <Button sx={{marginTop:1}} variant='contained' onClick={showUpdateForm}>Data Protection Settings</Button>
                            </>
                        }

                        {/* <Box sx={{borderTop:2, marginTop:3, borderTopColor:"lightgray"}}>
                            <Typography variant='h6'>Export and reporting</Typography>
                            <Button >Export</Button>
                            <Button >Reporting</Button>
                        </Box> */}

                        <Dialog open={isUpdateFormOpen}>
                            <DialogContent>
                        {commcareInstance.parsedMetadata && 
                            <ApplicationTree rootContainer={commcareInstance.parsedMetadata} ontology={ontology}
                            onSave={(c:IDataCollectionFormContainer)=>{
                                handleUpdateContainer(c);
                                setIsUpdateFormOpen(false);
                            }}
                            onCancel = {()=>setIsUpdateFormOpen(false)}
                            /> 
                        }
                        </DialogContent>
                        </Dialog>
                        {/* <Button variant='contained' onClick={handleUpdateApplicationMetadataClick}>Update application metadata</Button>
                        <Button variant='contained' onClick={handleRequestApplicationMetadataClick}>Request metadata</Button> */}

                         
                        {/* <Box sx={styles.stepContainer} component="div">
                            <Paper sx={styles.steps}>
                                <Typography variant="h6">Step 1:</Typography>
                                <Typography variant="body2">Configure automatic export from CommCare to a database in the cloud.  We will guide you and automate as much as possible.  The database can serve as a backup or a data warehouse source for all your reporting. </Typography>
                                <div><Button 
                                    // onClick={() => startSetup(navigationPaths.exportSetupPath)}
                                >Learn more</Button></div>
                            </Paper>
                            <Paper sx={styles.steps}>
                                <Typography variant="h6">Step 2:</Typography>
                                <Typography variant="body2">Make form data available for reporting in PowerBI and other tools.  Protect sensitive information from leaking.</Typography>
                                <div><Button 
                                    // onClick={() => startSetup(navigationPaths.reportingSetupPath)}
                                >Learn more</Button></div>
                            </Paper>
                            <Paper sx={styles.steps}>
                                <Typography variant="h6">Step 3:</Typography>
                                <Typography variant="body2">Configure data sharing with partners and other organizations</Typography>
                                <div><Button 
                                    // onClick={() => startSetup(navigationPaths.sharingAndExportPath)}
                                >Learn more</Button></div>
                            </Paper>
                            <Paper sx={styles.steps}>
                                <Typography variant="h6">Do anytime</Typography>
                                <Typography variant="body2">Monitor and alert form structure and data for changes.  Get alerted if data is added or removed.</Typography>
                                <div><Button 
                                    // onClick={() => startSetup(navigationPaths.monitoringSetupPath)}
                                >Learn more</Button></div>
                            </Paper>
                        </Box> 
                     */}
                    
                    </>
            </Box>

            {/* {org && commcareInstance &&
                <EventList
                    // orgId={orgId} resourceType={MonitoringResourceType.COMMCARE_INSTANCE} 
                    // resourceId={commcareInstanceId} 
                    eventList={eventList} />
            } */}
        </Box>
    )
}

export default CommcareDetail;