import { Box, List, ListItem, ListItemText, Typography } from "@mui/material"
import DopCollapse from "../../DopCollapse";

const GetPasswordData = () => {
    let x = 0;
    return <DopCollapse title={<b>Credentials</b>} isCollapsible={false} elevation={0}>
        <Typography variant="subtitle2">Information the program team will need for reporting and other operations.</Typography>
        <Typography variant="body1" sx={{marginTop:3}}>
            Secrets are stored in Azure Key Vault:
        </Typography>
        <Box sx={{paddingLeft:3}}>
        <List>
          <ListItem disablePadding>
              <ListItemText primary={`${++x}. Logon to Azure Portal: https://portal.azure.com`} />
          </ListItem>
          <ListItem disablePadding>
              <ListItemText primary={`${++x}. List key vaults: https://portal.azure.com/#view/HubsExtension/BrowseResource/resourceType/Microsoft.KeyVault%2Fvaults`} />
          </ListItem>
          <ListItem disablePadding>
              <ListItemText primary={`${++x}. Select key vault: kv-data-...`} />
          </ListItem>
          <ListItem disablePadding>
              <ListItemText primary={`${++x}. Open "Secrets" section from the left nav.`} />
          </ListItem>
          <ListItem disablePadding>
              <ListItemText primary={`${++x}. Select the secret and copy the value.`} />
          </ListItem>
        </List>
        </Box>
    </DopCollapse>
}

export default GetPasswordData;