import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";

function CoreTech() {
    return (
    <Container>
        <Typography variant="h1">
            Core technology
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
    We believe that: 
        <ul>
            <li>data-driven decision-making is essential for creating positive social impact</li>
            <li>many NGOs face similar technical and data challenges</li>
            <li>by solving these as a community, we will help all of is achieve better outcomes for people serve</li>
        </ul> 
    We organize around these ideas.
    </Typography>
    <Typography variant="body1" gutterBottom component="div">
        How does it work?
        <ul>
            <li>Step 1: Share the problem</li>
            <li>Step 2: We find others who share this problem</li>
            <li>Step 3: We form a collaborations of NGOs and funders to solve the problem for everyone</li>
            <li>Step 4: Solve the problem</li>
        </ul>
        Commercial sector has done this over and over.  Every one uses the same few email tools. 
    </Typography>
    



        <Typography variant="body1" gutterBottom>
            Approach:  Unix philosophy - focused tools that do one thing well and can be composed to create a flexible system
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
            Data collection to reporting pipeline:
            <ul>
                <li>Collaborative field collection design tool</li>
                <li>Modern data collection forms</li>
                <li>Case management</li>
                <li>Field collection export and backup</li>
                <li>Domain model</li>
                <li>Data warehouse and reporting</li>
            </ul>
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
            Tools: 
            <ul>
                <li>CommCare backup</li>
                <li>Kobo backup</li>
                <li>React app to Open Data Kit</li>
                <li>MEL Common Data Model</li>
            </ul>
        </Typography>
        
    </Container>
    )
}

export default CoreTech;