import { useEffect, useState } from "react";
import { LandscapeContainerApi } from "../../api/landscape/LandscapeContainerApi";
import useSWR from 'swr'
import { ILandscape } from "../../api/landscape/ILandscape";
import { ILandscapeSection } from "../../api/landscape/ILandscapeSection";
import LandscapeSection from "./LandscapeSection";
import { Card, CardContent, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from 'remark-gfm'
import { useTranslation } from "react-i18next";
import VersionLabel from "../content/VersionLabel";




const fetcher =  (url:string) =>  {  return new LandscapeContainerApi().get().then((landscape) => landscape); }
 // fetch(url).then((res) => res.json());

const LandscapeContainer =  () => {
    let [landscape, setLandscape] = useState<ILandscape>();
    const [isInfoOpen, setInfoOpen] = useState(false);
    const {i18n} = useTranslation();

    const { data, error, isLoading } = useSWR('xxx',  fetcher);

    useEffect( ()=> setLandscape(data), [data, isLoading] )
 
    if (error) return <div>An error has occurred.</div>;
    if (isLoading) return <div>Loading...</div>;

    const handleInfoOpen = ()=> setInfoOpen(true);
    const handleInfoClose = () => setInfoOpen(false);
    const htlDescription_en:string = 
`The Humanitarian Tech Landscape (HTL) diagram seeks to provide an overview of the technical ecosystem supporting humanitarian efforts. Our start and current focus is on humanitarian response in Ukraine. HTL aims to help organizations navigate the evolving landscape of technologies and options, and to facilitate decisions when deploying programs in a specific context.  HTL is inspired by The Cloud Native Landscape (https://landscape.cncf.io/). 

Our intent is to continue evolving and extending this database to make it a useful tool for NGO technology and architecture teams.`; 
    
    const htlDescription_ua = 
`Діаграма Ландшафту Технологій Гуманітарної Допомоги (HTL) прагне надати огляд технічної екосистеми, що підтримує гуманітарні зусилля. Наш початок та поточний фокус спрямований на гуманітарну відповідь в Україні. HTL спрямована на допомогу організаціям у навігації еволюційного ландшафту технологій та можливостей, та на полегшення прийняття рішень при розгортанні програм у конкретному контексті. HTL надихнена Ландшафтом Облачних Технологій (https://landscape.cncf.io/).

Наша мета полягає в подальшому розвитку та розширенні цієї бази даних, щоб зробити її корисним інструментом для команд технологій та архітектури НГО.`

    return (
        // <SWRConfig 
        // value={{
        //   revalidateOnMount: true,
        //   revalidateOnFocus:false
        // }}>
    <>
        <Dialog open={isInfoOpen}>
            <DialogContent>
                <ReactMarkdown children={i18n.resolvedLanguage === "en"? htlDescription_en : htlDescription_ua } remarkPlugins={[remarkGfm]}/>
            </DialogContent>
            <DialogActions>
            <IconButton
                    onClick={handleInfoClose}
                    color="primary" 
                >
                    <CloseIcon  color="primary"/>
            </IconButton>
            </DialogActions>
        </Dialog>
        <Card >
            <CardContent>
            <Typography component="div" variant="h3" sx={{ marginBottom:1}}>
            {landscape?.title} <IconButton aria-label="info" onClick={handleInfoOpen}>
                <InfoOutlinedIcon color="primary" fontSize="large"/> </IconButton>
            </Typography>
            <Typography component="div" variant="body1" sx={{ marginBottom:5}}>
            <ReactMarkdown children={i18n.resolvedLanguage === "en"? htlDescription_en : htlDescription_ua } remarkPlugins={[remarkGfm]}/>
            </Typography>
            <VersionLabel version={landscape?.version} versionDate={landscape?.versionDate}/>
            <Grid container spacing={2}>
            {landscape && 
             landscape.sections.map(
                 (section: ILandscapeSection, sectionIndex)=>  {
                    return <LandscapeSection 
                        key={section.name} 
                        {...section} sectionIndex={sectionIndex}
                    /> 
                })
            }
            </Grid>
            </CardContent>
        </Card>
        </>
        // </SWRConfig>
    )
}

export default LandscapeContainer;

