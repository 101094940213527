import {  Card, CardActionArea } from "@mui/material";
import { ILandscapeItem } from "../../api/landscape/ILandscapeItem";
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import { blue } from '@mui/material/colors';
import { useState } from "react";
import LandscapeItemDetail from "./LandscapeItemDetail";

interface ILandscapeItemProps extends ILandscapeItem {
    itemIndex: number
}

const LandscapeItem = (props:ILandscapeItemProps) => {
    const [dialogOpen, setDialogOpen] = useState(false);


    const handleClick = () => {
        setDialogOpen(true);
    } 
    const handleDialogClose=() => {
        setDialogOpen(false);
    }

    return (
        <Card elevation={3} sx={{flexGrow:1, display: "flex"
        // , flexWrap: 'wrap', 
        , maxHeight: 130, maxWidth:130, height:130, width:130
        }} >
            {dialogOpen && <LandscapeItemDetail isOpen={dialogOpen} handleClose={handleDialogClose} item={props}/> }
            <CardActionArea onClick={handleClick} sx={{alignItems: "start"}} >
                {/* <Stack> */}
            <div style={{
                fontFamily: "Roboto",
                fontSize: ".9rem"
            }}><b>{props.name}</b></div>
            {props.maker && props.name!==props.maker && <div style={{
                fontFamily: "Roboto",
                fontSize: ".9rem"
            }}>
                {props.maker}
            </div>}
            { props.imgFilename  ?
                <img src={`/logo/${props.imgFilename}`} style={{ width: '50px', height: '50px' }}  alt={`${props.name} logo`}/> :
                <BrokenImageOutlinedIcon sx={{color: blue[900], fontSize:40}}/>
            }
            {/* </Stack> */}
            </CardActionArea>
        </Card>
    
    )
}

export default LandscapeItem;