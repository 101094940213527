import { Box, Button, Stack, TextField } from "@mui/material";
import { useState } from 'react'
import { ITerraformCloudConfig, TerraformConfigValidationStatus } from "../../datamodel/interfaces/terraform.interfaces";

interface ITerraformConfigFormProps  {
    orgId:string,
    data?:ITerraformCloudConfig,
    handleSubmit:(doc:ITerraformCloudConfig)=>void
    handleCancel:()=>void,
    allowCancel:boolean

}

const TerraformConfigForm = (props:ITerraformConfigFormProps) => {
    const {orgId,data, handleSubmit, handleCancel, allowCancel} = props;
    const [orgName, setOrgName] = useState(data?.orgName ?? "");
    const [workspaceName, setWorkspaceName] = useState(data?.workspaceName ?? "");
    const [projectName, setProjectName] = useState(data?.projectName ??"");
    const [key, setKey] = useState("");

    
    
    const handleSubmitConfig = ()=> {
        const doc:ITerraformCloudConfig = {
            _id: data?._id,
            orgId:orgId,
            orgName: orgName,
            projectName: projectName,
            workspaceName: workspaceName,
            validationStatus:TerraformConfigValidationStatus.PENDING,
            validationMessage: "",
            key:key,
            settingValues:[]
        }

        handleSubmit(doc);

    }

    const buttonStyle = {
        marginTop:2, marginRight:2
    }

    return (
        <Box>
            <Stack>
                <TextField
                    variant="standard"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    label="Organization name" />
                <TextField
                    variant="standard"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    label="Project name" />
                <TextField
                    variant="standard"
                    value={workspaceName}
                    onChange={(e) => setWorkspaceName(e.target.value)}
                    label="Workspace name" />
                <TextField
                    variant="standard"
                    multiline
                    value={key}
                    onChange={(e) => setKey(e.target.value)}
                    label="Key" />
                <div>
                    {allowCancel && <Button sx={buttonStyle} variant="outlined" onClick={handleCancel}>Cancel</Button>}
                    <Button sx={buttonStyle} variant="contained" onClick={handleSubmitConfig}>Submit</Button>
                </div>
            </Stack>
        </Box>
    )
}

export default TerraformConfigForm;