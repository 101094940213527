import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { IDataCollectionForm } from "../../datamodel/interfaces/datacollectioninterfaces";
import QuestionNode from "./QuestionNode";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

interface IFormNodeProps {
    form: IDataCollectionForm
    depth: number,
    ontology: any,
    onChange: (f: IDataCollectionForm) => void
    //updateQuestion: (question, questionIndex)=>void
}

const FormNode = (nodeProps: IFormNodeProps) => {
    const { form, depth, ontology } = nodeProps;


    return (
        <>
            <Typography variant="body1" sx={{ marginLeft: depth * 3 }}>
                <ArticleOutlinedIcon />
                {form.name}
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Question</TableCell>
                        <TableCell>Sensitive</TableCell>
                        <TableCell>Protection Type</TableCell>
                        <TableCell>Meaning</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
            {form.questions.map((q, questionIndex) => 
                <QuestionNode
                    ontology={ontology}
                    key={`q${depth}${questionIndex}${Math.random()}`}
                    question={q}
                    depth={depth + 1}
                    onChange={(q) => { /*alert(i); alert(q);*/ 
                        const nextForm = {...form};
                        nextForm.questions[questionIndex] = q;
                        nodeProps.onChange(nextForm);
                     }}
                />
            )}
            </TableBody>
            </Table>
        </>
    )
}

export default FormNode;