import { useCallback, useEffect, useState } from "react";
import { IAzConfigSettings } from "../../../datamodel/interfaces/deployment-settings.interfaces";
import { IOrganization } from "../../../datamodel/interfaces/organization.interfaces";
import DopCollapse from "../../DopCollapse";
import { AzConfigApi } from "../../../api/AzConfigApi";
import { Button, Dialog, DialogContent } from "@mui/material";
import AzureConfigForm from "../../azure/AzureConfigForm";
import AzureConfigSummary from "../../azure/AzureConfigSummary";

interface IAzureConnectionConfigProps {
    org: IOrganization,
    publishConfig?: (c: IAzConfigSettings) => void
}

const AzureConnectionConfig = (props: IAzureConnectionConfigProps) => {
    const { org, publishConfig } = props;
    const [azConfig, setAzConfig] = useState<IAzConfigSettings>();
    const [isConfigFormOpen, setIsConfigFormOpen] = useState<boolean>(false);

    const refreshAzConfig = useCallback(() => {
        AzConfigApi.getAzConfigForOrg(org._id)
            .then(c => {
                setAzConfig(c);
                publishConfig && publishConfig(c); //maybe use side-effect
            }
            );
    }, [publishConfig, org._id])

    const handleAddEditConfigClick = () => {
        setIsConfigFormOpen(true);
    }

    const handleConfigFormCancelClick = () => {
        setIsConfigFormOpen(false);
    }

    const handleConfigFormSubmitClick = async (c: IAzConfigSettings) => {
        if (c._id) {
            await AzConfigApi.updateAzConfig(c, c._id, org._id);

        } else {
            await AzConfigApi.createAzConfig(c, org._id);
        }
        setIsConfigFormOpen(false);
        refreshAzConfig();
    }

    useEffect(() => {
        refreshAzConfig()
    }, [refreshAzConfig])

    return <>
        {
            azConfig
                ?
                <DopCollapse title={<b>Connect to Azure</b>} isCollapsible={false} elevation={0}>
                    <>
                        <AzureConfigSummary azConfig={azConfig} />
                        <Button onClick={handleAddEditConfigClick}>Edit config</Button>
                    </>


                </DopCollapse>
                : <Button onClick={handleAddEditConfigClick}>Connect to Azure</Button>
        }
        <Dialog open={isConfigFormOpen}>
            <DialogContent>
                <AzureConfigForm org={org}
                    handleSubmit={handleConfigFormSubmitClick}
                    handleCancel={handleConfigFormCancelClick}
                    allowCancel={true}
                    data={azConfig} />
            </DialogContent>
        </Dialog>

    </>
}

export default AzureConnectionConfig;