import { Typography } from "@mui/material"

interface IVersionLabelProps {
    version?:string,
    versionDate?:string
}

const VersionLabel = (props:IVersionLabelProps)=> {
    const {version, versionDate} = props;
    return (
        <>
        { 
            (version || versionDate) ?
            <Typography component="div" variant="subtitle1" sx={{ marginBottom:5}}>
                Version: {version} {versionDate}
            </Typography> : <></>
        }
        </>          
    )
}

export default VersionLabel;