import { IStepSummaryProps } from "../IStepSummaryProps";
import { IConfigResourceSettings } from "../../../../../datamodel/interfaces/deployment-settings.interfaces";
import Datasheet from "../../../../Datasheet";
import InfrastructureSummary from "./infrastructure-summary";

interface IResourceGroupSummaryProps extends IStepSummaryProps {
    resourceGroupSettings: IConfigResourceSettings | undefined
}

const ResourceGroupSummary = (props: IResourceGroupSummaryProps) => {
    const { onEdit, resourceGroupSettings, showEdit } = props;
    return (
        resourceGroupSettings
            ? <InfrastructureSummary title={"Resource Group"} onEdit={onEdit} editDisabled={false} showEdit={showEdit}
                isReadyForDeployment={resourceGroupSettings.readyForDeployment}>

                <div style={{ maxWidth: 400 }}>
                    {resourceGroupSettings
                        ? <Datasheet data={resourceGroupSettings?.settings}
                            keys={[
                                { displayName: "Name", keyValue: "name" }
                                , { displayName: "Location", keyValue: "location" }
                            ]} />
                        : <div>Loading... </div>
                    }
                </div>
            </InfrastructureSummary>
            : <div>Loading...</div>
    )
}

export default ResourceGroupSummary;