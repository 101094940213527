import { Box, Tab, Tabs } from "@mui/material";
import {  useCallback, useEffect, useMemo, useState } from "react";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import DataCollectionConnectionConfig from "./ITConfig/DataCollectionConnectionConfig";
import TerraformConnectionConfig from "./ITConfig/TerraformConnectionConfig";
import AzureConnectionConfig from "./ITConfig/AzureConnectionConfig";
import GetPasswordData from "./ITConfig/GetPasswordData";
import { a11yProps, TabPanel } from "../Tab";

interface IITConfigProps {
    org: IOrganization
}



const ITConfig = (props: IITConfigProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { org } = props;
    const [tabIndex, setTabIndex] = useState(0);
    const [path, setPath] = useState(location.pathname);
    
    const tabMetadata = useMemo(()=>[
        {name:"Data Collection", path:"data-collection"},
        {name:"Terraform", path:"tf-config"},
        {name:"Azure", path:"azure-config"},
        {name:"Get Password", path:"get-passwords"},    
    ], []);
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(tabMetadata[newValue].path);
    };

    const processNavigation = useCallback(()=> {
        const fullPath = path;
        const fragmentList = fullPath.split("/");
        const lastPart = fragmentList[fragmentList.length-1];
        let index = tabMetadata.findIndex( t=>t.path === lastPart);
        if( index < 0 ){
            navigate(tabMetadata[0].path);
        }
        else {
            setTabIndex(index);
        }
    }, [navigate, path, tabMetadata]);

    useEffect(()=>{
        setPath(location.pathname);
    }, [location.pathname])


    useEffect(()=>{
        processNavigation()
    }, [processNavigation])

    return <>
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="standard"
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="IT Config Tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Data Collection" {...a11yProps(0)} />
                <Tab label="Terraform" {...a11yProps(1)} />
                <Tab label="Azure" {...a11yProps(2)} />
                <Tab label="Get Passwords" {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
            <DataCollectionConnectionConfig org={org}/>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
            <TerraformConnectionConfig org={org}/>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
            <AzureConnectionConfig org={org} />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
            <GetPasswordData/>
            </TabPanel>
        </Box>    </>
}

export default ITConfig;