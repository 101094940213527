import { Box, Button, List, ListItem, ListItemText, Typography } from "@mui/material";
import DataSharingRequests from "./DataSharingRequest";
import { useState } from "react";
import { IDataCollectionFormContainer } from "../../../datamodel/interfaces/datacollectioninterfaces";

interface IDataSharingRequestsConfigProps {
    requests:any[],
    formData:IDataCollectionFormContainer
}


const DataSharingRequestsConfig = (props: IDataSharingRequestsConfigProps)=> {
    const {requests, formData} = props;
    const [currentRequest, setCurrentRequest] = useState<any>(undefined);
    return <>
    <Box>
        <Typography variant="h4">Request list</Typography>
        <List>
            {requests.map((r, i)=> <ListItem key={`listitem_${i}`}>
                <ListItemText primary={<>{r.name} 
                
                {currentRequest === requests[i] 
                ? 
                <Button onClick={()=>{
                    setCurrentRequest(undefined);
                }}>Close</Button>
                :
                <Button onClick={()=>{
                    setCurrentRequest(requests[i]);
                }}>View</Button>
            
            }   </> }/>
            </ListItem>
            )}
        </List>
    </Box>
    {currentRequest && <>
        <DataSharingRequests request={currentRequest} formData={formData}/>
        <Button onClick={()=>setCurrentRequest(undefined)}>Close</Button>
    </>}
    {/* {requests.map(r=> <DataSharingRequests request={r} formData={formData}/>)} */}
    </>
}

export default DataSharingRequestsConfig;
