import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";

function RoadmapPage() {
    return (
    <Container>
        <Typography variant="h3">
            Roadmap
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
            We don't yet a have a full roadmap.  We know where we are starting and we believe that will help us create a roadmap.
            <br/>
            Inspired by the cloud native landscape (https://landscape.cncf.io/), we will put together a humanitarian technology landscape:
            <ul>
            <li><b>Form builders:</b>  create digital forms to collect data from beneficiaries or program participants.</li>
            <li><b>Mobile data collection apps:</b> collect data using mobile devices in the field. Examples of mobile data collection apps include Open Data Kit (ODK), CommCare, and SurveyCTO.</li>
            <li><b>Data/case management platforms:</b> store and manage data. Examples of data management platforms include Airtable, TolaData, and DHIS2.</li>
            <li><b>Data analysis tools:</b> analyze data to gain insights and make informed decisions. Examples of data analysis tools include Excel, Tableau, and R.</li>
            <li><b>Mapping and visualization tools:</b> create maps and visualizations to communicate data effectively. Examples of mapping and visualization tools include ArcGIS, QGIS, PowerBI and Flourish.</li>
            <li><b>Payment processing tool:</b>  manage the disbursement of cash to beneficiaries. This tool should allow for secure and efficient payment processing, as well as tracking of payment disbursements.
                <ul>
                <li><b>Mobile money platforms:</b> digital payment platforms that allow individuals to send, receive, and store money using their mobile phones.</li>
                <li><b>Prepaid debit cards:</b> physical cards that are pre-loaded with a specific amount of money and can be used to withdraw cash or make purchases.</li>
                <li><b>E-vouchers:</b> digital vouchers that can be used to purchase goods and services from specific vendors.</li>
                <li><b>Biometric payment systems:</b> These systems use biometric identification (e.g., fingerprint or iris scans) to authenticate beneficiaries and disburse cash directly to their accounts.</li>
                <li><b>Blockchain-based platforms:</b>  decentralized digital ledgers that can be used to record and track financial transactions securely and transparently.</li>
                </ul>
            </li>
            <li><b>Analytics and reporting tool:</b> analyze and visualize data collected from forms and case management systems. This tool should allow for exploration of data, as well as the ability to create custom reports and dashboards.</li>
            <li><b>Communication tool:</b> communicate with beneficiaries and other stakeholders, such as local authorities and partner organizations. This tool should allow for two-way communication, as well as the ability to send alerts and notifications.</li>
            <li><b>Monitoring and evaluation tool:</b> A monitoring and evaluation tool is necessary to track progress and evaluate the effectiveness of the program. This tool should allow for the creation of custom indicators, as well as the ability to track progress towards program goals.</li>
            </ul>
            And others.
        
        </Typography>
        
    </Container>
    )
}

export default RoadmapPage;