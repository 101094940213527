import { IOrganization } from '../../datamodel/interfaces/organization.interfaces';
import { Card, CardHeader, Box, Typography, CardContent, CardActions, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


interface IOrgListProps {
    orgList: IOrganization[],
    addOrg: () => void,
    selectOrg: (org: IOrganization) => void
    
    //mode: "full"|"summary"
}


const OrgList = (props: IOrgListProps) => {
    const { orgList, addOrg, selectOrg } = props;


    return (<Card sx={{margin:2}} elevation={5}>
        <CardHeader title={
            <Box>
                <Typography variant='h5'>Programs</Typography>
                <Typography variant='subtitle2'></Typography>
            </Box>
        } />
        <CardContent>

            {
                orgList.map((org) => 
                    <div key={org._id} >
                        {org.orgName}
                        <IconButton onClick={() => selectOrg(org)}>
                            <NavigateNextIcon />
                        </IconButton>
                    </div>
                )
            }
        </CardContent>
        <CardActions>
            <IconButton onClick={addOrg}>
                <AddIcon />
            </IconButton>
        </CardActions>
    </Card>


    )
}

export default OrgList;