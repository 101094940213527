import { ILandscape } from "./ILandscape";
import testJson from './landscape.json'

export class LandscapeContainerApi {
    async get(): Promise<ILandscape> {

        let landscape:ILandscape = testJson

         return new Promise( (resolve, reject) => {
        //     const landscapeSectionApi = new LandscapeSectionApi();
        //     console.log("1", landscape);
        //     Promise.all (
        //         landscape.sections.map(
        //             (section)=> {
        //                 landscapeSectionApi.get(section.name).then ( (returnedSection)=> 
        //                     Object.assign(section, returnedSection)    
        //                 //section.items = returnedSection.items
        //                 );
        //             }
        //         )
        //     );

            console.log("2", landscape);
            resolve(landscape);
        }) ;
    }
}