import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import { Box, Button, Link,  List,  ListItem,  ListItemText,  Tab, Tabs } from '@mui/material';
import { CommcareApi } from '../../api/CommcareApi';
import { ICommcareInstance } from '../../datamodel/interfaces/commcare.interfaces';
import OrgSecrets from './OrgSecrets';
import CommcareHome from '../commcare/CommcareHome';
import DataWarehouse from '../datawarehouse/datawarehouse';
import InfrastructureWizard from '../terraform/deployment/deployment-steps/infrastructure/infrastructure-wizard';
import TerraformConfigList from '../terraform/terraform-list';
import { ITerraformCloudConfig } from '../../datamodel/interfaces/terraform.interfaces';
import { InfrastructureApi } from '../../api/InfrastructureApi';
import TerraformDeployHome from '../terraform/deployment/TerraformDeployHome';
import { CreateCommcareInstanceDto } from '../../datamodel/dtos/CreateCommcareInstanceDto';
// import DataManagement from '../containers/xxx_DataManagement';
// import TechManagement from '../containers/xxx_TechManagement';
import AddCommcareDialog from '../commcare/AddCommcareDialog';
import ITConfig from '../containers/ITConfig';
import DataConfig from '../containers/DataConfig';
import Publish from '../containers/Publish';
import Reporting from '../containers/Reporting';
import DataSharing from '../containers/DataSharing';


interface IOrgDetailProps {
  back: () => void,
  getOrg: (orgId: string) => IOrganization | undefined
}


const OrgDetail = (props: IOrgDetailProps) => {
  // const {orgId, backNav: {label, path}} = {...props, ...useParams() };
  //const { org , back} = props;
  const { getOrg } = props;
  const { orgId } = useParams();

  const [org, setOrg] = useState<IOrganization>();
  const [terraformConfigList, setTerraformConfigList] = useState<ITerraformCloudConfig[]>([]);
  
  const [commcareList, setCommcareList] = useState<ICommcareInstance[]>()
  const [koboList] = useState<any[]>([])
  const [showAddCommcareDialog, setShowAddCommcareDialog] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  const refreshTerraformConfigList = useCallback(() => {
    orgId && InfrastructureApi.getInfrastructureConfigs(orgId).then(
        (config: ITerraformCloudConfig[]) => {
            if (config.length > 1) {
                throw new Error('more than 1 terraform config. One or zero expected.')
            }

            setTerraformConfigList(config)
        }
    );
  }, [orgId])

  const saveConfig = async (config: ITerraformCloudConfig) => {
    await InfrastructureApi.create(config, orgId!);
    refreshTerraformConfigList();
  }

  const updateConfig = async (config:ITerraformCloudConfig) => {
      await InfrastructureApi.update(config._id, config, orgId!);
      refreshTerraformConfigList();
  }

  useEffect(() => refreshTerraformConfigList(), [refreshTerraformConfigList]);

  useEffect( ()=> {

  })





  const getCommcareInstanceById = (id: string) =>  {
    //refreshTerraformConfigList();
    return commcareList ? commcareList.find((c) => c._id === id) : undefined;
  }

  const addCommcareInstanceStart = () => {
    //navigate("commcare/add")
    setShowAddCommcareDialog(true);
  }


  let refreshCommcareInstanceList = useCallback(() => {
    if (org) {
      CommcareApi.getCommcareInstances(org._id).then(
        (result) => {
          setCommcareList(result)
        }
      )
    }
  }, [org]);

  const handleCancelAddCommcareInstance = () => {
    //navigate(`/org/${org?._id}`);
    alert('here - cancel')
    setShowAddCommcareDialog(false);
  }


  const handleSubmitAddCommcareInstance = async (instance: CreateCommcareInstanceDto) => {
    
    await CommcareApi.saveInstance(org!._id, instance);
    refreshCommcareInstanceList();
    setShowAddCommcareDialog(false);
  }

  //    const handleBackToList = ()=>back();

  useEffect(() => {
    setOrg(getOrg(orgId!));
  }, [getOrg, orgId])

  useEffect(() => {
    refreshCommcareInstanceList()
  }

    , [org, refreshCommcareInstanceList])


  const tabMetadata = [
      {path: "it-config" , name:"IT Config"},
      {path: "data-config" , name:"Data Config"},
      {path: "publish", name:"Publish to Cloud"},
      {path: "reporting", name:"Reporting"},
      {path: "data-sharing", name:"Data Sharing"}
  ]
  
  return (
    org ?
    <>
      <AddCommcareDialog org={org} handleCancelAddCommcareInstance={handleCancelAddCommcareInstance}
        handleSubmitAddCommcareInstance={handleSubmitAddCommcareInstance}
        showAddCommcareDialog={showAddCommcareDialog}
      />

      <Box sx={{ marginRight: 4, marginLeft: 4, marginTop: 5 }}>
        <div>
          {tabMetadata.map((md,i)=>{
            const isSelected = location.pathname.split("/").findIndex( (fragment) => fragment===md.path ) >=0;
            return <Button key={`${md.path}_${i}`} 
              variant={isSelected? "contained" : "text"}
            onClick={() => navigate(md.path)}>{md.name}</Button>
           })
          }
        </div>
        
        <Routes>

          <Route path='/' element={
            <>
              <List>
                <ListItem>
                  <ListItemText>
                    <Link component="button" onClick={()=>navigate("it-config")}>IT Config</Link> 
                    - as a member of IT team, provide setting to connect to Kobo, Commcare, Azure and Terraform Cloud.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Link component="button" onClick={()=>navigate("data-config")}>Data Config</Link> 
                    - as member of Data Collection team, define which fields are available for reporting, map to semantic/common model 
                    and establish data protection settings.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Link component="button" onClick={()=>navigate("publish")}>Publish to Cloud</Link> 
                    - once connections and data settings are established, deploy the system so it's available for reporting and analysis.  Data will start flowing soon after deployment.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Link component="button" onClick={()=>navigate("reporting")}>Reporting</Link> 
                    - connect your cloud infrastructure to Power BI
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <Link component="button" onClick={()=>navigate("data-sharing")}>Data Sharing</Link> 
                    - data sharing management, including receiving requests and securely delivering data
                  </ListItemText>
                </ListItem>

              </List>
            </>
          } />

          <Route path='/it-config/*' element={
            <ITConfig org={org}/>
          } />

          <Route path='/data-config/*' element={
            <DataConfig org={org}/>
          } />
          
          <Route path='/publish/*' element={
            <Publish org={org}/>
          } />
          

          <Route path='/reporting/*' element={
            <Reporting org={org}/>
          } />
          
          <Route path='/data-sharing/*' element={
            <DataSharing org={org}/>
          } />
          





          {/* <Route path='/data-management/*' element={
            <DataManagement org={org}/>
          } />
          <Route path='/tech-management/*' element={
            <TechManagement org={org}/> 
          } /> */}
          <Route path="/deploy/*" element={
            org
            ? <TerraformDeployHome org={org}/> 
            : <div>Loading ... </div>
          }/>          
          <Route path='/wizard/*' element={<>
            <div>Wizard</div>
            {org && <InfrastructureWizard org={org}/>}
          </>
          } />
          <Route path='/advanced/*' element={<>
            <div>Advanced</div>
            <div>
              <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 600 }}
              >
                {commcareList && <TabbedView 
                  org={org} orgId={orgId} 
                  commcareList={commcareList} 
                  koboList={koboList}
                  addCommcareInstanceStart={addCommcareInstanceStart} 
                  getCommcareInstanceById={getCommcareInstanceById}
                  getOrg={getOrg} 
                  infrastructureConfigData={
                    orgId && <TerraformConfigList orgId={orgId} saveConfig={saveConfig} updateConfig={updateConfig}
                      terraformConfig={terraformConfigList && terraformConfigList.length >0 ? terraformConfigList[0] : undefined}
                    />

                  }
                  
                  />}
              </Box>
            </div>
          </>
          } />

        </Routes>


      </Box>

    </>
    :<div>Loading...</div>
  )
}

interface ITabbedViewProps {
  org: any,
  orgId: any,
  commcareList: any[],
  koboList: any[],
  addCommcareInstanceStart: any,
  getOrg: any,
  getCommcareInstanceById: any,
  infrastructureConfigData:ReactNode
}

const TabbedView = (props: ITabbedViewProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const {  org, orgId, getOrg, getCommcareInstanceById } = props;
  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };


  interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
  }

  


  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }


  return (<>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={tabIndex}
      onChange={handleTabChange}
      aria-label="Vertical tabs example"
      sx={{ borderRight: 1, borderColor: 'divider' }}

    >
      <Tab label="Infrastructure Setup" {...a11yProps(0)} />
      {/* <Tab label="Data Collection" {...a11yProps(0)} /> */}
      <Tab label="Data Collection" {...a11yProps(1)} />
      <Tab label="Data Warehouse" {...a11yProps(2)} />
      <Tab label="Data Structure" {...a11yProps(3)} />
      <Tab label="Reporting" {...a11yProps(4)} />
      <Tab label="API Keys" {...a11yProps(5)} />
    </Tabs>
    <TabPanel value={tabIndex} index={0}>
      {/* <InfrastructureSetup/> */}
      {orgId && props.infrastructureConfigData}
    </TabPanel>
    <TabPanel value={tabIndex} index={1} >
      <Routes>
        <Route path="/" element={<>
          {/* {org && commcareList && 
            <CommcareList org={org}  addCommcareInstance={addCommcareInstanceStart} currentCommcareInstance={undefined} />}
          */}
          
          {/* {org && koboList &&
            <KoboList org={org} koboList={koboList} addKoboInstance={() => { }} />
          } 
           */}
          </>} />

        <Route path="/commcare/:commcareInstanceId/*" element={<>
          CommcareHome
          {org && <CommcareHome orgId={org._id} getOrg={getOrg} getCommcareInstance={getCommcareInstanceById} />}
          </>} />
      </Routes>
    </TabPanel>
    <TabPanel value={tabIndex} index={2}>
      <DataWarehouse />
    </TabPanel>
    <TabPanel value={tabIndex} index={3}>
      Data Structure
    </TabPanel>
    <TabPanel value={tabIndex} index={4}>
      Reporting
    </TabPanel>
    <TabPanel value={tabIndex} index={5}>
      {org && <OrgSecrets org={org} />}
    </TabPanel>
  </>
  )
}


export default OrgDetail;