//import { useEffect } from 'react';
import logoen from './logo-en.png'
//import logoua from './logo-ua.png'
//import { useTranslation } from 'react-i18next'

export default function Logo() {
    //const {i18n} = useTranslation();
    // const selectLogo = (lang:string)=> lang==='en'? logoen : logoua;
    
    // useEffect( () => {    
    // },
    //     [i18n.resolvedLanguage]
    // )

    return ( 
    <div>
        {/* <img src={selectLogo(i18n.resolvedLanguage)} alt="logo"/> */}
        <img src={logoen} alt="logo"/>
        </div>
    )
}