import { useEffect, useState, useCallback } from 'react'
import TerraformApi from '../../../api/TerraformApi'
import { IOrganization } from '../../../datamodel/interfaces/organization.interfaces'
import { Button, Card, CardContent, CardHeader, Typography, Collapse } from '@mui/material'
import Datasheet from '../../Datasheet'
import CountdownTimer from '../../../Timer'

interface ITerraformDeployHomeProps {
    org: IOrganization
}

const TerraformDeployHome = (props: ITerraformDeployHomeProps) => {
    const { org } = props;
    //const [runsList, setRunsList] = useState<any>();
    const [inProgressList, setInProgressList] = useState<any[]>([]);
    const [finishedList, setFinishedList] = useState<any[]>([]);
    const [finishedCardExpended, setFinishedCardExpended] = useState(false);
    const [lastRefresh, setLastRefresh] = useState<Date|undefined>(undefined)


    const triggerNewRun = async () => {
        await TerraformApi.createRun(org._id);
        refreshRunsList();
    }

    const triggerDestroyAll = async () => {
        await TerraformApi.createRunDestroyAll(org._id);
        refreshRunsList();
    }



    const refreshRunsList = useCallback(() => {
        const finishedTerraformStatuses = [
            "planned_and_finished"
            , "applied"
            , "discarded"
            , "errored"
            , "canceled"
            , "force_canceled"
        ];
        TerraformApi.getRunsList(org._id).then(
            (l) => {
                //setRunsList(l); 
                const data: any[] = l.data;
                setFinishedList(
                    data
                    .filter(r => finishedTerraformStatuses.indexOf(r.attributes["status"]) !== -1)
                    .sort( (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))
                
                );
                setInProgressList(data.filter(r => finishedTerraformStatuses.indexOf(r.attributes["status"]) === -1));
                const x = new Date();
                console.log(x.toString())
                setLastRefresh(x);
            }
        )

            ;
    }, [org._id])

    useEffect(refreshRunsList, [refreshRunsList])

    const buttonStyle = {
        marginRight: 2
    }

    return (<>
        <Button sx={buttonStyle} onClick={() => refreshRunsList()}>Refresh</Button> 
        <CountdownTimer minutes={0} seconds={10} done={()=> {refreshRunsList()}} showTimer={false}/>
        
        <Card elevation={0} sx={{ marginBottom: 3 }}>
            <CardContent>
                {inProgressList.length > 0 && <Typography variant='h4'>In Progress</Typography>}
                {inProgressList.length === 0 && <>
                    <Typography variant="body1" sx={{marginBottom:2}}>Trigger Terraform Cloud deployment to create or update the infrastructure.</Typography>
                    <Button onClick={triggerNewRun}>Trigger Deployment</Button>
                    <Button onClick={triggerDestroyAll}>Trigger Destroy All!</Button>
                </>}
                {inProgressList.map(
                    (run: any, i: number) => <TerraformRun key={`run-inprogress-${i}`} run={run} org={org} refreshRunsList={refreshRunsList} lastRefresh={lastRefresh} />
                )}
            </CardContent>
        </Card>
        {
            finishedList && finishedList.length > 0 && <Card>
                <CardHeader title={<>
                    <Typography variant='h4'>Completed</Typography>
                    <Button onClick={() => setFinishedCardExpended(!finishedCardExpended)} >{finishedCardExpended ? "Collapse" : "Expand"}</Button>
                </>} />
                <Collapse in={finishedCardExpended}>
                    <CardContent>
                        {finishedList.map((run: any, i: number) => <TerraformRun key={`run-finished-${i}`} run={run} org={org} refreshRunsList={refreshRunsList}  />)}
                    </CardContent>
                </Collapse>
            </Card>
        }

        {/* {runsList && runsList.data.map(
            (run:any)=><TerraformRun run={run} org={org} refreshRunsList={refreshRunsList}/>
        )} */}

        {/* </DopCollapse> */}
    </>
    )
}

const TerraformRun = (props: { run: any, org: IOrganization, refreshRunsList: () => void, lastRefresh?:Date|undefined }) => {
    const { run,  org } = props;
    
    const refreshRunsList = ()=> {
        props.refreshRunsList();
    
    }

    const applyPlanned = async (runId: string) => {
        await TerraformApi.applyPlannedRun(org._id, runId);
        refreshRunsList();
        
    }
    const discardPlanned = async (runId: string) => {
        await TerraformApi.discardPlannedRun(org._id, runId);
        refreshRunsList();
    }

    let datasheetData: any = {
        id: run.id,
        createdAt: run.attributes["created-at"],
        status: run.attributes["status"],
    };

    props.lastRefresh && (datasheetData.lastRefresh = props.lastRefresh.toString());

    let datasheetKeys = [
        { displayName: "ID", keyValue: "id" },
        { displayName: "Created", keyValue: "createdAt" },
        { displayName: "Status", keyValue: "status" }
    ];

    props.lastRefresh && datasheetKeys.push({ displayName: "Last Refresh", keyValue: "lastRefresh"});

    return <>
        <Typography variant="h6">{run.id}</Typography>
        <Datasheet data={datasheetData} keys={datasheetKeys} />

        {run.attributes["status"] === "planned" && <>
            <Button onClick={() => applyPlanned(run.id)}>Run</Button>
            <Button onClick={() => discardPlanned(run.id)}>Discard</Button>
        </>

        }
    </>
}

export default TerraformDeployHome;