import { IKoboInstance } from "../datamodel/interfaces/IKoboInstance.interface";
import { IDataCollectionFormContainer } from "../datamodel/interfaces/datacollectioninterfaces";
import { ApiHelper } from "./ApiHelper";

export class KoboApi {
    
    public static async getKoboInstances(orgId: string) {
        let res = await ApiHelper.authenticatedGet(`org/${orgId}/kobo`);
        const output = res.data as IKoboInstance[];
        return output;
    }

    public static async getKoboInstance(koboId:string, orgId:string) {
        let res = await ApiHelper.authenticatedDelete(`org/${orgId}/kobo/${koboId}`);
        const output = res.data as IKoboInstance;
        return output;
    } 

    public static async saveInstance( orgId:string, instance:any) {
        let res = await ApiHelper.authenticatedPost(`org/${orgId}/kobo`, instance);

        return res.data;
    }

    public static async updateMetadata(metadata:IDataCollectionFormContainer, koboId:string, orgId:string) {
        let res = await ApiHelper.authenticatedPatch(`org/${orgId}/kobo/${koboId}/metadata`, metadata);
        return res.data;
    }

    public static async loadMetadata(koboId: string, orgId: string) {
        let res = await ApiHelper.authenticatedPost(`org/${orgId}/kobo/${koboId}/metadata`, {});
        return res.data;
    }

}