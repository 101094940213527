import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { useState } from 'react';
import { UserApi } from "../../api/user/UserApi";
import { useNavigate } from 'react-router-dom';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import MicrosoftSignIn from "./MicrosoftSingIn";
import googleLoginButtonImage from "../../static/btn_google_signin_dark_normal_web.png"

const Login = () => {
    // eslint-disable-next-line
    const [username, setUsername] = useState("");
    // eslint-disable-next-line
    const [password, setPassword] = useState("");
    // eslint-disable-next-line
    const [status, setStatus] = useState("")
    const navigate = useNavigate();


    const goToHomePage = ()=>{ 
        navigate("/org");
    }

    const googleCodeResponseSuccess = async (codeResponse: CodeResponse) => {
        setStatus(codeResponse.code);
        // eslint-disable-next-line
        const result = await UserApi.loginGoogleFromCode(codeResponse);
        // console.log("result", result)
        goToHomePage();
    }

    const googleLogin = useGoogleLogin({
        onSuccess: googleCodeResponseSuccess,
        flow: 'auth-code',
        ux_mode: 'popup'
    });

    // const handleSubmit = async () => {
    //     try {
    //         setStatus("")
    //         let isSuccess = await UserApi.login(username, password);
    //         setStatus(isSuccess? "Success":"Fail" );
    //         goToHomePage();
    //     }
    //     catch (e: any) {
    //         setStatus(e.message);
    //     }
    // }
    
    return (<>
        <Grid container sx={{marging:3, padding:5}}  >
            <Grid item md={3} xs={12}>
        {/* <Card>
            <CardHeader title="Test login"/>
            <CardContent>
                <Box sx={{display: 'flex', flexDirection: "column"}}>
                    <TextField id="standard-basic" label="Standard" variant="standard"
                        value={username}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setUsername(event.target.value);
                        }}
                    />

                    <TextField
                        id="filled-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="filled"
                        value={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value);
                        }}
                    />

                    <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                </Box>
            </CardContent>

        </Card> */}
        </Grid>
        <Grid item md={6} xs={12}>
            <Card elevation={10}>
                
                <CardHeader title = "Sign In" />
                <CardContent sx={{display: 'flex', flexDirection: 'column'}}> 
                    
                    {/* <Button variant="contained" onClick={googleLogin}>Google Login</Button> */}
                    
                    <Button variant="text" onClick={googleLogin}> 
                        <img src={googleLoginButtonImage} alt="Logo" />
                    </Button>
                    <MicrosoftSignIn goToHomePage={goToHomePage}/>
                </CardContent>
            </Card>
       

        </Grid>
        <Grid item md={3} xs={12}>
        </Grid>
        </Grid>

    </>

    )
}

export default Login;