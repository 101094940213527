import { Card, CardContent, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import VersionLabel from "../content/VersionLabel";
import ReferenceArchitectureSection from "./ReferenceArchitectureSection";
import { IReferenceArchitectureConfig } from "./reference-architecture-module";
import { Link } from "react-router-dom";

interface IReferenceArchitectureProps {
    config:IReferenceArchitectureConfig
}

const ReferenceArchitecture = (props:IReferenceArchitectureProps) => {
    const {t, i18n} = useTranslation();
    const {config} = props;

    return (

    <Card >
    <CardContent>
    <Typography component="div" variant="h3" sx={{ marginBottom:1}}  id="toc">
        {config.title} 
        {/* <InfoOutlinedIcon color="primary" fontSize="large"/> </IconButton> */}
    </Typography>

    <VersionLabel version={config.version} versionDate={config.versionDate}/>
    {/* <Typography component="div" variant="body1" sx={{ marginBottom:5}}>
    <ReactMarkdown children={i18n.resolvedLanguage === "en"? htlDescription_en : htlDescription_ua } remarkPlugins={[remarkGfm]}/>
    </Typography> */}
    <Box sx={{border:1, padding:2, marginBottom:2}} >
        <Typography variant="h5">Table of Contents</Typography>
    {config.sections.map( ({id, name}) => <div><a href={`#${id}`}>{t(name)}</a></div> )}
    </Box>
    <Box>
        <Box sx={{marginBottom:2}}>
            <div>
                {config.supportedBy && config.supportedBy.length > 0 ? <>
                    <Typography variant="h6" component="span">Supported By: </Typography> 
                        {config.supportedBy.map(({id, title})=><Link to={`../${id}`} style={{marginRight:2}}>{title}</Link>)}
                    </> : "" 
                }
            </div>
            <div>
                {config.supports && config.supports.length > 0 ? <>
                    <Typography variant="h6" component="span">Supports: </Typography> 
                        {config.supports.map(({id, title})=><Link to={`../${id}`} style={{marginRight:2}}>{title}</Link>)}
                    </> : "" 
                }
            </div>
        </Box>
        {config.sections.map( ({id, name, desc, content}) => <ReferenceArchitectureSection id={id} name={t(name)} tooltip={t(desc)} > 
            {content}
            <Typography component="div" variant="caption">
                {i18n.resolvedLanguage==="ua"? "Ще не перекладено":""}
            </Typography>
        </ReferenceArchitectureSection>) }
    </Box>
    </CardContent>
    </Card>
    )
}

export default ReferenceArchitecture;