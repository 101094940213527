import {Box, Typography} from '@mui/material'


const DataWarehouse = ()=> {
    return (
    <Box>
        <Typography variant='h6'>Data Warehouse</Typography>

        <Typography variant='body1'>
            This tab captures configures data storage locations.  
            <br/><br/>
            Commcare Instances require a Postgres database as a warehouse.   We can walk though the creation of this warehouse using terraform as managed infrastructure.
        </Typography>


    </Box>)
}

export default DataWarehouse;