import { IStepSummaryProps } from "../IStepSummaryProps";
import { IConfigResourceSettings } from "../../../../../datamodel/interfaces/deployment-settings.interfaces";
import Datasheet from "../../../../Datasheet";
import InfrastructureSummary from "./infrastructure-summary";

interface IStorageAccountSummaryProps extends IStepSummaryProps {
    storageAccountSettings: IConfigResourceSettings | undefined,
    resourceGroupDependency: IConfigResourceSettings | undefined,
    resourceGroupValidator: (rg: IConfigResourceSettings | undefined) => boolean
}

const StorageAccountSummary = (props: IStorageAccountSummaryProps) => {
    const { onEdit, storageAccountSettings, resourceGroupValidator, resourceGroupDependency, showEdit } = props;
    return (
        storageAccountSettings
        ? <InfrastructureSummary 
            title={"Storage Account"} 
            onEdit={onEdit} 
            editDisabled={!resourceGroupValidator(resourceGroupDependency)} 
            showEdit={showEdit}
            isReadyForDeployment={storageAccountSettings.readyForDeployment}
        >
            {resourceGroupDependency && (!resourceGroupValidator(resourceGroupDependency)) ? <div>Resource group must be defined before key vault</div> : <></>}
            <div style={{ maxWidth: 400 }}>
                {storageAccountSettings?.settings &&
                    <Datasheet 
                        data={
                            {
                                name:storageAccountSettings.settings.name,
                                resourceGroupName: resourceGroupDependency?.settings.name
                            }
                        } keys={[{ displayName: "Name", keyValue: "name" }, { displayName: "Resource Group Name", keyValue: "resourceGroupName" }]} />
                }
            </div>
        </InfrastructureSummary>
        :<div>Loading...</div>
    )
}

export default StorageAccountSummary;