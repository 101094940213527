import { useCallback, useEffect, useState } from "react"
import { AzConfigApi } from "../api/AzConfigApi"
import { IOrganization } from "../datamodel/interfaces/organization.interfaces"
import { IAzConfigSettings } from "../datamodel/interfaces/deployment-settings.interfaces"
import Datasheet from "./Datasheet"
import DownloadAsFile from "./DownloadAsFile"

interface IReportConnectProps {
    org:IOrganization
    server?:string,
    database?:string
    username?:string
    passwordKeyVaultName?:string
}

const ReportConnect = (props: IReportConnectProps)=> {
    const {org} = props;
    const [azConfig, setAzConfig] = useState<IAzConfigSettings>();
    const [serverFqdn, setServerFqdn] = useState("")
    const [shortServerName, setShortServerName] = useState("")
    const [database, setDatabase] = useState("")
    // eslint-disable-next-line
    const [username, setUsername] = useState("reportinguser")
    // eslint-disable-next-line
    const [passwordAkvKey, setPasswordAkvKey] = useState("db-export-reporting-user-password")
    const [keyVaultName, setKeyVaultName] = useState("")

    const refreshAzConfig = useCallback(()=>{
        AzConfigApi.getAzConfigForOrg(org._id)
            .then (c=>{
                setAzConfig(c);
            }
        );
    }, [org._id])

    useEffect( ()=>{
        refreshAzConfig()
    }, [refreshAzConfig])

    useEffect( ()=> {
        if(!azConfig || !azConfig.settingsValues) return; 
        
        const serverConfig = azConfig?.settingsValues.find( (s)=>s.configId==='export_database' );
        setShortServerName(serverConfig?.settings.data_server_name)
        setServerFqdn(`${serverConfig?.settings.data_server_name}.postgres.database.azure.com`);
        setDatabase(serverConfig?.settings.database_name);
        const keyVaultConfig = azConfig?.settingsValues.find( (s)=>s.configId==='key_vault' );
        setKeyVaultName(keyVaultConfig?.settings.name)
    },
        [azConfig]
    )


    return <>
        <Datasheet 
            data={{server: serverFqdn, database:database, username:username, passwordAkvKey:passwordAkvKey, keyVaultName:keyVaultName}} 
            keys={[
                {keyValue:"server", displayName:"Server"},
                {keyValue:"database", displayName:"Database Name"},
                {keyValue:"username", displayName:"Username"},
                {keyValue:"passwordAkvKey", displayName:"Azure Key Vault secret name"},
                {keyValue:"keyVaultName", displayName:"Azure Key Vault name"},
            ]}></Datasheet>
            <DownloadAsFile content={
{
    "version": "1.0",
    "connections": [
        {
            "details": {
                "protocol": "postgresql",
                "address": {
                    "server": serverFqdn,
                    "database": database
                }
            },
            "options": null
        }
    ]
}
            } filename={`${shortServerName}.pbids`}/>
         </>
}

export default ReportConnect;