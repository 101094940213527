import { Dialog, DialogContent } from "@mui/material";
import { CreateCommcareInstanceDto } from "../../datamodel/dtos/CreateCommcareInstanceDto";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import CommcareForm from "./CommcareForm";

interface IAddCommcareDialogProps {
    showAddCommcareDialog: boolean,
    org: IOrganization,
    handleCancelAddCommcareInstance: () => void,
    handleSubmitAddCommcareInstance: (instance: CreateCommcareInstanceDto) => void

}
const AddCommcareDialog = (props: IAddCommcareDialogProps) => {
    const { 
        showAddCommcareDialog, 
        handleCancelAddCommcareInstance, 
        handleSubmitAddCommcareInstance, 
        org } = props;


    return (

        <Dialog open={showAddCommcareDialog}>
            {/* <DialogTitle>Add CommCare</DialogTitle> */}
            <DialogContent>
                {org && <CommcareForm orgId={org._id} cancel={handleCancelAddCommcareInstance} submit={handleSubmitAddCommcareInstance} />}
            </DialogContent>
        </Dialog>
    )
}

export default AddCommcareDialog;