import ResourceGroupSummary from "./resource-group-summary";
import { Route, Routes, useNavigate } from "react-router-dom";
import ResourceGroupStep from "./resource-group-step";
import { useState, useEffect } from "react";
import KeyVaultStep from "./keyvault-step";
import KeyVaultSummary from "./keyvault-summary";
import StorageAccountSummary from "./storage-account-summary";
import { AzResourceType, IConfigResourceSettings } from "../../../../../datamodel/interfaces/deployment-settings.interfaces";
import { AzConfigApi } from "../../../../../api/terraform/AzConfigApi";
import { IOrganization } from "../../../../../datamodel/interfaces/organization.interfaces";
import StorageAccountStep from "./storage-account-step";
import MainSummary from "./main-summary";
import MainStep from "./main-step";

interface IInfrastructureWizardProps {
    org:IOrganization
}

const InfrastructureWizard = ( props:IInfrastructureWizardProps) => {
    const navigate = useNavigate();
    const [mainSetting, setMainSetting] = useState<IConfigResourceSettings>();
    const [resourceGroupSetting, setResourceGroupSetting] = useState<IConfigResourceSettings>();
    const [keyVaultSetting, setKeyVaultSetting]  = useState<IConfigResourceSettings>();
    const [storageAccountSetting, setStorageAccountSetting]  = useState<IConfigResourceSettings>();
    const {org} = props;

    const isResourceGroupDefined = (rg:IConfigResourceSettings|undefined)=> {
        return rg &&
        rg.settings.name && rg.settings.name.length > 0 &&
        rg.settings.location && rg.settings.location.length > 0
        
    }

    useEffect(()=>{
        AzConfigApi.getConfig(org._id, AzResourceType.MAIN).then(c=>setMainSetting(c) );
        AzConfigApi.getConfig(org._id, AzResourceType.RESOURCE_GROUP).then(c=>setResourceGroupSetting(c) );
        AzConfigApi.getConfig(org._id, AzResourceType.KEY_VAULT).then(c=>setKeyVaultSetting(c) );
        AzConfigApi.getConfig(org._id, AzResourceType.STORAGE_ACCOUNT).then(c=>setStorageAccountSetting(c) );
    }, [org._id])

    

    const editMain = ()=> {
        navigate("main")
    }

    const editResourceGroup = ()=> {
        navigate("resource-group")
    }

    const editKeyVault = ()=> {
        navigate("key-vault")
    }

    const editStorageAccount = ()=> {
        navigate("storage-account")
    }

    const saveSettingOnStepFinish = async (config:IConfigResourceSettings, setter:(c:IConfigResourceSettings)=>void)=>{
        await AzConfigApi.saveSetting(org._id, config);
        setter(config);
        navigate(".")}
    
    return (<>
        <Routes>
            <Route path='/' element={
        <>
            <MainSummary onEdit={editMain} mainSettings={mainSetting} showEdit={true} />
            <ResourceGroupSummary onEdit={editResourceGroup} 
                resourceGroupSettings={resourceGroupSetting} 
                showEdit={true}/>
            <KeyVaultSummary 
                onEdit={editKeyVault} 
                keyVaultSettings={ keyVaultSetting}  
                resourceGroupDependency={resourceGroupSetting}
                resourceGroupValidator={isResourceGroupDefined}
                showEdit={true}
            />
            <StorageAccountSummary onEdit={editStorageAccount}
                storageAccountSettings={storageAccountSetting}
                resourceGroupDependency={resourceGroupSetting}
                resourceGroupValidator={isResourceGroupDefined}
                showEdit={true}
            />
        </>
            }/>
            <Route path='/main' element={
                mainSetting && 
                <MainStep 
                    config={mainSetting}
                    next={c => saveSettingOnStepFinish(c, setMainSetting)}
                    back={() => { navigate("."); } } /> 
            }/>
            
            
            <Route path='/resource-group' element={
                resourceGroupSetting && 
                <ResourceGroupStep 
                config={resourceGroupSetting} 
                next={c=>saveSettingOnStepFinish(c, setResourceGroupSetting)} 
                back={()=>{navigate(".")}}/> 
            }/>
            <Route path='/key-vault' element={
                keyVaultSetting
                ? <KeyVaultStep 
                    config={keyVaultSetting}
                    resourceGroupDependency={resourceGroupSetting}
                    back={()=>{navigate(".")}} 
                    next={c=>saveSettingOnStepFinish(c, setKeyVaultSetting)}
                />
                :<div>Loading</div>
            }/>
            
            <Route path='/storage-account' element={
                storageAccountSetting 
                ? <StorageAccountStep 
                        config={storageAccountSetting}
                        resourceGroupDependency={resourceGroupSetting}
                        back={()=>{navigate(".")}} 
                        next={c=>saveSettingOnStepFinish(c, setStorageAccountSetting)}
                />
                :<div>Loading ...</div>
            }/>
        </Routes>
        </>
    )
}

export default InfrastructureWizard;
