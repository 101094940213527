import { Box, Button, IconButton, SvgIcon } from '@mui/material'
import { ReactComponent as PowerBILogo } from './powerbi.svg'
import { useNavigate } from 'react-router-dom'

interface IDownloadAsFileProps {
    content: object
    filename: string
}

const DownloadAsFile = (props: IDownloadAsFileProps) => {
    const navigate = useNavigate();
    const handleClick = () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(props.content)],
            { type: 'text/plain;charset=utf-8' });
        element.href = URL.createObjectURL(file);
        element.download = props.filename;
        document.body.appendChild(element);
        element.click();
    }
    return (
        <Box sx={{marginTop:3}}> 
            <IconButton onClick={handleClick}>
                <SvgIcon component={PowerBILogo} inheritViewBox />
            </IconButton> 
            <Button onClick={handleClick}>
                Open in PowerBI
            </Button> <br/><br/>
            <Button onClick={()=>navigate("../it-config/get-passwords")}>
                Get db password
            </Button>
        </Box>
    );
}

export default DownloadAsFile;
