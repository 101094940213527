import {useState, useEffect } from 'react'
import OrgForm from './OrgForm';
import OrgList from './OrgList';
import { OrgApi } from '../../api/OrgApi';
import { IOrganization } from '../../datamodel/interfaces/organization.interfaces';
import { Box } from '@mui/material';
import { CreateOrgDto } from '../../datamodel/dtos/CreateOrgDto';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import OrgDetail from './OrgDetail';

const OrgHome  = () => {
    const {orgId} = useParams()

    
    const [orgList, setOrgList] = useState<IOrganization[]>([]);
    
    const navigate = useNavigate()
    
    //startup
    useEffect( ()=> {
        refreshOrgList();
    }, [orgId]);

    
    const refreshOrgList = async () => {
        let result = await OrgApi.getOrgList();
        setOrgList(result);
        // if(orgId) {
        //     setSelectedOrg(result.find((o)=>o._id == orgId))
        // }
    }

    const startOrgAdd = ()=> {
        navigate("add")
    };

    const addOrg = async (org:CreateOrgDto)=> {
        // eslint-disable-next-line
        const result = await OrgApi.saveOrg(org);
        //alert(JSON.stringify(result));
        refreshOrgList();
        navigate(".");
    }

    const cancelAddOrg = () => {
    }

    const selectOrg = (org:IOrganization)=> {
        navigate(org._id)
    }

    const handleOrgDetailClose = () => {
        navigate("..")
    }

    const getSelectedOrgById = (orgId:string) => {
        return orgList.find((o)=> o._id === orgId)
    }

    return (
        <Box sx={{margin:2}}>
                <Routes>
                    <Route path=""  element={<OrgList orgList={orgList} addOrg={startOrgAdd} selectOrg={selectOrg}/>}/>
                    <Route path="/:orgId/*" element={<OrgDetail getOrg={getSelectedOrgById} back={handleOrgDetailClose}/>}/>
                    <Route path="add" element={<OrgForm addOrg={addOrg} cancel={cancelAddOrg}/>}/>
                </Routes>
        </Box>
    
    )
}

export default OrgHome;