import { CreateOrgDto } from "../datamodel/dtos/CreateOrgDto";
import { IOrganization } from "../datamodel/interfaces/organization.interfaces";
import { ApiHelper } from "./ApiHelper";

export class OrgApi {
    
    static async saveOrg(org:CreateOrgDto) {
        try {
        const res = await ApiHelper.authenticatedPost("organization", org);
        
        const data = res.data;
        return data;
        }
        catch(e) {
            throw e;
        }
    }

    //get org list for this user
    static async getOrgList() {
        let res = await ApiHelper.authenticatedGet("organization");
        //let user = res.data;
 
        return res.data as IOrganization[];
    
    }

}