import { Typography } from "@mui/material";
import { IDataCollectionFormContainer } from "../../datamodel/interfaces/datacollectioninterfaces";
import FormNode from "./FormNode";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

interface IApplicationNodeProps {
    container: IDataCollectionFormContainer,
    depth: number,
    ontology: any,
    onChange: (a: IDataCollectionFormContainer) => void
}

    
const ApplicationNode = (nodeProps: IApplicationNodeProps) => {
    const { container, depth, ontology } = nodeProps;
    return (
        <>
            <Typography variant="body1" sx={{ marginLeft: depth * 3 }}>
                <FolderOutlinedIcon />
                {container.name} </Typography>
            {container.subContainers.map((c, i) =>
                <ApplicationNode 
                    ontology={ontology}
                    key={`f${depth}${i}${Math.random()}`}
                    container={c} 
                    depth={depth + 1}
                    onChange={(a) => {
                        const nextContainer = {...container};
                        nextContainer.subContainers[i] = a;
                        nodeProps.onChange(nextContainer);
                    }}

                />)}
            {container.forms.map((c, i) => 
            <FormNode
                ontology={ontology}
                key={`f${depth}${i}${Math.random()}`}
                form={c}
                depth={depth + 1}
                onChange={(f:any) => {
                    const nextContainer = {...container};
                    nextContainer.forms[i] = f;
                    nodeProps.onChange(nextContainer);
                }}
            />)}



        </>
    )
}


export default ApplicationNode;
