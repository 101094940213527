import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { loginRequest } from "./microsoftAuthConfig";
import { UserApi } from "../../api/user/UserApi";
import microsoftLoginLogo from "../../static/ms-symbollockup_signin_light.png"


interface IMicrosoftSignInProps {
    goToHomePage:()=>void
}

const MicrosoftSignIn = (props:IMicrosoftSignInProps) => {
    const { instance } = useMsal();
    
    const microsoftLogin = async () => {
        try {
            let result = await instance.loginPopup(loginRequest);
            // let profile = 
            await UserApi.loginMicrosoft(result);
            //navigate("/profile");
            props.goToHomePage();
        }
        catch (e) {
            console.log(e);
        };

    }


    return (
         //<Button variant="contained" onClick={microsoftLogin}>Microsoft Login</Button>
         <Button variant="text" onClick={microsoftLogin}>
            <img alt="logo" src={microsoftLoginLogo}/>
         </Button>
    )
}

export default MicrosoftSignIn;