import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IDataCollectionFormContainer } from "../../../datamodel/interfaces/datacollectioninterfaces";

interface IApplicationSummaryProps {
rootContainer: IDataCollectionFormContainer,
}

const ApplicationSummary = (props:IApplicationSummaryProps)=> {

    const processContainer = (rows:any[], parentPath:string, container:IDataCollectionFormContainer) => {
        const containerPath = `${parentPath}/${container.name}`;
        container.subContainers.forEach( c=>processContainer(rows, containerPath, c) );
        rows.push(...container.forms.map( f=> processForm(containerPath, f.name, f.questions.length, f.questions.filter( q=> q.properties?.isProtectedInfo === true).length)));

        return rows;
    }

    const processForm = (containerPath:string, formName:string, questionCount:number, protectedQuestionCount:number) => {
        return ({
            containerPath:containerPath,
            formName:formName,
            questionCount:questionCount,
            protectedQuestionCount:protectedQuestionCount
        });
    }

    
    
    
    return (<>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Application</TableCell>
                    <TableCell>Form</TableCell>
                    <TableCell>Questions</TableCell>
                    <TableCell>Protected</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {processContainer([], "", props.rootContainer).map( (r:any, i:number)=> { 
                    return  <TableRow key={i}>
                        <TableCell>{r.containerPath}</TableCell>
                        <TableCell>{r.formName}</TableCell>
                        <TableCell>{r.questionCount}</TableCell>
                        <TableCell>{r.protectedQuestionCount}</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>)
}

export default ApplicationSummary;