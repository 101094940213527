import Datasheet from "../Datasheet";
import { IAzConfigSettings } from "../../datamodel/interfaces/deployment-settings.interfaces";

interface IAzureConfigSummaryProps {
    azConfig: IAzConfigSettings
}

const AzureConfigSummary = (props: IAzureConfigSummaryProps) => {

    const settingCount = props.azConfig.settingsValues?props.azConfig.settingsValues.length:0;
    return (
        <>
            <Datasheet data={{...props.azConfig, settingCount: settingCount}} keys={[
                { keyValue: "_id", displayName: "ID" },
                { keyValue: "subscriptionId", displayName: "Subscription ID" },
                { keyValue: "location", displayName: "Location" },
                { keyValue: "settingCount", displayName: "Settings"}
            ]} />
            {/* <Button variant="contained" onClick={()=>setConfigViewMode(ConfigViewMode.EDIT)}>Edit</Button> */}
        </>
    )
}

export default AzureConfigSummary;
