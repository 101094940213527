import { Box, Button, Stack, TextField } from "@mui/material";
import { useState } from 'react'
import { IAzConfigSettings } from "../../datamodel/interfaces/deployment-settings.interfaces";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";

interface IAzureConfigFormProps  {
    org:IOrganization,
    data?:IAzConfigSettings,
    handleSubmit:(doc:IAzConfigSettings)=>void
    handleCancel:()=>void,
    allowCancel:boolean

}

const AzureConfigForm = (props:IAzureConfigFormProps) => {
    const {org,data, handleSubmit, handleCancel, allowCancel} = props;
    const [location, setLocation] = useState(data?.location ?? "");
    const [subscriptionId, setSubscriptionId] = useState(data?.subscriptionId ??"");
    
    
    
    const handleSubmitConfig = ()=> {
        const doc:IAzConfigSettings = {
            _id: data?._id,
            orgId:org._id,
            subscriptionId: subscriptionId,
            location:location,
            settingsValues: data?.settingsValues ?? []
        };

        handleSubmit(doc);
    }

    const buttonStyle = {
        marginTop:2, marginRight:2
    }

    return (
        <Box>
            <Stack>
                <TextField
                    variant="standard"
                    value={subscriptionId}
                    onChange={(e) => setSubscriptionId(e.target.value)}
                    label="Azure SubscriptionId" />
                <TextField
                    variant="standard"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    label="Azure Region" />
                <div>
                    {allowCancel && <Button sx={buttonStyle} variant="outlined" onClick={handleCancel}>Cancel</Button>}
                    <Button sx={buttonStyle} variant="contained" onClick={handleSubmitConfig}>Submit</Button>
                </div>
            </Stack>
        </Box>
    )
}

export default AzureConfigForm;