import { SavedSecretResponse } from "../datamodel/dtos/SavedSecretResponse";
import { IOrgSecret } from "../datamodel/interfaces/orgsecret.interfaces";
import { ApiHelper } from "./ApiHelper";

export class OrgSecretApi {
    public static async generateSecret(orgId: string) {
        const secret = await ApiHelper.authenticatedPost(`organization/${orgId}/secret`, {});
        return secret.data as unknown as SavedSecretResponse;;
    }

    public static async getOrgSecretList(orgId:string) {
        const secretList = await ApiHelper.authenticatedGet(`organization/${orgId}/secret`);
        const result = secretList.data;
        console.log("getOrgSecretList", result);
        return result as unknown as IOrgSecret[];
    }

    public static async deleteSecret(secret:IOrgSecret) {
        return await ApiHelper.authenticatedDelete(`organization/${secret.org}/secret/${secret._id}`);
    }
}