import { Button, Card, CardContent, Typography } from "@mui/material";
import { IAzConfigSettings, IConfigResourceSettings } from "../../datamodel/interfaces/deployment-settings.interfaces";
import { AzConfigApi } from "../../api/AzConfigApi";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import { useState, useCallback, useEffect } from "react";
import DatasheetFromObject from "../DatasheetFromObject";
import DopCollapse from "../DopCollapse";

interface IAzureConfigSettingsSummaryProps {
    org: IOrganization
    publishConfig?: (settingsValues: IConfigResourceSettings[]) => void
}

const AzureConfigSettingsHome = (props: IAzureConfigSettingsSummaryProps) => {
    const { org, publishConfig } = props;
    const [azConfig, setAzConfig] = useState<IAzConfigSettings>();

    const refreshAzConfig = useCallback(() => {
        AzConfigApi.getAzConfigForOrg(org._id)
            .then(c => {
                setAzConfig(c);
                publishConfig && publishConfig(c); //maybe use side-effect
            }
            );
    }, [org._id,publishConfig ])

    useEffect(() => {
        refreshAzConfig()
    }, [refreshAzConfig])

//TODO: remove
//    const settingCount = azConfig?.settingsValues ? azConfig.settingsValues.length : 0;

    const handleAddDefaults = async () => {
        await AzConfigApi.generateDefaults(azConfig!._id, azConfig!.orgId);
        refreshAzConfig();
    }

    const title = "Deployment Settings";
    return (
        <>
            {/* <Datasheet data={{...azConfig, settingCount: settingCount}} keys={[
                    { keyValue: "settingCount", displayName: "Settings"}
                ]} /> */}

            {(!azConfig?.settingsValues || azConfig?.settingsValues.length === 0) &&
                <Card elevation={6} sx={{ marginTop: 2, marginBotton: 2, padding: 2 }}>
                    <CardContent>
                        <b>{title}</b>
                        <br /><br />
                        <Button onClick={handleAddDefaults}>Generate default config</Button>
                    </CardContent>
                </Card>
            }


            {azConfig?.settingsValues && azConfig?.settingsValues.length > 0 &&<>
                <Typography variant="body1" sx={{paddingBottom:3}}>Please review and edit settings, if needed.  Default settings are intended to be functional.</Typography>
                 <DopCollapse title={<b>{title}</b>}>
                    {
                        azConfig?.settingsValues.map(
                            (s, i) => {
                                return <DatasheetFromObject 
                                        key={`${s._id}_${i}`}
                                        excludeNamePattern={/_secret_value/}
                                        title={s.resourceDisplayName} 
                                        dataObject={s.settings} 
                                        isRecursive={true} />
                                // const keys = Object.keys(s.settings);
                                // let x:any[] = [];
                                // keys.forEach( key=> {
                                //     if(!Array.isArray( s.settings[key])) { 
                                //         x.push(
                                //             {keyValue: key, displayName: key}
                                //         );
                                //     }
                                // })

                                // return <Datasheet key={i} title={<b>{s.resourceDisplayName}</b>} data={s.settings} keys={x} />
                            }
                        )}
                    <Button onClick={handleAddDefaults}>
                        Reset config to defaults
                    </Button>

                </DopCollapse>
                    </>
            }
            {/* <Button variant="contained" onClick={()=>setConfigViewMode(ConfigViewMode.EDIT)}>Edit</Button> */}
        </>
    )
}

export default AzureConfigSettingsHome;
