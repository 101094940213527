import { useEffect, useState } from "react";
import { IStepConfig } from "../IStepConfig";
import Step1Info from "./step01Info";
import { Route, Routes, useNavigate } from "react-router-dom";

const ExportConfig = ()=> {
    const stepCount = 5;
    const [stepIndex, setStepIndex] = useState(0);
    const navigate = useNavigate();
    
    const nextStep = ()=> {
        
        const newIndex = stepIndex+1; 
        if( newIndex === stepCount) {
            navigate("done");
        }
        else {
            setStepIndex(newIndex);
            navigate(steps[newIndex].path);
        }
    }

    const backStep = ()=> {
        let newIndex = stepIndex-1;
        setStepIndex(newIndex);
        //alert(steps[newIndex].path);
        navigate(steps[newIndex].path);
    }

    const cancel = ()=> {
        alert("cancel")
    }

    

    
    const [steps] = useState<IStepConfig[]>([
        {
            back:backStep,
            next:nextStep,
            cancel:cancel,
            stepCount:stepCount,
            stepIndex:0,
            path: "step1"
        },
        {
            back:backStep,
            next:nextStep,
            cancel:cancel,
            stepCount:stepCount,
            stepIndex:1,
            path: "step2"
        },
        {
            back:backStep,
            next:nextStep,
            cancel:cancel,
            stepCount:stepCount,
            stepIndex:2,
            path: "step3"
        },
        {
            back:backStep,
            next:nextStep,
            cancel:cancel,
            stepCount:stepCount,
            stepIndex:3,
            path: "step4"
        },
        {
            back:backStep,
            next:nextStep,
            cancel:cancel,
            stepCount:stepCount,
            stepIndex:4,
            path: "step5"
        }
    ]);

    useEffect( ()=>{
        navigate(steps[stepIndex].path);
    }, [stepIndex, steps, navigate])


    return (
        <>
        <Routes>
            <Route path="/" element={<Step1Info {...steps[0]} />}/>
            <Route path="/done" element={<div>Done</div>}/>
            
            {steps.map( (step)=>  <Route key={step.path} path={step.path} element={<Step1Info {...step} />}/>)}
        </Routes>
        </>
    )
}

export default ExportConfig;