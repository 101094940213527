import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import { ReactNode } from "react";


interface IDatasheetKey {
    keyValue: string,
    displayName: string,
    altValueFunction?: () => string
}
interface IDatasheetProps {
    data: any,
    keys: IDatasheetKey[],
    title?: ReactNode,
    excludeNamePattern?: RegExp
}


const Datasheet = (props: IDatasheetProps) => {
    const { data, keys, title } = props;


    return (<>
        <Table size="small">
            <TableBody>
                {title && <TableRow>
                    <TableCell colSpan={2}>{title}</TableCell>
                </TableRow>}
                {keys.map((key, ind) => {
                    if (props.excludeNamePattern && props.excludeNamePattern.test(key.keyValue)) return<></>;

                    return <TableRow key={`${key}-${ind}`}>
                        <TableCell align="right">{key.displayName}:</TableCell>
                        <TableCell align="left">{
                            key.altValueFunction ?
                                key.altValueFunction() :
                                data[key.keyValue as keyof typeof data]
                        }</TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
    )
}

export default Datasheet;