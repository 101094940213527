import { ITerraformCloudConfig } from "../datamodel/interfaces/terraform.interfaces";
import { ApiHelper } from "./ApiHelper";

export class InfrastructureApi {
    static async validate(terraformConfig: ITerraformCloudConfig, orgId: string) {
        const result  = await ApiHelper.authenticatedPatch(`terraform-config/${terraformConfig._id}/validate`, {});
        return result.data;
    }

    public static async getInfrastructureConfigs(orgId:string) {
        const list = await ApiHelper.authenticatedGet(`org/${orgId}/terraform-config`);
        return list.data as ITerraformCloudConfig[];
    }

    public static async  create(doc:ITerraformCloudConfig, orgId:string) {
        const saved = await ApiHelper.authenticatedPost(`org/${orgId}/terraform-config`, doc);
        return saved.data as ITerraformCloudConfig;
    }

    public static async  update(terraformConfigId:string, doc:ITerraformCloudConfig, orgId:string) {
        const saved = await ApiHelper.authenticatedPut(`terraform-config/${terraformConfigId}`, doc);
        return saved.data as ITerraformCloudConfig;
    }
}