import { Dialog, DialogContent } from "@mui/material";
import { CreateKoboInstanceDto } from "../../datamodel/dtos/CreateKoboInstanceDto";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import KoboForm from "./KoboForm";

interface IAddKoboDialogProps {
    showAddKoboDialog: boolean,
    org: IOrganization,
    handleCancelAddKoboInstance: () => void,
    handleSubmitAddKoboInstance: (instance: CreateKoboInstanceDto) => void

}
const AddKoboDialog = (props: IAddKoboDialogProps) => {
    const { 
        showAddKoboDialog, 
        handleCancelAddKoboInstance, 
        handleSubmitAddKoboInstance, 
        org } = props;


    return (

        <Dialog open={showAddKoboDialog}>
            {/* <DialogTitle>Add CommCare</DialogTitle> */}
            <DialogContent>
                {org && <KoboForm orgId={org._id} cancel={handleCancelAddKoboInstance} submit={handleSubmitAddKoboInstance} />}
            </DialogContent>
        </Dialog>
    )
}

export default AddKoboDialog;