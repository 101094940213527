import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
    const {t} = useTranslation()
    return (

        <Grid container >
            <Grid item md={6} xs={12} >
                <Box sx={{margin:10}}>
                    <Typography variant="h4" sx={{textAlign: "center"}}>
                        {t("contact-title")}
                    </Typography>
                    <Typography variant="body1">
                        {t("contact-header-text")}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={6} xs={12}>
                <iframe 
                title = "sign-up"
                width="540" 
                height="705" 
                src="https://221abd1f.sibforms.com/serve/MUIEAA5VGCVSFRawE4-uXKGdSjW6y0QWdRq9SlTo6ZDm883W8OLsQ2HUvAzZ0m5k7A6j0Vpia4sUzLkjUnpgcydJdyGXwzfd8TkknBJzIFHYva4-Hox708gcMSYCuK6XSF1kzEPJCmdnMq3ZPd2duCj4_rwV-HWIJ8VfaTvTRczwRfV4fH2ziEMDT6u_xSllMIv5p3-EMlbDK0yr"frameBorder="0" 
                scrolling="auto" 
                allowFullScreen
                style={{
                    display: "block", 
                    marginLeft:"auto",
                    marginRight: "auto", minWidth: "100%", 
                    minHeight: "100%"}}
                ></iframe>
                </Grid>
        </Grid>
    );
}

export default ContactUs;