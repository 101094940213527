import { useState, useEffect, useCallback } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Dialog, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { OrgSecretApi } from "../../api/OrgSecretApi";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import { IOrgSecret } from "../../datamodel/interfaces/orgsecret.interfaces";
import { SavedSecretResponse } from "../../datamodel/dtos/SavedSecretResponse";
import { Fragment } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';


interface IOrgSecretProps {
    org: IOrganization
}

const OrgSecrets = (props: IOrgSecretProps) => {
    const { org } = props;
    const [newSecretResponse, setNewSecretResponse] = useState<SavedSecretResponse>();
    const [secretList, setSecretList] = useState<IOrgSecret[]>([]);

    const refreshSecretList = useCallback(() => {
        OrgSecretApi.getOrgSecretList(org._id).then(
            (result) => setSecretList(result)
        );
    }, [org])

    useEffect(() => {
        refreshSecretList()
    }, [refreshSecretList])


    const handleGenerateSecretClick = async () => {
        let secret = await OrgSecretApi.generateSecret(org._id);
        console.log("handleGenerateSecretClick", secret);
        setNewSecretResponse(secret);
        refreshSecretList();
    }

    const handleCloseNewSecret = () => {
        setNewSecretResponse(undefined);
    }

    const handleDeleteClick = async (secret: IOrgSecret) => {
        await OrgSecretApi.deleteSecret(secret);
        refreshSecretList();
    }

    return (<Card elevation={3}>

        {/* <CardHeader title={<>
        <Typography variant='h4'>Secrets</Typography>
        <Typography variant='body2'>Secrets are used to connect various services, like monitoring CommCare backups.</Typography>
        
        
        </>}/> */}
        <CardContent>
            <Accordion elevation={0}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant='h4'>Secrets ({secretList.length})</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell align="right">Client ID:</TableCell>
                                <TableCell align="left">{org._id}</TableCell>
                            </TableRow>
                            {secretList.map((secret) => <Fragment key={secret._id}><TableRow
                                key={secret._id}>
                                <TableCell align="right">Secret:</TableCell>
                                <TableCell align="left">
                                    <span>{secret.maskedSecret} </span><IconButton onClick={() => handleDeleteClick(secret)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                                <TableRow>
                                    <TableCell align="right">Created on:</TableCell>
                                    <TableCell align='left'>{secret.createDate}</TableCell>
                                </TableRow>
                            </Fragment>
                            )}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
            {newSecretResponse
                ?
                <Dialog open={newSecretResponse != null} onClose={() => setNewSecretResponse(undefined)}>
                    <DialogTitle>New Secret</DialogTitle>
                    <Paper elevation={0} sx={{ margin: 2, padding: 2 }}>
                        <Typography sx={{ marginBottom: 3 }} >This your secret.  Please copy it, it will not be shown again.  We do not retain a copy of you secret and cannot recover it.</Typography>
                        <Typography variant='body1'>Secret: {newSecretResponse.originalSecret}</Typography>
                        {/* <Typography variant='body1'>Secret: {newSecretResponse.secret.maskedSecret}</Typography> */}
                        <Button onClick={handleCloseNewSecret}>Close</Button>
                    </Paper>

                </Dialog>

                :
                <Button onClick={handleGenerateSecretClick}>Generate Secret</Button>
            }

        </CardContent>
    </Card>
    )
}

export default OrgSecrets;