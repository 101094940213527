import { Button, Typography } from '@mui/material'
import { IOrganization } from '../../datamodel/interfaces/organization.interfaces';
import { PropsWithChildren } from 'react';
import DopCollapse from '../DopCollapse';
import { IKoboInstance } from '../../datamodel/interfaces/IKoboInstance.interface';

interface IKoboListProps {
    org: IOrganization,
    koboInstanceList: IKoboInstance[],
    currentKoboInstance: IKoboInstance | undefined,
    addKoboInstance: () => void
    setCurrentKoboInstance: (ci: IKoboInstance) => void
}

const KoboList = (props: IKoboListProps & PropsWithChildren) => {
    const { addKoboInstance, koboInstanceList } = props;
    //const   {orgId} = useParams();
    //const [selectedKoboInstance, setSelectedKoboInstance] = useState<IKoboInstance>();
    
    const handleAddKoboInstance = () => {
        addKoboInstance();
    }

    return ( <>
        <DopCollapse
            title={<></>
            // <b>
            //     Kobo Data Collection
            //     </b>
            } isCollapsible={false} elevation={0} sx={{padding:0, margin:0}}>

        
                        {koboInstanceList.length === 0 && <Button onClick={handleAddKoboInstance}>Add Kobo Instance</Button>}
                        {koboInstanceList.length === 0 ?
                            <Typography variant='subtitle2' >At the moment there are no CommCare instances.  Start by adding one.</Typography >
                            :
                            <>
                                {
                                // Array.isArray(koboInstanceList) && 
                                // <Table size="small">

                                //     <TableBody>
                                //         {koboInstanceList.map((koboInstance, i) => {
                                //             return (
                                //                 <TableRow key={koboInstance._id}>
                                //                     <TableCell>{koboInstance.name}</TableCell>
                                //                     <TableCell>{koboInstance.description}</TableCell>
                                //                     <TableCell>{koboInstance.username}</TableCell>

                                //                     <TableCell>{koboInstance._id}</TableCell>
                                //                     <TableCell>
                                //                         {/* <Link 
                                //                             onClick={()=>props.setCurrentKoboInstance(koboInstance)}
                                //                             //to={`kobo/${koboInstance._id}`}
                                //                         >Manage</Link>
                                //                         <Button component={Link}>M</Button> */}

                                //                     </TableCell>
                                //                 </TableRow>
                                //             )
                                //         })}
                                //     </TableBody>
                                // </Table>
                                }
                            </>
                        }

                        {props.children}
        </DopCollapse>
        
        </>
    )
}

export default KoboList;