import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack } from "@mui/material";
import { ILandscapeSection } from "../../api/landscape/ILandscapeSection";
import CloseIcon from '@mui/icons-material/Close';

interface ILandscapeSectionDetailProps extends ILandscapeSection {
    handleClose: () => void,
    isOpen: boolean
}

const LandscapeSectionDetail = (props: ILandscapeSectionDetailProps) => {
    const { isOpen, handleClose, description, name } = props;

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle sx={{ m: 0, p: 2 }} >
                {name}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={12}>
                        <Stack spacing={2}>
                            <div>Name: {name}</div>
                            <div>{description}</div>
                        </Stack>
                    </Grid>
                </Grid>        </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default LandscapeSectionDetail;