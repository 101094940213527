import { Button, Link as MuiLink, Typography } from "@mui/material";
import { IStepConfig } from "../IStepConfig";
import SetupChecklist from "../setupChecklist";

interface IStep1_InfoProps extends IStepConfig {
    
}


const Step1Info = (props: IStep1_InfoProps)=>{
    const {next, back, cancel, stepIndex, stepCount} = props;
    return (
        <>
        {stepIndex === 0 && <>
            <Typography variant="h4">Step 1: Overview</Typography>
            
<p>This wizard will walk you through the setup of CommCare export to PostgreSQL database in Azure.  Soon we will be able to support AWS and GCP clouds.  Please contact us if you are working on these platforms and we will be glad to collaborate.</p>
<p>In the following steps we will collect the information needed and provide you with scripts customized to your needs to establish a cost effective export and backup solution in minutes.</p>


<Typography variant="h5">How does it work?</Typography>
<p>
The scripts use <code>terraform</code>. This is an industry standard tool used to create, manage and ultimately destroy cloud infrastructure based on a description of needed environment using special terraform syntax. We have done the hard work of creating the script that defines the structure of the environment.  All we need is set some variables that are specific to your environment - subscription id, names for your resources groups, etc. 
</p>
<p>
We will guide through this collection process step-by-step.  Once we collect your environment specific settings, we will be able to customize this script.  Then, when you are ready, you can run this script using once command <code>terraform apply</code> and the environment will be established with running exports.
</p>

{/* 
<Typography variant="h5">Why PostgresSQL?</Typography>
<p>
We are using dimagi's Data Export Tool - we didn't to duplicate work and this tool is supported by the maker of CommCare.  This tool is supported and can be used to create and effective backup to PostgreSQL database's JSON column.  
</p>

            </Typography> */}
        </>
        }
        {stepIndex === 1 && <>
            <Typography variant="h4">Step {stepIndex+1}: Get necessary files</Typography>
            <p>In this step we will setup the core infrastructure using terraform.  You can learn more about terraform by visiting <a href="https://www.terraform.io/" rel="noreferrer" target="_blank">https://www.terraform.io/</a></p>
            <Typography variant="h5">1. Download and install terraform</Typography>
            <p>Follow <a href='https://developer.hashicorp.com/terraform/downloads?ajs_aid=b30bdec1-6fb7-42a5-8456-ac640e29e722&product_intent=terraform' rel="noreferrer" target="_blank">instructions to install terraform for you platform</a>.</p>
            <Typography variant="h5">2. Download <code>terraform</code> scripts</Typography>
            <p>Clicking the button will download a zip file containing terraform scripts for testing your <code>terraform</code> setup and to create CommCare export infrastructure.  The zip file will container the following directories:
            <ul>
                <li>test: this directory has scripts to manage a resource group and storage account.  The purpose of this is to make sure the <code>terraform</code> installation is working. </li>
                <li>export: this directory contains the scripts to create the CommCare export infrastructure. </li>
            </ul>
            The following steps will walk you through the process.
            </p>
            <p>
                Please go on to the next step once you extracted the the zip file you downloaded and install terraform for your machine.
            </p>
        </>}
        {stepIndex===2 && <>
            <Typography variant="h4">Step {stepIndex+1}: Test terraform (10-20 min)</Typography>
            <p>In this step will run the test to make sure terraform is working correctly.  You can follow the steps below or use <a href="http://www.example.com" target="_blank" rel="noreferrer" onClick={()=>alert('not yet implemented')} >this YouTube video </a>as a guide.</p>
            
            <SetupChecklist checklistItems={[
                {
                    description: "Open you command prompt and navigate to the <code>test</code> directory."
                }, 
                {
                    description: "Set this value"
                },
                {description:"set that value"},
                {description:"Detailed instructions for setting to come to come."},
                {description: <>Run <code>terraform init</code>.  This sets up the terraform environment.</>},
                {description:<>Run <code>terraform apply</code>.  This will analyze the infrastructure and plan the update.</>},
                {description:<>Go to Azure and review view the changes.</>},
                {description:<>Teardown the test infrastructure using <code>terraform destroy</code>.</>}

                ]
                    
                } />

            <p>
                Once you have completed the test, please go to the next step to setup the infrastructure for exports.
            </p>
        </>}
        {stepIndex === 3 && <>
            <Typography variant="h4">Step {stepIndex+1}: Configure and deploy export infrastructure</Typography>
            <p>In this step we are going to create the cloud infrastructure - database, storage, and everything else needed to do an export from CommCare to PostgreSQL.  We are not yet going to run a specific export - at this point we are just setting the infrastructure.</p>
            <SetupChecklist checklistItems={[
                {description: <>Update subscription.</>},
                {description: <>Setup your terraform environment <code>terraform init</code></>},
                {description: <>Settings: (more to come) resource group, database name, ...</>},
                {description: <>Run <code>terraform apply</code> - this will </>}
            ]} />
    
            <p>Once the infrastructure is deployed, the next and final step is to deploy the export through terraform config.</p>
        </>}

        {stepIndex === 4 && <>
            <Typography variant="h4">Step {stepIndex+1}: Setup an export (20 min)</Typography>
            <p>In this step we are going to setup the export for specific commcare instances.  </p>
            <SetupChecklist checklistItems={[
                {description: <>In order to protect CommCare username and API key, we will put them in Azure KeyVault.  A key vault was created in a prior step.  Here are <MuiLink onClick={()=>alert("not implemented")}>detailed instructions</MuiLink> and a <MuiLink  onClick={()=>alert("not implemented")}>short video</MuiLink> to help you store CommCare username and API key in in Azure Key vault.</>},
                {description: <>Provide domain to be exported.</>},
                {description: <>Settings: (more to come) resource group, database name, ...</>},
                {description: <>Run <code>terraform apply</code> - this will </>}
            ]} />
            
            
           { // <p>
            //     <ol>
            //         <li>In order to protect CommCare username and API key, we will put them in Azure KeyVault.  A key vault was created in a prior step.  Here are <MuiLink onClick={()=>alert("not implemented")}>detailed instructions</MuiLink> and a <MuiLink  onClick={()=>alert("not implemented")}>short video</MuiLink> to help you store CommCare username and API key in in Azure Key vault.</li>
            //         <li>Provide domain to be exported</li>
            //         <li>Settings: (more to come) resource group, database name, ...</li>
            //         <li>Run <code>terraform apply</code> - this will </li>
            //     </ol>
            //                 </p>
           }
            <p>Once the infrastructure is deployed, the next and final step is to deploy the export through terraform config.</p>
        </>}
        <div>Step {stepIndex+1} of {stepCount}</div>
        <Button onClick={back} disabled={stepIndex === 0}>Back</Button>
        <Button onClick={next} >{stepIndex +1 === stepCount ? "Finish":"Next"}</Button>
        <Button onClick={cancel}>cancel</Button>
        <p>Please contact us if you run into any issue.  We can help.</p>
        </>
    )
}

export default Step1Info;