import {  useState } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import Datasheet from '../Datasheet';
import { IDataCollectionFormContainer } from '../../datamodel/interfaces/datacollectioninterfaces';
import ApplicationSummary from './kobo-form/ApplicationSummary';
import { IKoboInstance } from '../../datamodel/interfaces/IKoboInstance.interface';
import ApplicationTree from '../data-portection-form/ApplicationTree';
import ontology from '../../ontology.json'

interface IKoboDetailProps {
    org: IOrganization,
    koboInstance: IKoboInstance
    mode: string,
    showDataProtectionButton?:boolean,
    refresh: () => void,
    setCurrentKoboInstance: (c: IKoboInstance) => void,
    requestUpdateMetadataFromKoboApi: (c: IKoboInstance) => void //update metadat from kobo
    saveMetadataUpdate: (metadata: IDataCollectionFormContainer, koboId: string) => void

    // navigationPaths: {
    //     exportSetupPath: string,
    //     reportingSetupPath: string,
    //     monitoringSetupPath: string,
    //     sharingAndExportPath: string
    // }

}

const KoboDetail = (props: IKoboDetailProps) => {
    const {koboInstance } = props;
    const [isUpdateFormOpen, setIsUpdateFormOpen] = useState<boolean>(false);
    const showDataProtectionButton=props.showDataProtectionButton ?? true;

    // const loadKoboInstance = useCallback( ()=>{
    //     koboId && 
    //     KoboApi.getKoboInstance(koboId,org._id).then(
    //         (ci:IKoboInstance)=> {
    //             setKoboInstance(ci);
    //         }
    //     )
    // }, [])

    // const navigate = useNavigate();

    // useEffect(() => {
    //     koboInstance && MonitoringEventsApi.getKoboEventsList(org._id, koboInstance._id)
    //         .then((result) => setEventList(result));

    // }, [org, koboInstance])



    // const startSetup = (path: string) => {
    //     navigate(path);
    // }


    const styles = {

        stepContainer: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        steps: {
            margin: 2,
            padding: 2,
            width: 250,
            minWidth: 200,
            flexWrap: "wrap"
        },
        header: {
            margin: 2,
            padding: 2,
            maxWidth: 800
        }
    }

    const handleUpdateContainer = async (c: IDataCollectionFormContainer) => {
        //update 
        props.saveMetadataUpdate(c, koboInstance._id);
        
    }

    const handleLoadMetadata = async () => {
        props.requestUpdateMetadataFromKoboApi(koboInstance);
    }

    const showUpdateForm = () => {
        setIsUpdateFormOpen(true);
    }

    return (
        <Box sx={styles.header} >
            <Box sx={styles.header}  >
                <Typography variant='h6'> Kobo Instance Detail</Typography>
                <>
                    {props.mode === "overview" && <><Datasheet data={
                        {
                            ...koboInstance,
                            'rawMetadataStr': koboInstance.rawMetadata ? JSON.stringify(koboInstance.rawMetadata).slice(0, 240) + "..." : "",
                            'parsedMetadataStr': koboInstance.parsedMetadata ? JSON.stringify(koboInstance.parsedMetadata).slice(0, 240) + "..." : ""
                        }
                    } keys={[
                        { keyValue: "name", displayName: "Name" },
                        { keyValue: "description", displayName: "Description" },
                        { keyValue: "_id", displayName: "ID" },
                        { keyValue: "server", displayName: "Server" },
                        { keyValue: "maskedKey", displayName: "Masked Key" },
                        { keyValue: "rawMetadataStr", displayName: "Kobo Raw Metadata" },
                        { keyValue: "parsedMetadataStr", displayName: "Parsed Metadata" }
                    ]} />

                        <div>
                            <Button onClick={()=>{console.error("Edit kobo instance not implemented")}}>
                                Edit
                            </Button>
                            <Button onClick={handleLoadMetadata}>
                                {koboInstance.rawMetadata ?  "Refresh Metadata" : "Load Metadata"}
                            </Button>
                            {/* <Button onClick={handleViewMetadata}>View Metadata</Button> */}
                        </div>
                    </>}
                    {showDataProtectionButton && koboInstance.parsedMetadata && <>
                        <ApplicationSummary rootContainer={koboInstance.parsedMetadata} />
                        <Button sx={{ marginTop: 1 }} variant='contained' onClick={showUpdateForm}>Edit Data Protection Settings</Button>
                    </>
                    }

                    <Dialog open={isUpdateFormOpen} fullScreen={true}>
                        <DialogContent>
                            <Box sx={{width:1000}}>
                            {koboInstance.parsedMetadata &&
                                <ApplicationTree rootContainer={koboInstance.parsedMetadata} ontology={ontology}
                                    onSave={(c: IDataCollectionFormContainer) => {
                                        handleUpdateContainer(c);
                                        setIsUpdateFormOpen(false);
                                    }}
                                    onCancel={() => setIsUpdateFormOpen(false)}
                                />
                            }
                            </Box>
                        </DialogContent>
                    </Dialog>
                </>
            </Box>
        </Box>
    )
}

export default KoboDetail;