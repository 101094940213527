import { useCallback, useEffect, useRef, useState } from "react";

interface ICountdownTimerProps {
    minutes: number,
    seconds: number,
    done: ()=>void,
    showTimer:boolean
}

const CountdownTimer = (props:ICountdownTimerProps) => {
    const {done} = props;
    const [minutes, setMinutes] = useState(props.minutes);
    const [seconds, setSeconds] = useState(props.seconds);

    // eslint-disable-next-line
    const [timerId, setTimerId] = useState<any>(null);
    const timerId2 = useRef<any>(null);


    const intervalCallback = useCallback( ()=> {
        setSeconds(seconds => seconds-1);
        //count.current = count.current+1;
    }, []);
    
    useEffect(()=>{
        const id = setInterval( intervalCallback, 1000 );
        timerId2.current = id;
        setTimerId(id);
        return ()=> clearInterval(id);    
    }, [intervalCallback])
    
    useEffect(()=>{
        if(seconds < 0 ) {
            setSeconds(seconds => 60+seconds);
            setMinutes(minutes => minutes-1);
        }
    }, [seconds]);


    useEffect(()=>{
        if(minutes < 0 ) {
            setSeconds(seconds);
            setMinutes(minutes);
            //console.log("clearInterval")
            //clearInterval(timerId2.current);
            console.log("props.done")
            done();
            
        }
    }, [minutes, seconds, done]);


    return props.showTimer ? 
        <> {minutes}:{seconds}</> :
        <></>
}

export default CountdownTimer;