import { useState, useEffect } from "react";
import { OrgApi } from "../../api/OrgApi";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import OrgList from "../org/OrgList";

const OrgSummary = () => {
    const [orgList, setOrgList] = useState<IOrganization[]>([])
    //startup
    useEffect( ()=> {
        OrgApi.getOrgList().then(
            (result:any[])=> 
            {
            console.log("getOrgList", result);
            setOrgList(result);
            }
        )
    }, [])



    return (
        <>
            <OrgList orgList={orgList} addOrg={()=>alert('add')} selectOrg={()=>alert('select')}/>        
        </>
    )
}

export default OrgSummary;