export enum TerraformConfigValidationStatus {
    VALIDATED="validated",
    PENDING="pending",
    ERROR="Error"
}

export interface ITerraformCloudConfig {
    _id?:any,
    orgId:any,
    orgName:string,
    projectName:string,
    workspaceName:string,
    validationStatus:TerraformConfigValidationStatus,
    validationMessage?:string,
    rawValidationMessage?:string,
    workspaceId?:string,
    key?:string,
    maskedKey?:string,
    settingValues:any[]

}

export interface ITerraformCloudDeploy {
    orgId:any,
    dateStart: Date, //update triggered
    config:any, //capture config at the time
    settings:any, //capture settings at the time
    workspaceId:string, //workspace id
    configVersionId:string, //Terraform cloud config versions id
    runId:string, //Terraform cloud run id
    runStatus:string,
    runStatusUpdateDate:string
}