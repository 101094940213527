import { IAzConfigSettings } from "../datamodel/interfaces/deployment-settings.interfaces";
import { ApiHelper } from "./ApiHelper";

export class AzConfigApi {
    
    static async getAzConfigForOrg(orgId:string) {
        const config = await ApiHelper.authenticatedGet(`org/${orgId}/az-config`);
        return config.data;
    }

    static async createAzConfig(c: IAzConfigSettings, orgId: string) {
        const config = await ApiHelper.authenticatedPost(`org/${orgId}/az-config`, c);
        return config.data;
    }

    static async updateAzConfig(c: IAzConfigSettings, configId: string, orgId: string) {
        const config = await ApiHelper.authenticatedPut(`org/${orgId}/az-config/${configId}`, c);
        return config.data;
    }

    static async generateDefaults(configId:string, orgId:string) {
        const config = await ApiHelper.authenticatedPost(`org/${orgId}/az-config/${configId}/defaults`, {})
        return config;
    }
}