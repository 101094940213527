// import { useParams } from "react-router-dom"
// import CommcareForm from "./CommcareForm"
// import CommcareList from "./CommcareList"

import { useEffect, useState, useCallback } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { ICommcareInstance } from "../../datamodel/interfaces/commcare.interfaces";
import { IOrganization } from "../../datamodel/interfaces/organization.interfaces";
import CommcareDetail from "./CommcareDetail";
import CommcareSetup from "./setup/CommcareSetup";

interface ICommcareHomeProps {
    orgId: string,
    getOrg: (id: string) => IOrganization | undefined,
    getCommcareInstance: (id: string) => ICommcareInstance | undefined
}


const CommcareHome = (props:ICommcareHomeProps)=> {
    const { orgId, getOrg, getCommcareInstance } = props;
    const { commcareInstanceId } = useParams()
    
    const [org, setOrg] = useState<IOrganization>();
    const [commcareInstance, setCommcareInstance] = useState<ICommcareInstance>();
    
    const refreshCommcareInstance = useCallback( ()=> {
        commcareInstanceId && setCommcareInstance(getCommcareInstance(commcareInstanceId));
    }, [commcareInstanceId, getCommcareInstance] )

    useEffect(() => {
        setOrg(getOrg(orgId));
        refreshCommcareInstance()
    }, [orgId, commcareInstanceId, getOrg, refreshCommcareInstance ])

    //TODO: remove
    // capture locations of the various activities
    //const currentPath = useLocation();
    // const navigationPaths = {
    //     exportSetupPath: `${currentPath.pathname}/setup/export`,
    //     reportingSetupPath: `${currentPath.pathname}/setup/reporting`,
    //     monitoringSetupPath: `${currentPath.pathname}/setup/monitoring`,
    //     sharingAndExportPath: `${currentPath.pathname}/setup/sharing`
    // }

    //const {orgId} = useParams()
    return (
    <>
    <Routes>
        <Route path="/" element={
            commcareInstance && org &&         
            <CommcareDetail 
                refresh={refreshCommcareInstance} 
                requestUpdateMetadataFromCommcareApi={(c:ICommcareInstance)=>{}}
                saveMetadataUpdate={()=>{}} 
                commcareInstance={commcareInstance} org={org} 
                setCurrentCommcareInstance={(c: ICommcareInstance)=>{}}            />
        } />
        <Route path="/setup/*" element={
            commcareInstance && org &&
            <CommcareSetup />
        } />
    </Routes>
    </>
    )

}

export  default CommcareHome;