import { CreateCommcareInstanceDto } from "../datamodel/dtos/CreateCommcareInstanceDto";
import { ICommcareInstance } from "../datamodel/interfaces/commcare.interfaces";
import { IDataCollectionFormContainer } from "../datamodel/interfaces/datacollectioninterfaces";
import { ApiHelper } from "./ApiHelper";

export class CommcareApi {
    
    public static async getCommcareInstances(orgId: string) {
        let res = await ApiHelper.authenticatedGet(`org/${orgId}/commcare`);
        const output = res.data as ICommcareInstance[];
        return output;
    }

    public static async getCommcareInstance(commcareId:string, orgId:string) {
        let res = await ApiHelper.authenticatedDelete(`org/${orgId}/commcare/${commcareId}`);
        const output = res.data as ICommcareInstance;
        return output;
    } 

    public static async saveInstance( orgId:string, instance:CreateCommcareInstanceDto) {
        let res = await ApiHelper.authenticatedPost(`org/${orgId}/commcare`, instance);

        return res.data;
    }

    public static async updateMetadata(metadata:IDataCollectionFormContainer, commcareId:string, orgId:string) {
        let res = await ApiHelper.authenticatedPatch(`org/${orgId}/commcare/${commcareId}/metadata`, metadata);
        return res.data;
    }

    public static async loadMetadata(commcareId: string, orgId: string) {
        let res = await ApiHelper.authenticatedPost(`org/${orgId}/commcare/${commcareId}/metadata`, {});
        return res.data;
    }

}