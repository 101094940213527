import { Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { IStepProps } from "../IStepProps";
import { IConfigResourceSettings } from "../../../../../datamodel/interfaces/deployment-settings.interfaces";
import StorageAccountSummary from "./storage-account-summary";

interface IStorageAccountStepProps extends IStepProps {
    resourceGroupDependency:IConfigResourceSettings|undefined,
}

const StorageAccountStep = (props:IStorageAccountStepProps) => {
    const numberOfSubsteps = 3;
    const {back,next, config, resourceGroupDependency} = props;
    const [storageAccountName, setStorageAccountName] = useState<string>(config.settings.name);
    const [currentStep, setCurrentStep] = useState(0);
    const [readyForDeployment, setReadyForDeployment] = useState<boolean>(false);
    
    const nextSubstep = ()=>{ 
        config.settings.name = storageAccountName;
        config.readyForDeployment = readyForDeployment;
        currentStep < (numberOfSubsteps-1) ?  setCurrentStep(currentStep+1) : next(config);
    }

    const previousSubstep = ()=>{
        currentStep === 0 ?  back() : setCurrentStep(currentStep-1);
    }

    const Step1Info = ()=> {
        return (<>
            <Typography variant="h6">Storage Account</Typography>
            <Typography variant="body1">This is used to store files.  Later will create containers for specific types of files.</Typography>
            </>
        )
    }

    const Step2Info = ()=> {
        return (<>
            <Typography variant="h6">Storage account configuration</Typography>
            <Typography variant="body1">To get started, just the name.</Typography>
            </>
        )
    }
    const Step3Info = ()=> {
        return (<>
            <Typography variant="h6">Review Storage Account</Typography>
            <Typography variant="body1">Please review this info.</Typography>
            </>
        )
    }

    return (
        <>
        {currentStep === 0 && <Step1Info/> }
        {currentStep === 1 && <>
            <Step2Info/>
            <TextField label="Storage Account Name" value={storageAccountName} onChange={(e)=>setStorageAccountName(e.target.value)}/>
            <Typography variant="body1">Resource group: {resourceGroupDependency?.settings.name}</Typography>

        </>}
        {currentStep === 2 && 
            <>
            <Step3Info/>
            <div>
                    <StorageAccountSummary showEdit={false} onEdit={() => { }} 
                            storageAccountSettings={config} 
                            resourceGroupDependency={resourceGroupDependency}
                            resourceGroupValidator={c=>true}/>
                    <FormControlLabel label="Ready for Deployment"
                        control={
                            <Checkbox checked={readyForDeployment} onChange={(e) => setReadyForDeployment(e.target.checked)}
                            />
                        } />
                </div>
            </>
        }
        
        
        <Button onClick={previousSubstep}>Back</Button>
        <Button onClick={nextSubstep}>Continue</Button>
        <div style={{ textAlign: "right" }}> Step {currentStep + 1} of {numberOfSubsteps}</div>

        </>
    )
}

export  default StorageAccountStep;