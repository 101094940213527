import { Box, Table, TableRow, TableCell, TableBody, TableHead, Button, Dialog, DialogContent, DialogActions, Typography, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useState, useEffect } from 'react'
import { IDataCollectionForm, IDataCollectionFormContainer, IDataCollectionQuestion } from '../../../datamodel/interfaces/datacollectioninterfaces';

const FieldNode = (
    f: any,
    fieldMapping: any,
    setField: (fieldId: string) => void,
    formData:any
) => {
    //TODO: remove: const questions = formData?.questions?.filter((q:any) => q.semantic_label === f.semantic_label || q.semantic_label === f.computedFrom)
    const formField = fieldMapping ? fieldMapping[f.id] : undefined;

    return <TableRow key={`fieldNode_${f.id}`}> 
        <TableCell>{f.name}</TableCell>
        <TableCell>{f.semantic_label}</TableCell>
        <TableCell>
            <div>
                {formField && `Form Field: ${formField.name}, Semantic Label: ${formField.semantic_label}`} {formField && formField.ontology && `Ontology: ${formField.ontology}`} 
                
                <Button onClick={() => setField(f)}>
                    {formField ? "Edit" : "Set"}
                </Button>
            </div>
        </TableCell>
        <TableCell>{f.ontology ?? "NA"}</TableCell>
    </TableRow>
}

interface IDataSharingRequestsProps {
    request:any,
    formData:IDataCollectionFormContainer
}



// interface IRenderContainerProps {
//     parentName:string,
//     container:IDataCollectionFormContainer
// }
// const RenderContainer = (props:IRenderContainerProps)=> {
//     const {parentName, container} = props;
//     const name=`${[parentName]}:${container.name}`;
//     return <>
//         {
//             container.forms.map(
//                 form=> {
//                     return form.questions.map( question=>
                        
//                         )
//                 }
//             )
//         }
    
//     </>
// }

interface IFormQuestionForList {
    path:string,
    id:string,
    question:IDataCollectionQuestion
}


const DataSharingRequests = (props:IDataSharingRequestsProps) => {
    const {request, formData} = props;
    const [fieldMapping, setFieldMapping] = useState<any>();
    const [isFormFieldOpen, setIsFormFieldOpen] = useState<boolean>(false);
    const [recommendedFields, setRecommendedFields] = useState<IFormQuestionForList[]>([])
    const [selectedFormValue, setSelectedFormValue] = useState<any>();
    const [dialogRequestField, setDialogRequestField]=useState<any>();
    const [showAllFields, setShowAllFields] = useState(false);
    const [formQuestions, setFormQuestions] = useState<IFormQuestionForList[]>([]);

    
    const getQuestionsFromFormData = (fd:IDataCollectionFormContainer)=> {
        const currentPath = "";
        const questions = [...getQuestionsFromForms(fd.forms, currentPath), ...getQuestionsFromSubcontainers(fd.subContainers, currentPath) ];

        return questions;
    }

    const getQuestionsFromForms = (forms:IDataCollectionForm[], path:string) => {
        const questionArrays =  forms.map( form=>getQuestionsFromForm(form, path));
        const result = questionArrays.reduce((accumulator, value) => accumulator.concat(value), []);
        return result;
    }

    const getQuestionsFromForm = (form:IDataCollectionForm, path:string) => {
        const currentPath = `${path}:${form.name}`;
        const questions = form.questions.map(question=>{
            const flatQuestion:IFormQuestionForList = 
            {
                path:currentPath, 
                id:question.label,
                question:question
            };
            return flatQuestion;
        
        });
        return questions;
    }

    const getQuestionsFromSubcontainers = (subContainers: IDataCollectionFormContainer[], path:string)=> {
        const containerQuestionsArray = subContainers.map(subContainer=>getQuestionsFromSubcontainer(subContainer, path));
        const containerQuestions = containerQuestionsArray.reduce((accumulator:IFormQuestionForList[], value:IFormQuestionForList[]) => accumulator.concat(value), []);
        
        return containerQuestions;
    }

    const getQuestionsFromSubcontainer:(subContainer: IDataCollectionFormContainer, path:string)=>IFormQuestionForList[] = (subContainer: IDataCollectionFormContainer, path:string)=> {
        const currentPath = `${path}:${subContainer.name}`;

        return [...getQuestionsFromSubcontainers(subContainer.subContainers, currentPath), ...getQuestionsFromForms(subContainer.forms, currentPath)] ;
    }


    useEffect( ()=>{
        const questions = getQuestionsFromFormData(formData);
        setFormQuestions(questions);
    //eslint-disable-next-line
    }, [formData])



    const changeSelectedFormField = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFormValue((event.target as HTMLInputElement).value);
    };

    // const changeSelectedFormField = ()=>{alert(11111);}
    const setField = (f: any) => {
        setDialogRequestField(f);

        setRecommendedFields(
            formQuestions.filter(q => 
                {
                    const questionSemanticLabel = q.question.properties?.semanticValue;
                    
                    return questionSemanticLabel && (
                    questionSemanticLabel === f.semantic_label || questionSemanticLabel === f.computedFrom
                    );
                })
        );
        setIsFormFieldOpen(true);
    }
    const cancelFieldSelect = () => setIsFormFieldOpen(false);
    const saveFieldSelect = () => {
        const newFieldMapping = {...fieldMapping};
        //newFieldMapping[dialogRequestField.id] = formData.questions.find((q:any) => q.id ===selectedFormValue );
        //todo:ids?
        
        newFieldMapping[dialogRequestField.id] = formQuestions.find((q:IFormQuestionForList) => q.question.label === selectedFormValue );
        setFieldMapping(newFieldMapping);
        setIsFormFieldOpen(false);
     }

    useEffect(() => {
        let y = new Map();
        request.fields.forEach((field:any) => y.set(field.id, ""));
        const o = Object.fromEntries(y);
        setFieldMapping(o);
    }, [request.fields])

    return <>
        <Dialog open={isFormFieldOpen}>
            <DialogContent>
                <Typography variant='h6'>Recommended</Typography>
                {dialogRequestField && <Typography variant='subtitle2'>
                    Requested data is for field {`Name: "${dialogRequestField.name}", Semantic Label: "${dialogRequestField.semantic_label}"`}.
                      {/* {`${JSON.stringify(dialogRequestField)}`}. */}
                </Typography>}
                <br/>
                
                <FormControl>
                    <FormLabel id="form-controlled-selected">Recommended Fields</FormLabel>
                    <RadioGroup
                        aria-labelledby="controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={selectedFormValue}
                        // onChange={()=>alert('123213')}
                        onChange={changeSelectedFormField}
                    >
                        {recommendedFields.map((f: IFormQuestionForList, i:number) => <FormControlLabel 
                            key={`recommended_${i}`}
                            value={f.id} control={<Radio />} label={`${f.question.label}; Semantic Label: ${f.question.properties?.semanticValue ?? "none"}`} />)}
                        <br />
                        <FormLabel id="form-controlled-selected">All Fields<Button onClick={()=>setShowAllFields(!showAllFields)}>{showAllFields? "Hide": "Show"}</Button></FormLabel> 
                        
                        <Box sx={{display:showAllFields? 'block':'none'}}>
                        {//formData.questions.map((f: any) => <FormControlLabel value={f.id} control={<Radio />} label={`Data Field: "${f.name}"; Semantic Label: "${f.semantic_label}"`} />)
                        }
                        {formQuestions.map((q: IFormQuestionForList, i:number) => <FormControlLabel 
                            key={`allQuestions_${i}`}
                            value={q.id} control={<Radio />} label={`${q.question.label}; Semantic Label: ${q.question.properties?.semanticValue ?? "none"}`} />)
                        }
                        </Box>
                    </RadioGroup>
                </FormControl>

            </DialogContent>
            <DialogActions>
                <Button onClick={cancelFieldSelect}>Cancel</Button>
                <Button onClick={saveFieldSelect}>Save</Button>
            </DialogActions>
        </Dialog>
        <div>
            {request.name}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Semantic Label</TableCell>
                        <TableCell>Export Data</TableCell>
                        <TableCell>Ontology</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {request.fields.map((f: any) => FieldNode(f, fieldMapping, setField, formData))}
                </TableBody>
            </Table>
        </div>
    </>
}

export default DataSharingRequests;