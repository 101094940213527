import { Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Input, InputLabel, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";

interface IFieldConfig {
    name: string,
    semantic_label: string
}

interface IUploadConfig {
    type: string,
    location: string,
}


interface IDataSource {
    name: string,
    source: string,
    fields: IFieldConfig[],
    upload: IUploadConfig
}

const MoreDataSourcesConfig = () => {
    const [isAddDataSourceOpen, setIsAddDataSourceOpen] = useState(false);
    const [newDataSource, setNewDataSource] = useState<IDataSource>({
        name: "",
        source: "cleaning",
        fields: [
            { name: "id", semantic_label: "person:id" }
        ],
        upload: {
            type: 'sharepoint',
            location: ""
        }
    });

    const addDataSource = () => {
        setIsAddDataSourceOpen(true);
    }

    const cancelAddDataSource = () => {
        setIsAddDataSourceOpen(false);
    }

    const saveDataSource = () => {
        setIsAddDataSourceOpen(false)
    }
    const formControlStyle = { paddingTop: 3 };

    const updateCurrentFormData = (newDs: IDataSource) => {
        const newerDs = { ...newDs };
        setNewDataSource(newerDs);
    }

    return <>
        <Button onClick={addDataSource}>Add Data Source</Button>
        
        <Dialog open={isAddDataSourceOpen}>
            <DialogContent>
                <Typography variant="body1">Configure additional sources from external processes such as cleaning, scoring, cash distribution, and others.</Typography>
                <FormControl sx={formControlStyle}>
                    <InputLabel htmlFor="appid-input">Source Name: </InputLabel>
                    <Input multiline id="appid-input" aria-describedby="appid-input-text"
                        value={newDataSource.name}
                        onChange={(e) => {
                            newDataSource.name = e.target.value;
                            updateCurrentFormData(newDataSource);
                        }} />
                    {/* <FormHelperText id="appid-input-text">App name that will be used to access the API.</FormHelperText> */}
                </FormControl>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field Name</TableCell>
                            <TableCell>Semantic Label</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {newDataSource.fields.map(field => <TableRow>
                            <TableCell>{field.name}</TableCell>
                            <TableCell>{field.semantic_label}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        )}
                        <TableRow>
                            <TableCell>
                                <FormControl sx={formControlStyle}>
                                    <InputLabel htmlFor="appid-input">Field Name: </InputLabel>
                                    <Input multiline id="appid-input" aria-describedby="appid-input-text"/>
                                    <FormHelperText id="appid-input-text">App name that will be used to access the API.</FormHelperText>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <FormControl sx={formControlStyle}>
                                    <InputLabel htmlFor="appid-input">Semantic Label: </InputLabel>
                                    <Input multiline id="appid-input" aria-describedby="appid-input-text"/>
                                    <FormHelperText id="appid-input-text">App name that will be used to access the API.</FormHelperText>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <Button>Add</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <br/>         
                <Button> Preview Excel Spreadsheet </Button>

            </DialogContent>
            <DialogActions>
                <Button onClick={cancelAddDataSource}>Cancel</Button>
                <Button onClick={saveDataSource}>Save</Button>
            </DialogActions>
        </Dialog>
    </>
}

export default MoreDataSourcesConfig;