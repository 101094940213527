import { Button, Paper, Typography } from "@mui/material"
import { PropsWithChildren } from "react"

interface IInfrastructureSummaryProps {
    title:string,
    onEdit:()=>void,
    showEdit:boolean,
    editDisabled:boolean,
    isReadyForDeployment:boolean
}

const InfrastructureSummary = (props:PropsWithChildren<IInfrastructureSummaryProps>)=> {
    const {onEdit,editDisabled, title, showEdit, isReadyForDeployment} = props;
    return <Paper elevation={0} sx={{padding:2, margin:2}}>
        <div>
            <Typography variant='h5' component={"span"}>{title}</Typography>
            {showEdit && <Button onClick={onEdit} disabled={editDisabled}>Edit</Button>}
        </div>
        {<span>Ready for deployment: {isReadyForDeployment? "Yes": "No"}</span>}
        {props.children}
    </Paper>

}

export default InfrastructureSummary;