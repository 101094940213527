import { Accordion, AccordionDetails, AccordionSummary, Card, CardActions, CardContent, SxProps,  Theme } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PropsWithChildren, ReactNode } from 'react';

interface IDopCollapseProps {
    title:ReactNode,
    isCollapsible?:boolean,
    elevation?: number;
    sx?:SxProps<Theme>
}

const DopCollapse = (props: IDopCollapseProps & PropsWithChildren) => {
    const sx = props.sx ?? { marginTop: 0, marginBottom: 2, padding:0 }
    const isCollapsible = props.isCollapsible ?? true;
    const elevation = props.elevation ?? 3;

    const collapsibleContainer = (props:IDopCollapseProps & PropsWithChildren)=> <Accordion elevation={0}>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
    >
        <>{props.title}</>

    </AccordionSummary>
    <AccordionDetails>
        {props.children}
    </AccordionDetails>
</Accordion>

const nonCollapsibleContainer = (props:IDopCollapseProps & PropsWithChildren)=> <>
    <>{props.title}</>
    {props.children}
</>



    return (
        <Card elevation={elevation} sx={sx}>

            {/* <CardHeader title={<Typography variant="subtitle1">Commcare Instances</Typography>} /> */}
            <CardContent>
                {isCollapsible? collapsibleContainer(props) : nonCollapsibleContainer(props)}
            </CardContent>
            <CardActions>

            </CardActions>
        </Card>
    )
}

export default DopCollapse;