import { Button, Dialog, DialogActions, 
    DialogContent, DialogTitle, Grid, IconButton, Stack } from "@mui/material";
import { ILandscapeItem } from "../../api/landscape/ILandscapeItem";
import CloseIcon from '@mui/icons-material/Close';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
import { blue } from '@mui/material/colors';


interface ILandscapeItemDetailProps {
    isOpen: boolean,
    handleClose: ()=> void,
    item: ILandscapeItem
}


const LandscapeItemDetail = (props:ILandscapeItemDetailProps) => {
    const {isOpen, handleClose, item} = props;
    
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle sx={{ m: 0, p: 2 }} >
               {item.name} 
              <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                    >
          <CloseIcon />
        </IconButton>
    </DialogTitle>
    <DialogContent dividers>
        <Grid container>
            <Grid item xs={3}>
                <Stack>
                    { item.imgFilename  ?
                        <img src={`/logo/${item.imgFilename}`} style={{ width: '50px', height: '50px' }} alt={`${item.name} logo`}/> :
                        <BrokenImageOutlinedIcon sx={{color: blue[900], fontSize:40}}/>
                    }
                    
                    
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <Stack spacing={2}>
                    <div>Name: {item.name}</div>
                    <div>{item.description}</div> 
                    <div>URL: {item.url}</div>         
                </Stack>
            </Grid>
        </Grid>        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
        </Dialog>
        )
}

export default LandscapeItemDetail;