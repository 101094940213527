import { Button, Paper, Typography } from "@mui/material";

const ViewDataConfig = ()=> {
    return <>
    <Paper elevation={5} sx={{padding:5}} >
    <Typography variant="h6">Views</Typography>
    <Button>Add a view definition</Button>
    </Paper>

    </>
}

export default ViewDataConfig;