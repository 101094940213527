import { Button, TextField, Typography, Box, Checkbox, FormControlLabel } from "@mui/material";

import { useState } from "react";
import ResourceGroupSummary from "./resource-group-summary";
import { IStepProps } from "../IStepProps";

interface IResourceGroupStepProps extends IStepProps{
    
}

const ResourceGroupStep = (props: IResourceGroupStepProps) => {
    const numberOfSubsteps = 3;
    const [currentStep, setCurrentStep] = useState(0);
    const { back, next, config } = props;
    const [rgName, setRgName] = useState(config.settings.name);
    const [rgLocation, setRgLocation] = useState(config.settings.location);
    const [readyForDeployment, setReadyForDeployment] = useState(false);


    const nextSubstep = () => {
        config.settings.name = rgName;
        config.settings.location = rgLocation;
        config.readyForDeployment = readyForDeployment;
        currentStep < (numberOfSubsteps - 1) ? setCurrentStep(currentStep + 1) : next(config);
    }

    const previousSubstep = () => {
        currentStep === 0 ? back() : setCurrentStep(currentStep - 1);
    }

    return (
        <>
            {currentStep === 0 && <AzureResourceGroups />}
            {currentStep === 1 && <>
                <Box sx={{ marginLeft: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Resource Group for Data Processing
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        To establish a resource group, we need a few details from you. Please provide a name for the resource group and the location where you want it to be deployed.
                    </Typography>
                    <Typography variant="body1" gutterBottom>

                        The name you provide will help you and your team identify and manage the resource group and its associated resources.
                    </Typography>
                    <Typography variant="body1" gutterBottom>

                        When choosing a location for your resource group, consider your privacy and data residency requirements. If your organization is subject to certain regulations or requirements that mandate data storage in specific geographic locations, it's important to choose a location that meets those requirements.
                    </Typography>

                    <TextField value={rgName} onChange={e => setRgName(e.target.value)} label="Resource Group Name"></TextField>
                    <TextField value={rgLocation} onChange={e => setRgLocation(e.target.value)} label="Resource Group Locations"></TextField>
                </Box>
            </>}
            {currentStep === 2 && <>
                <Typography variant="h6" gutterBottom>
                    Review Resource Group
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Thank you for providing a name and location for your resource group. We're almost ready to deploy it! Before we do, we ask that you review the details you've provided and mark the resource group as ready for deployment.
                </Typography>
                <Typography variant="body1" gutterBottom>

                    Please take a moment to review the name and location you've selected for the resource group. If everything looks good, simply mark it as ready for deployment. If you need to make any updates or changes, you can easily edit the resource group details before marking it as ready for deployment.
                </Typography>
                <div>
                    <ResourceGroupSummary showEdit={false} onEdit={() => { }} resourceGroupSettings={config} />
                    <FormControlLabel label="Ready for Deployment"
                        control={
                            <Checkbox checked={readyForDeployment} onChange={(e) => setReadyForDeployment(e.target.checked)}
                            />
                        } />
                </div>
            </>}

            <Button onClick={previousSubstep}>{currentStep === 0 ? "Back" : "Back"} </Button>
            <Button onClick={nextSubstep}>{currentStep + 1 === numberOfSubsteps ? "Done" : "Continue"}</Button>
            <div style={{ textAlign: "right" }}> Step {currentStep + 1} of {numberOfSubsteps}</div>

        </>
    )
}


const AzureResourceGroups = () => {
    return (
        <Box sx={{ marginLeft: 4 }}>
            <Typography variant="h6" gutterBottom>
                Resource Groups in Azure
            </Typography>
            <Typography variant="body1" gutterBottom>
                In Azure, a resource group is a logical container that holds related Azure resources. It allows you to group resources that share the same lifecycle and manage their lifecycle as a single entity. For example, you can easily delete or update all resources in a resource group at once.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Resource groups also provide a way to manage access and permissions. You can assign users and groups to roles in a resource group, providing fine-grained control over who can access and manage the resources within it.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Additionally, resource groups help monitor and manage costs associated with specific resources or projects. By using resource groups, you can track and manage the costs of resources, helping you to optimize spending and allocate resources more efficiently.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Overall, resource groups are a key concept in Azure that help simplify the management and organization of resources, making it easier to manage large, complex environments.
            </Typography>
        </Box>
    );
};

export default ResourceGroupStep;